.action-toast {
    display: flex;
    height: 80px;
    position: fixed;
    top: 88px;
    left: calc(224px + 42.5%);
    transform: translateX(-42.5%);
    border-radius: 12px;
    overflow: hidden;
    box-shadow: $defaultshadow;
    z-index: 45;
    &-message {
        display: flex;
        flex-flow: row;
        align-items: center;
        padding: 0 60px 0 16px;
        font: {
            family: $roboto;
            size: 13px;
            weight: 700;
        }
        &-icon {
            margin: 0 28px 0 0;
        }
        &-close {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 80px;
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.action-successful {
    background: $d7green;
    color: $darkgreen;
    .action-toast-message {
        &-close {
            background: rgba(39, 116, 68, 0.1);
        }
    }
}

.action-info {
    background: $blue;
    color: $white;
    .action-toast-message {
        &-close {
            background: rgba(39, 116, 68, 0.1);
        }
    }
}

.action-failure,
.action-error {
    background: $red;
    color: #963333;
    .action-toast-message {
        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            background: #963333;
            border-radius: 50%;
        }
        &-close {
            background: rgba(150, 51, 51, 0.1);
        }
    }
}

@media only screen and (max-width: 767px) {
    .action-toast {
        left: 50%;
        transform: translateX(-50%);
    }
}
