.hide {
    display: none;
}

.drop-chevron {
    content: url("../../images/shared/chevron.svg");

    &--back {
        transform: rotate(90deg);
    }
}

.drop-triangle {
    content: url("../../images/shared/drop-triangle.svg");
}

.help {
    content: url("../../images/shared/help.svg");
}

.dropmenu {
    display: flex;
    justify-content: center;
    flex-flow: column;
    position: absolute;
    background-color: #fff;
    border-radius: 6.93px;
    box-shadow: $defaultshadow;
    padding: 8px 0;
    width: 150px;
    list-style: none;
    z-index: 8;
    li {
        padding: 8px;
        font: {
            family: $sfpro;
            size: 13px;
            weight: 400;
        }
        &:hover {
            background-color: $lightgrey;
            cursor: pointer;
        }
    }
}

.dropdown-toggle,
.dropdown-menu {
    width: 100%;
    font: {
        family: $roboto;
        size: 15px;
        weight: 700;
    }
}

.dropdown-item {
    font-weight: 700;
    color: $d7navy;
    &:hover,
    &:focus {
        background: var(--drip7-primary-color-25);
    }
    &:active {
        background: $d7teal;
        color: #fff;
    }
}

.close-button {
    color: $d7navy;
    padding: 4px;
    box-sizing: content-box;
    border-radius: 50%;
    transition: 0.1s;
    &:hover {
        color: #fff;
        background: $d7teal;
        cursor: pointer;
        transition: 0.1s;
    }
}

.container-expand {
    .container-symbol {
        transform: rotate(0);
        transition: 0.2s;
    }
}

.container-collapse {
    .container-symbol {
        transform: rotate(0deg);
        transition: 0.2s;
    }
    .badge-status__badge-group-container {
        height: 0;
        margin: 0;
        overflow: hidden;
    }
}

.button-placeholder {
    color: $greymedium;
}

.d7__checkbox-container {
    @extend %custom-checkmark;
    position: relative;
}

button {
    &:disabled {
        opacity: 0.5;
        &:hover {
            cursor: initial;
        }
    }
}

/*****Margin Classes*****/

.m-v-xs {
    margin: 8px 0 !important;
}

.m-v-sm {
    margin: 16px 0 !important;
}

.m-v-md {
    margin: 32px 0 !important;
}

.m-t-xs {
    margin-top: 8px !important;
}

.m-t-sm {
    margin-top: 16px !important;
}

.m-t-md {
    margin-top: 32px !important;
}

.m-b-xs {
    margin-bottom: 8px !important;
}

.m-b-sm {
    margin-bottom: 16px !important;
}

.m-b-md {
    margin-bottom: 32px !important;
}

/*****Width Classes*****/

.width-100 {
    width: 100% !important;
}

/*****Flex Classes*****/

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.row-wrap {
    flex-flow: row wrap;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.space-between {
    justify-content: space-between;
}

.col-gap-sm {
    column-gap: 16px;
}

.col-gap-md {
    column-gap: 24px;
}

.col-gap-lg {
    column-gap: 36px
}

.flex {
    &__xs {
        flex: 1 3 0;
    }
    &__sm {
        flex: 1 2 0;
    }

    &__md {
        flex: 2 1 0;
    }

    &__lg {
        flex: 3 0 0;
    }

    &__xl {
        flex: 4 0 0;
    }
}