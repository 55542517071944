@import "../helpers/mixin.scss";
@import "../helpers/variables";

.user-portal {
    &__heading {
        display: flex;
        flex-flow: row;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        margin: 1.4rem auto;
        @include style-for(mobile) {
            flex-direction: column;
        }
    }

    &__avatar {
        display: none;
        width: 74px;
        min-width: 74px;
        height: 74px;
        margin: 0;
        background-size: contain;
        border: solid 5px #fff;
        border-radius: 50%;
        box-shadow: $darkshadow;
        overflow: hidden;
        img {
            width: 100%;
        }
    }

    &__mobile-avatar {
        display: none;
    }

    &__greeting {
        letter-spacing: 0.37px;
        color: var(--drip7-secondary-color);
        margin: 0;
        font: {
            family: $roboto;
            size: 24px;
            weight: 700;
        }
    }

    &__start {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        padding-right: 20px;
        height: 48px;
        letter-spacing: 0.37px;
        box-sizing: border-box;
        background: $d7teal;
        border: none;
        border-radius: 6.93px;
        color: #fff;
        font: {
            family: $sfpro;
            size: 17px;
            weight: 400;
        }
        svg {
            margin: 0 9px 0 0;
        }
    }
}

@media only screen and (max-width: 767px) {
    .user-portal {
        &__heading {
            height: fit-content;
            margin: 2rem 1rem;
            width: auto;
        }

        &__greeting{
            text-align: center;
            width: 100%;
            font: {
                family: $roboto;
                size: 18px;
                weight: 700;
            }
        }

        &__start {
            border-radius: 14px;
            background: #fff;
            border: none;
            color: $d7teal;
            font: {
                family: $roboto;
                size: 18px;
                weight: 700;
            }

        }

        &__avatar {
            display: none;
        }

        &__mobile-avatar {
            display: flex;
            margin: 0 auto;
            margin-bottom: -40px;
            position: relative;
            z-index: 10;
            width: 100px;
            height: 100px;
            max-width: 100px;
            border-radius: 50%;
            box-shadow: $darkshadow;

            &::before {
                content: "";
                position: absolute;
                top: -5px; 
                right: -5px; 
                bottom: -5px; 
                left: -5px; 
                border: 6px solid #fff;
                border-radius: 50%;
                z-index: -1;
            }

            & figure {
                margin: 0;
                overflow: hidden;
            }
            img {
                border-radius: 50%;
                width: 100%;
                height: 100%;
            }
        }
    }
}
