.central__main {
    .table {
        overflow: auto;
        .select-all-col {
            width: 20px;
            padding: 0 24px 0 8px;
        }

        input {
            width: 16px;
            height: 16px;
            vertical-align: middle;
        }

        width: 100%;
        border-collapse: collapse;

        &__container {
            padding: 0 8px;
        }

        &__row {
            height: 64px;
            & > * {
                padding: 8px 0;
                vertical-align: middle;
                line-height: 16px;
                font: {
                    family: $roboto;
                    size: 13px;
                    weight: 700;
                }
            }

            &--head {
                height: 36px;
                padding: 0;
            }
        }

        &__head {
            color: $d7navy;
            opacity: 0.8;

            svg {
                margin-left: 8px;
            }

            &--checkbox {
                min-width: 36px;
                width: 36px;
            }

        }

        &__data {
            &--img-name {
                width: 40%;
                cursor:pointer
            }

            &--checkbox {
                padding: 0 0 0 8px;
            }

            &--group-name {
                cursor: pointer;
            }
            &--no-cursor-pointer {
                cursor: auto;
            }
            &--group-button {
                border: none;
                padding: 0px;
                line-height: 16px;
                font-family: "Roboto", sans-serif;
                font-size: 13px;
                font-weight: 700;
            }
            &--img-button {
                display: table;
                cursor:pointer;
            }
            &--img {
                cursor:pointer;
            }
            &--count {
                cursor:pointer;
            }
        }
    }
}

.badge-placeholder {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    /* background: purple; */
    object-fit: scale-down;
}

.sort-button {
    content: url("../../images/shared/sort.svg");
    // margin: 0 0 0 8px;
    vertical-align: middle;
    opacity: 1;
}

.queue-track-wrapper {
    position: relative;
    margin: 8px;
    padding-top: 8px;
    margin-top: 20px;
    border: 1px;
    border-style: solid;
    border-radius: 12px;
    border-color: var(--drip7-secondary-color);
}

.queue-track-header {
    position: absolute;

    top: -18px;
    margin-left: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
        cursor: pointer;
        padding: 6px 10px 6px 10px;
        border-radius: 8px;
        background-color: var(--drip7-primary-color);
        color: white;
        font-weight: bold;
    }

    &--item {
        margin-left: 24px;
    }
}
