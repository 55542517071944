@import "./../../../assets/scss/helpers/variables";
.categories {
    position: relative;
    top: 2rem;
    left: -2rem;
    z-index: 50;
    width: fit-content;
    opacity: 0;
    transition: 0.4s;
    &.active {
        opacity: 1;
    }
    & a {
        margin-top: 0.4rem;
        padding: 0.4rem;
        border-radius: 10px;
        color: #fff !important;
        text-align: center;
        background-color: rgb(158, 158, 158);
        transition: 0.4s;
        &:hover {
            background-color: rgb(189, 189, 189);
        }
    }
    & > div.content {
        position: absolute;
    }
    & ul {
        width: 100%;
        min-width: 170px;
        margin: 0;
        cursor: auto;
        display: flex;
        flex-direction: column;
        row-gap: 0.4rem;
        background: #fff;
        border-radius: 12px;
        box-shadow: $defaultshadow;
        padding: 1rem;
        position: relative;
        & li {
            list-style: none;
            white-space: nowrap;
            width: fit-content;
            & > div {
                display: flex;
                align-items: center;
                gap: 6px;
                & input[type="checkbox"] {
                    cursor: pointer;
                    appearance: none;
                    height: 24px;
                    width: 24px;
                    min-height: 24px;
                    min-width: 24px;
                    border: 2px;
                    outline: 2px solid $d7teal;
                    border-radius: 4px;
                    transition: 0.2s;
                    &:checked {
                        border: 2px solid $white;
                        background-color: $d7teal;
                    }
                }
                & label {
                    cursor: pointer;
                }
            }
        }
    }
}
