.dropdown {
    &--attached {
        @extend %attached-drop;
    }

    &--wide {
        width: 100%;
    }

    &--search-split {
        width: 95px;
        border-radius: 6px 0 0 6px;
    }

    &--language {
        .dropdown-menu {
            padding: 0;
            position: relative;
        }
    }

    .dropdown {
        button {
            @extend %attached-drop;
        }
    }

    &-toggle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: solid 1px $grey;

        &--add-curriculum {
            padding: 12px 8px;
            height: 40px;
            background: #fff;
            border: solid 1px $grey;
            border-radius: 8px;
            color: fff;
        }

        &--question-filter {
            min-width: 208px;
            height: 40px;
            padding: 10px 12px 10px 16px;
            background: rgba(236, 239, 241, 0.3);
            border: solid 1px $grey;
            border-radius: 6.93px;
            color: fff;
        }

        &--create-curriculum,
        &--curriculum-config,
        &--add-question,
        &--language {
            height: 40px;
            padding: 12px 8px;
            background: #fff;
            border-radius: 6.93px;
        }

        &--add-question {
            margin: 0;
        }

        &--wide {
            width: 100%;
        }

        &--search-split {
            width: 95px;
            height: 32px;
            padding: 5px 8px 5px 14px;
            background: rgba(236, 239, 241, 0.3);
            border-radius: 6px 0 0 6px;
            color: fff;
            font-size: 13px;
            line-height: 20px;
        }

        p {
            margin: 0;
        }
    }

    &--add-curriculum {
        width: 312px;
        .dropdown-toggle--add-curriculum {
            font: {
                family: $sfpro;
                size: 12px;
                weight: 500;
            }
        }
    }

    &--question-filter {
        margin: 0 8px;
        .dropdown-toggle {
            p {
                margin: 0 6px 0 0;
                font: {
                    family: $sfpro;
                    size: 13px;
                    weight: 500;
                }
            }
        }
        .dropdown-toggle[aria-expanded="true"] {
            svg {
                transform: translateY(-1px);
            }
        }
    }

    &--create-curriculum,
    &--curriculum-config,
    &--add-question {
        margin: 6px 0;
        .dropdown-toggle {
            font: {
                family: $sfpro;
                size: 12px;
                weight: 500;
            }
        }
    }

    &--create-curriculum,
    &--curriculum-config {
        p {
            color: $greymedium;
        }
    }

    &--create-curriculum,
    &--add-question {
        width: 304px;
    }

    &--curriculum-config {
        width: 200px;
    }

    &--add-question {
        margin: 0;
        .dropdown-toggle {
            color: fff;
            span {
                color: $greymedium;
            }
        }
    }

    &--user-assignment {
        .dropdown-toggle {
            font: {
                family: $sfpro;
                size: 12px;
                weight: 500;
            }
        }
    }

    &--language {
        width: 224px;
        .dropdown-toggle {
            color: fff;
            span {
                color: $greymedium;
            }
            font: {
                family: $sfpro;
                size: 12px;
                weight: 500;
            }
        }
    }

    &-menu {
        &-container {
            display: flex;
            flex-flow: row wrap;
            width: 100%;
            padding: 0 8px;
            overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            &--user-assignment {
                height: 241px;
            }
        }
        &--language {
            height: 209px;
            overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__search {
        &-wrapper {
            display: inline-flex;
            padding: 4px 0 8px;
            position: sticky;
            top: 0;
            background: #fff;
            box-shadow: 0 0 1px 1px #fff;
            z-index: 10;
            &--user-assigment {
                width: 624px;
                .dropdown--split {
                    width: 95px;
                    height: 32px;
                    padding: 5px 8px 5px 14px;
                    background: rgba(236, 239, 241, 0.3);
                    border-radius: 6px 0 0 6px;
                    color: fff;
                    font-size: 13px;
                    line-height: 20px;
                }
            }
        }
        display: inline-flex;
        align-items: center;
        padding: 6px 8px;
        border: {
            top: solid 1px $grey;
            right: solid 1px $grey;
            bottom: solid 1px $grey;
            left: none;
        }
        border-radius: 0 6px 6px 0;
        color: fff;
        &--user-assignment {
            width: 527px;
            height: 32px;
        }
        &--language {
            width: 208px;
            height: 32px;
            margin: 5px 8px 0;
            position: sticky;
            top: 9px;
            background: #fff;
            z-index: 1;
            border-radius: 6.93px;
            border: solid 1px $grey;
            input {
                width: 165px;
                margin: 0 0 0 8px;
                color: fff;
                font: {
                    family: $sfpro;
                    size: 12px;
                    weight: 500;
                }
                &:focus {
                    outline: none;
                }
            }
        }
        input {
            border: none;
            height: 30px;
        }
    }

    &__checklist {
        display: flex;
        flex-flow: column;
        width: 100%;
        padding: 0;
        list-style: none;
        &-item {
            display: flex;
            flex-flow: row;
            align-items: center;
            position: relative;
            width: 100%;
            margin: 8px 0;
            .d7__checkbox {
                width: 100%;
            }
        }
        &-text {
            line-height: 20px;
            margin: 0 0 0 24px;
            font: {
                family: $roboto;
                size: 12px;
                weight: 500;
            }
        }
        &--question-filter {
            padding: 0 8px 4px;
            margin: 0;
        }
    }

    &-list {
        width: 100%;
        padding: 0;
        list-style: none;
        &--language {
            margin: 4px 0 0;
        }
    }
    &-toggle {
        &::after {
            display: none;
        }
    }
    .search-match {
        padding: 1px 0;
        background: var(--drip7-primary-color-25);
    }
}
