.admin-user-menu {
    width: 224px;
    padding: 18px 0;
    box-sizing: border-box;
    position: fixed;
    top: 88px;
    right: 5px;
    z-index: 11;
    background: #fff;
    border-radius: 12px;
    box-shadow: $defaultshadow;
    opacity: 0;
    transition: 0.4s all;
    &.open {
        opacity: 1;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        letter-spacing: 0.37px;
        font: {
            family: $roboto;
            size: 16px;
            weight: 700;
        }

        li {
            display: flex;
            align-items: center;
            line-height: 24px;
            padding: 6px 16px;
            color: $d7navy;
            cursor: pointer;
            transition: 0.05s;
            &:hover {
                text-decoration: underline;
                transition: 0.05s;
            }

            svg {
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                margin: 0 16px 0 0;
            }

            .drop-triangle {
                margin: 0 0 0 20px;
            }

            a {
                color: inherit;
                text-decoration: none;
            }
        }

        .logout {
            color: $red;
        }
    }

    .user-commands {
        margin: 0 0 12px;
    }

    .policies {
        opacity: 0.5 !important;
    }

    &:before {
        display: block;
        content: "";
        width: 16px;
        height: 16px;
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        background: #fff;
    }
}

@media only screen and (max-width: 767px) {
    .admin-user-menu {
        width: 240px;
        &:before {
            left: initial;
            right: 16px;
            transform: translateX(0) rotate(45deg);
        }
    }
}
