@import "../helpers/variables";
@import "../helpers/mixin";

.phishing-page {
    font-family: $roboto;
    color: $fontgray;
    background: $white;
    line-height: 1.7em;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;

    h1, h2, h3, h4 {
        color: $d7navy;
        font-weight: 800;
    }
    
    h2 {
        font-size: 2.85rem;
    }
    
    h4 {
        font-size: 2rem;
    }
    
    p {
        font-size: 1.375rem;
    }
    
    .blue-background {
        background-color: $lightblue;
    }
    
    .wavy-pattern {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNCQ0VDRkYiPjxwYXRoIGQ9Ik0zMjAgMjhDMTYwIDI4IDgwIDQ5IDAgNzBWMGgxMjgwdjcwYy04MCAyMS0xNjAgNDItMzIwIDQyLTMyMCAwLTMyMC04NC02NDAtODR6Ii8+PC9nPjwvc3ZnPg==');
        background-size: 100% 100px;
        height: 100px;
        transform: scale(-1, -1);
    }
    
    .squiggly-separator {
        margin-bottom: 1%;
        width: 33%;
        text-align: left;
        margin-left: 0;
    }

    .footer {
        background-blend-mode: multiply;
        background-image: linear-gradient(180deg,#e2f9ed 100%,rgba(0,0,0,0) 100%),url('../../images/phishing/footer.jpg');
        padding: 54px;
        background-position: 50%;
        position: relative;
        background-size: cover;
        &.image {
            padding-top: 13.2%;
            padding-bottom: 13.2%;
        }
    }

    .ribbon-badge {
        margin-top: -15%;
        width: 200px;
        margin-right: 20%;
        img {
            position: relative;
            max-width: 100%;
            height: auto;
        }
    }

    .footer-wave {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0zMjAgMjhDMTYwIDI4IDgwIDQ5IDAgNzBWMGgxMjgwdjcwYy04MCAyMS0xNjAgNDItMzIwIDQyLTMyMCAwLTMyMC04NC02NDAtODR6Ii8+PC9nPjwvc3ZnPg==);
        background-size: 100% 100px;
        top: 0;
        height: 100px;
        // z-index: 1;
        transform: scale(1,1);
        position: absolute;
        left: 0;
        right: 0;
    }
}