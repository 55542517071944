.game {
    &-body {
        position: fixed;
        top: 0;
        z-index: 10;
        background: lightblue;
        overflow: hidden;
        // z-index: 101;
    }
    &-close {
        position: fixed;
        top: 10px;
        right: 10px;
        // z-index: 101;
    }
    &-canvas {
        background: lightblue;
        position: fixed;
        top: 0;
        left: 0;
    }
    &-question {
        position: fixed;
        max-width: 90vw;
        top: 20px;
        left: 5vw;
        text-align: center;
        padding-bottom: 0;
        width: 100%;
        z-index: 2;
    }
}
