@import "../../../assets/scss/helpers/variables";

.input-whit-icon-container {
    & label {
        margin-bottom: 0.4rem;
    }
    &.fullwidth {
        width: 100%;
    }
    .input-with-icon {
        display: flex;
        align-items: center;
        position: relative;
        & input.icon {
            text-indent: 34px;
        }
        & input {
            background: $white;
            min-height: 24px;
            padding: 0.6rem;
            border-radius: 4px;
            width: 100%;
            outline: 2px solid $grey;
            border: none;
            transition: 0.4s all;
            &:focus,
            &.view {
                outline: $d7teal solid 2px;
                transition: 0.4s outline;
            }
        }
        i {
            width: 24px;
            height: 24px;
            position: absolute;
        }
        & > i {
            margin-left: 8px;
            margin-right: 8px;
        }
        & input + i {
            right: 0;
           // margin-right: 8px;
        }
        & button {
            border: none;
            position: absolute;
            right: 0;
            margin-right: 8px;
            & i {
                position: static;
            }
        }

        //icons
        .user-icon {
            content: url("../../../assets/images/admin/user.svg");
        }
        .search-icon {
            content: url("../../../assets/images/admin/search.svg");
        }
        .email-icon {
            content: url("../../../assets/images/shared/email.svg");
        }
        .lock-icon {
            content: url("../../../assets/images/admin/lock.svg");
        }
        .eye-icon {
            content: url("../../../assets/images/admin/eye.svg");
            &:hover {
                cursor: pointer;
            }
        }
    }
}
