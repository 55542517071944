.add-curriculum {
    width: 672px;
    background: #fff;
    border-radius: 12px;
    box-shadow: $defaultshadow;

    &--purple {
        .add-curriculum__head {
            background: $purple;
        }
    }

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 24px 24px 20px;
        color: #fff;
        border-radius: 12px 12px 0 0;
        h4 {
            margin: 0;
            font: {
                family: $roboto;
                size: 15px;
                weight: 700;
            }
        }
    }

    &__close-button {
        box-sizing: content-box;
        padding: 3px;
        border-radius: 50%;
        transition: 0.1s;
        &:hover {
            cursor: pointer;
            background: #fff;
            color: $purple;
            transition: 0.1s;
        }
        &:active {
            background: $grey;
        }
    }

    &__field {
        &-container {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            padding: 0 16px;
            margin: 26px 0 0;
        }
        display: flex;
        flex-flow: row wrap;
        width: 312px;
        margin: 0 0 14px;
        &--wide {
            width: 100%;
        }
    }

    &__label {
        margin: 0 0 6px;
        line-height: 20px;
        color: $d7navy;
        font: {
            family: $roboto;
            size: 12px;
            weight: 500;
        }
        span {
            color: $darkgrey;
        }
    }

    &__input {
        width: 312px;
        height: 40px;
        padding: 12px 8px;
        border: solid 1px $grey;
        border-radius: 8px;
        color: $d7navy;
        font: {
            family: $sfpro;
            size: 12px;
            weight: 500;
        }
        &:focus {
            outline: none;
        }
        &::placeholder {
            color: $d7navy;
        }
    }

    &__button {
        &-container {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }
        width: 176px;
        height: 40px;
        margin: 16px 16px 16px 0;
        border-radius: 6.93px;
        font: {
            family: $sfpro;
            size: 13px;
            weight: 500;
        }
        &--clear {
            @extend %clear-button;
        }
        &--solid {
            @extend %solid-button;
            background: $d7teal;
            border: solid 1px $d7teal;
        }
    }
}
