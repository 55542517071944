.user-header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 1200px;
    /*height: 72px;*/
    padding: 0 30px;
    box-sizing: border-box;
    /*position: fixed;*/
    top: 0;
    font-family: $sfpro;
    background: $d7teal;
    color: #fff;
    box-shadow: $darkshadow;
    z-index: 45;

    &__logo {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 150px;
        background: none;
        padding: 15px 0 9px;
        .d7-logo-white {
            width: 89px;
            height: 48px;
        }
        & img {
            cursor: pointer;
            margin-top: -4px;
            height: 74px;
        }

        & > * {
            max-width: 100%;
            background: none;
        }
    }

    &__mini-profile {
        cursor: pointer;
        p {
            font-weight: 400;
        }
        .user-profile-photo {
            overflow: hidden;
        }
    }

    a {
        text-decoration: none;
        color: #1f4760;
        &:visited {
            color: #1f4760;
        }
    }
}

.user-nav {
    &__menu {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        list-style: none;
        letter-spacing: 0.37px;
        font: {
            size: 16px;
            weight: 500;
        }
    }
    &__item {
        display: inline-flex;
        align-items: center;
        height: 40px;
        padding: 16px 0;
        margin: 0 25px 0 0;
        &:last-of-type {
            margin: 0;
        }
        svg {
            margin: 0 10px 0 0;
        }
        transition: 0.1s;
        opacity: 0.5 !important;
        &:hover {
            opacity: 1 !important;
            transition: 0.1s;
            cursor: pointer;
        }
        &--active {
            margin: 0 25px 0 0;
            opacity: 1;
            text-decoration: underline;
            opacity: 1 !important;
            text-underline-offset: 2px;
            &:hover {
                cursor: default;
            }
        }
        &--dashboard {
            svg {
                margin: 0 10px 4px 0;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .user-header {
        justify-content: center;
        min-width: unset;
        background: $d7teal;
        box-shadow: none;
        &__mini-profile {
            display: none;
        }

        &__logo {
            width: unset;
            /*
            & img {
                width: 130px;
                height: 90px;
            }
            */
            .d7-logo-white {
                width: unset;
            }
        }
    }

    .user-nav {
        display: none;
    }
}
