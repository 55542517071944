.delete-user-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 520px;
    height: 160px;
    background: #fff;
    box-shadow: $defaultshadow;
    border-radius: 12px;

    .delete-user-wrap {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 360px;
        padding: 16px 0 24px;

        p {
            width: 100%;
            text-align: center;
            color: $d7navy;
            line-height: 20px;
            letter-spacing: -0.24px;
            font: {
                family: $roboto;
                size: 15px;
                weight: 700;
            }
            span {
                color: $d7teal;
            }
        }

        .delete-user-buttons {
            display: inline-flex;
            justify-content: space-between;
            width: 368px;
            .clear-button {
                @extend %clear-button;
            }

            .solid-button {
                @extend %solid-button;
                background: $red;
                border: solid 1px $red;
            }

            button {
                width: 176px;
                height: 40px;
                border-radius: 6.93px;
                font: {
                    family: $sfpro;
                    size: 13px;
                    weight: 500;
                }
            }
        }
    }
}
