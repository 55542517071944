@import "../../../assets/scss/helpers/mixin";

.profile-settings {
    display: flex;
    padding: 2rem 8rem 3rem 3rem;
    gap: 2rem;
    &__main-fields {
        width: 100%;
        & .user-info-content {
            width: 100%;
            margin-bottom: 2rem;
            row-gap: 0.4rem;
            display: flex;
            flex-direction: column;
            @include style-for(desktop) {
                display: grid;
                gap: 1rem;
                grid-template-columns: 1fr 1fr;
            }
        }
    }
    &__heading {
        width: 100%;
        h1 {
            line-height: 20px;
            margin: 35px 0 33px;
            text-align: left;
        }
    }

    .user-avatar {
        width: 131px;
        height: 131px;
        background: url("../../images/shared/avatar.png");
        background-size: contain;
        border: solid 5px #fff;
        border-radius: 50%;
        box-shadow: $darkshadow;
    }

    &__user-avatar {
        width: 160px;
        /*height: 160px;*/
        border: 4px solid $white;
        background-color: $white;
        filter: drop-shadow(0px 4px 40px rgba(141, 198, 222, 0.5));
        border-radius: 50%;
        @include style-for(desktop-large) {
            width: 200px;
            height: 200px;
        }
        &-wrapper {
            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
        }
    }

    &__link {
        margin: 24px 0 0;
        color: $d7teal;
        line-height: 10px;
        font: {
            family: $roboto;
            size: 17px;
            weight: 700;
        }
    }

    &__menu-heading {
        width: 100%;
        text-align: left;
        line-height: 32px;
        margin: 0 0 16px;
        color: $d7navy;
    }

    &__personal-information {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    &__change-password {
        display: flex;
        flex-flow: column;
        width: 312px;
        margin: 32px 0 38px;
    }

    &__edit-field {
        width: 312px;
        margin: 0 0 16px;
        letter-spacing: -0.24px;
        &--empty-input {
            .edit-profile__eye-icon {
                opacity: 0.5;
            }
        }

        &--unhide {
            .edit-profile__eye-icon {
                content: url("../../images/shared/eye-unhide.svg");
            }
        }
        &--password-error {
            .profile-settings__input-wrapper {
                border-color: $errorred;
            }
        }
    }

    &__label {
        width: 100%;
        line-height: 16px;
        text-align: left;
        color: $d7navy;
        font: {
            family: $roboto;
            weight: 700;
        }
    }

    &__input-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        padding: 8px;
        margin: 8px 0 0;
        position: relative;
        box-sizing: border-box;
        border: solid 1px $grey;
        border-radius: 6.93px;
    }

    &__input {
        width: 256px;
        height: 32px;
        margin: 0 0 0 16px;
        letter-spacing: -0.24px;
        border: none;
        color: $d7navy;
        font: {
            family: $roboto;
            size: 15px;
            weight: 700;
        }
        &:focus {
            outline: none;
        }
    }

    &__popover {
        position: absolute;
        background: #fff;
        border-radius: 5px;
        box-shadow: $defaultshadow;
        &::before {
            display: block;
            content: "";
            width: 0;
            height: 0;
            border: solid 8px;
            position: absolute;
            top: 50%;
            left: -11px;
            transform: rotate(45deg) translateY(-50%);
        }

        &--password-error {
            width: 297px;
            height: 72px;
            padding: 16px 0 8px;
            box-sizing: border-box;
            left: 336px;
            font-family: $roboto;
            &::before {
                border-color: $errorred;
            }
            &::after {
                display: block;
                content: "";
                width: 10px;
                height: 72px;
                position: absolute;
                top: 0;
                left: 0;
                background: $errorred;
                border-radius: 5px 0 0 5px;
                z-index: 50;
            }
        }

        &-text {
            padding: 0 0 0 30px;
            background: #fff;
            position: relative;
            z-index: 10;
            &--heading,
            &--link {
                margin: 0;
                font: {
                    size: 17px;
                    weight: 700;
                }
            }
            &--link {
                color: $d7teal;
                &:hover {
                    color: $d7navy;
                }
            }
        }
    }

    .dropdown {
        width: 312px;
        i {
            margin: 0 16px 0 0;
        }
    }

    .dropdown-toggle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        padding: 8px;
        margin: 8px 0 0;
        background: none;
        border: solid 1px $grey;
        border-radius: 6.93px;
        color: $d7navy;
        &__text {
            display: flex;
            align-items: baseline;
            line-height: 20px;
        }
    }

    &__toggle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 312px;
        height: 32px;
        margin: 16px 0;
        &-name {
            color: $d7navy;
        }
    }

    &__button {
        width: 312px;
        height: 48px;
        margin: 38px 0 0;
        background: $d7teal;
        color: #fff;
        border: none;
        border-radius: 6.93px;
        line-height: 32px;
        font: {
            family: $roboto;
            size: 17px;
            weight: 700;
        }

        &:disabled {
            background: $lightgrey;
            color: $greymedium;
        }
    }

    &__user-icon {
        content: url("../../images/shared/user.svg");
    }

    &__email-icon {
        content: url("../../images/shared/email.svg");
    }

    &__briefcase-icon {
        content: url("../../images/shared/briefcase.svg");
    }

    &__lock-icon {
        content: url("../../images/shared/lock.svg");
    }

    &__eye-icon {
        content: url("../../images/shared/eye.svg");
    }
}

.change-password-mobile {
    display: none;
}

@media only screen and (max-width: 767px) {
    .profile-settings {
        flex-flow: row wrap;
        padding: 0 8.5% 36px;
        overflow-y: scroll;
        margin-top: 90px;
        &__user-avatar {
            &-wrapper {
                margin-top: 20px;
                position: relative;
                width: 100%;
                height: auto;
                .user-avatar {
                    position: fixed;
                    top: 86px;
                }
            }
        }

        &__link {
            margin: 18px 0;
            line-height: 30px;
            &:first-of-type {
                margin: 40px 0 0;
            }
        }

        &__personal-information {
            width: 100%;
        }

        .user-avatar {
            width: 80px;
            height: 80px;
        }

        &__popover {
            display: none;
        }

        &__main-fields {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
        }

        &__edit-field,
        .dropdown {
            width: 100%;
        }

        &__menu-heading {
            display: none;
        }

        &__change-password {
            display: none;
            &--mobile {
                display: flex;
                flex-flow: row wrap;
                width: 100%;
                & > * {
                    width: 100%;
                }
            }
        }

        &__toggle {
            width: 100%;
            &s {
                width: 100%;
            }
        }

        &__button {
            width: 216px;
            margin: 100px 0 0;
        }

        &__input {
            width: calc(100% - 48px);
        }
    }

    /*.change-password-mobile {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 8px;
    margin: 32px 0 46px;
    background: var(--drip7-primary-color-20);
    border-radius: 7px;

    span {
      flex-grow: 2;
      text-decoration: underline;
      text-align: center;
      line-height: 30px;
      color: $d7teal;
      font: {
        family: $roboto;
        size: 17px;
        weight: 700;
      }
    }

    .lock-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      width: 32px;
      height: 32px;
    }

    .chevron-icon {
      display: flex;
      justify-content: center;
      width: 16px;
      margin: 0 0 0 16px;
    }
  }*/
}
