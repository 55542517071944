.admin-header {
    position: fixed;
    z-index: 50;
    background-color: #fff;
    box-shadow: $defaultshadow;
    color: $d7navy;
    font: {
        family: $roboto;
    }

    .dropdown-menu {
        min-width: 20rem;
        max-height: 70vh;
        overflow-y: scroll;
    }

    .header-wrap {
        display: flex;
        flex-flow: row-reverse wrap;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 255px);
        min-width: 400px;
        height: 72px;
        padding: 0 15px;
        transition-delay: 0.8s;
        transition: 0.4s;

        h1 {
            font: {
                size: 24px;
                weight: 700;
            }
            line-height: 25px;
        }

        .user-mini-profile {
            p {
                font-weight: 500;
            }
        }
    }
}

.admin-header.active > div.header-wrap {
    transition-delay: 0.2s;
    width: calc(100% - 55px) !important;
}