.d7badge {
    position: relative;
    background: $lightgrey;
    border-radius: 50%;
    overflow: hidden;

    &__icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        border-radius: 50%;
        z-index: 52;
        overflow: hidden;
        img {
            width: 100px;
            height: 100px;
        }
    }

    &--badge-status {
        width: 88px;
        height: 88px;
        .d7badge__icon {
            width: 72px;
            height: 72px;
        }
    }

    &--user-home {
        width: 136px;
        height: 136px;
        background: #fff;
        border-radius: 68px;
        overflow: hidden;
        box-shadow: $darkshadow;
        .d7badge__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 116px;
            height: 116px;
            background: #e6f7ff;
            img {
                object-fit: contain;
                border-radius: 50%;
            }
        }
        .radial-progress__half-circle-cover {
            background: #fff;
        }
    }
}
