.questions {
    &__table {
        width: calc(100% - 16px);
        margin: 0 8px;
        border-collapse: collapse;

        &-row {
            height: 64px;
            border-bottom: solid 1px $grey;
            color: $d7navy;
            &:last-of-type {
                border: none;
            }
            &:hover {
                .questions__table-data--edit {
                    opacity: 1;
                    transition: 0.075s;
                }
            }
            &--head {
                height: 56px;
                border: none;
            }

            &--subject {
                & > * {
                    &:first-child {
                        margin: 0 0 0 46px;
                    }
                }

                .questions__table-data--edit {
                    margin: 0 56px 0 0;
                }
            }
        }

        &-head {
            // opacity: .8;
            &--name {
                position: relative;
                width: 450px;
                .questions__table-heading {
                    margin: 0 0 0 32px;
                }
            }
            &--category {
                margin-left: 30px;
                min-width: 250px;
                max-width: 250px;
            }
            &--tenant {
                min-width: 250px;
                max-width: 250px;
            }
        }

        &-heading,
        &-text {
            margin: 0;
            line-height: 20px;
            cursor:pointer;
            font: {
                family: $roboto;
                size: 13px;
                weight: 700;
            }
        }

        &-heading {
            display: flex;
            align-items: center;
            line-height: 20px;
            opacity: 1;
            &::after {
                content: url("../../images/shared/sort.svg");
                margin: 0 0 0 8px;
                opacity: 1;
                height: 15px;
            }
            &:hover {
                cursor: pointer;
            }
            &-no-sort{
                display: flex;
                align-items: center;
                line-height: 20px;
                opacity: 1;
                margin: 0 0 0 0px;
                opacity: 1;
                height: 15px;
                font: {
                    family: $roboto;
                    size: 13px;
                    weight: 700;
                }
            }
        }

        &-data {
            &--category {
                margin: 0 0 0 30px;
                min-width: 250px;
                max-width: 250px;
            }
            &--tenant {
                min-width: 250px;
                max-width: 250px;
            }
            &--edit {
                display: inline-flex;
                column-gap: 12px;
                margin: 0 8px 0 0;
                opacity: 0;
                transition: 0.075s;
            }
            &--more {
                width: 16px;
                &:hover {
                    cursor: pointer;
                }
            }
            &--name {
                position: relative;
                .questions__table-text {
                    margin: 4px 8px 4px 32px;
                }
            }

            &--add-subject {
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                width: 44px;
                height: 32px;
                padding: 12px 13px;
                border-radius: 20px;
                box-sizing: border-box;
                overflow: hidden;
                color: $d7teal;
                transition: 0.1s;
                font: {
                    family: $sfpro;
                    size: 13px;
                }

                svg {
                    min-width: 14px;
                }

                &:hover {
                    width: 130px;
                    transition: 0.1s;
                    background: $d7teal;
                    color: #fff;
                    cursor: pointer;
                    .questions__add-subject {
                        width: fit-content;
                    }
                }

                a {
                    display: inline-flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 95px;
                    height: 32px;
                    color: inherit;
                    text-decoration: none;
                    
                    &:hover {
                        width: 130px;
                        color: #fff;
                        justify-content: space-between;
                        .questions__add-subject {
                            width: fit-content;
                        }
                    }
                }
            }
        }

        &--categories {
            .questions__table-row {
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }

            .questions__table-data {
                display: inline-flex;
                align-items: center;
            }

            a {
                color: inherit;
                
                &:hover {
                    color: inherit;
                }
            }
        }
    }

    &__edit-button,
    &__delete-button {
        &:hover {
            cursor: pointer;
        }
    }

    &__delete-button {
        color: $red;
    }

    &__add-subject {
        width: 0;
        overflow: hidden;
        transition: 0.1s;
    }
}

.question-slider {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 6px;
    margin-right: 64px;

    &--input {
        border: 1px;
        border-style: solid;
        border-radius: 8px;
        padding: 6px;
        border-color: lightgray;
        margin-right: 16px;

        &:focus {
            outline: none;
            border-color: var(--drip7-primary-color);
        }
    }
}
.tableW10{
    width: 10%;
}
.tableW15{
    width: 15%;
}
.tableW20{
    width: 20%;
}
.tableW30{
    width: 30%;
}
