.personal-info-modal {
    display: flex;
    justify-content: center;
    width: 672px;
    height: 456px;
    max-height: 100% !important;
    padding: 24px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: $defaultshadow;
    border-radius: 12px;

    h2 {
        padding: 0;
        margin: 0;
        font: {
            size: 20px;
            weight: 700;
        }
    }

    h4 {
        margin: 0;
        font: {
            size: 13px;
            weight: 700;
        }
    }

    .personal-info-wrap {
        display: flex;
        flex-flow: row wrap;

        .personal-info-heading {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin: 0 0 24px;

            h3 {
                padding: 0;
                margin: 0;
                line-height: 20px;
                color: $d7navy;
                font: {
                    family: $roboto;
                    size: 15px;
                    weight: 700;
                }
            }
            h2 {
                line-height: 20px;
                font: {
                    size: 15px;
                }    
            }
        }

        .user-photo-and-edit {
            width: 160px;
            cursor: pointer;

            .user-photo-large {
                width: 160px;
                height: 160px;
                background: url("../../images/admin/example-user-photo.png")
                    center no-repeat;
                background-size: contain;
                border-radius: 4px;
            }

            .edit-user-buttons {
                .solid-button {
                    @extend %solid-button;
                    @extend %sfpro-default;
                    width: 160px;
                    height: 40px;
                    margin: 16px 0 -8px;
                    background: $d7teal;
                    border: solid 1px $d7teal;
                    border-radius: 6.93px;
                }

                ul {
                    list-style: none;
                    margin: 24px 0 0;
                    padding: 0 8px;
                    font: {
                        family: $roboto;
                        size: 13px;
                        weight: 700;
                    }

                    li {
                        margin: 16px 0;
                        color: $d7teal;
                        display: flex;
                        flex-flow: row;
                        align-items: center;

                        svg,
                        img {
                            width: 24px;
                            height: 16px;
                            margin: 0 8px 0 0;
                        }
                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .delete-user {
                        color: $red;
                    }
                }
            }
        }
        .personal-info {
            display: flex;
            flex-flow: row wrap;
            align-content: flex-start;
            margin: 0 0 0 48px;
            color: $d7navy;
            font: {
                family: $roboto;
                size: 13px;
                weight: 700;
            }
            width: 400px;
            & > * {
                width: 100%;
            }

            .user-online-status {
                display: flex;
                flex-flow: row;
                align-items: center;
                width: 100%;
                height: 24px;

                h2 {
                    margin: 0;
                    color: $d7navy;
                    font: {
                        family: $roboto;
                        size: 20px;
                        weight: 700;
                    }
                }

                .online-status {
                    display: flex;
                    flex-flow: row;
                    align-items: center;
                    margin: 0 0 0 15px;
                    line-height: 20px;
                    color: $green;
                    font: {
                        family: $roboto;
                        size: 13px;
                        weight: 700;
                    }
                    span {
                        margin: 0 4px 0 0;
                        font-size: 24px;
                    }
                }
            }

            .user-id {
                margin: 8px 0 32px;
                color: $d7navy;
                opacity: 0.5;
            }

            .user-info {
                margin: 0 0 27px;

                h4 {
                    height: 16px;
                    margin: 0 0 16px;
                    &:last-of-type {
                        margin: 0;
                    }
                }
            }

            .user-progress {
                .user-progress-stats {
                    h4 {
                        height: 16px;
                        margin: 0 0 10px;
                    }
                }

                .user-badges-stats {
                    display: flex;
                    flex-flow: row;
                    align-items: center;
                    height: 32px;

                    h4 {
                        height: 16px;
                        margin: 0 20px 0 0;
                    }

                    .badge {
                        width: 32px;
                        margin: 0 0 0 -10px;
                        &:first-of-type {
                            margin: 0;
                        }
                    }
                }
            }
        }

        .user-edit-inputs {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-flow: row wrap;
            width: 416px;
            margin: 0 0 0 48px;
            color: $d7navy;

            .input-wrap {
                display: flex;
                flex-direction: column;
                min-width: 200px;
                width: fit-content;
                &:last-of-type {
                    margin: 0;
                }

                label {
                    display: flex;
                    flex-flow: row;
                    align-items: center;
                    width: fit-content;
                    margin: 0 0 8px;
                    font: {
                        family: $roboto;
                        size: 12px;
                        weight: 500;
                    }
                    .help {
                        content: url("../../images/shared/help.svg");
                        margin: 0 0 0 6px;
                    }
                }
                div.dropdown {
                    width: fit-content;
                    & > button {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                input,
                button {
                    width: 100%;
                    height: 40px;
                    box-sizing: border-box;
                    border: solid 1px $grey;
                    border-radius: 6.93px;
                    padding: 0 8px;
                    color: $d7navy;
                    font: {
                        family: $sfpro;
                        size: 12px;
                        weight: 500;
                    }
                }

                button {
                    display: flex;
                    align-items: center;
                    background: #fff;
                    .drop-triangle {
                        content: url("../../images/shared/drop-triangle.svg");
                    }

                    & input[type="checkbox"] {
                        width: 40px !important;
                    }
                    & label {
                        margin-bottom: unset !important;
                    }
                }
                input {
                    &:focus {
                        outline: none;
                        border-color: $d7teal
                    }
                }

                .tooltip-container {
                    position: relative;
                    display: none;
                    &:hover {
                        .tooltip-container__text {
                            visibility: visible;
                            transition: .4s;
                            opacity: 1;
                        }
                    }

                    &__text {
                    filter: drop-shadow(0px 4px 10px rgba(162, 218, 240, 0.3));
                    visibility: hidden;
                    color: #fff;
                    background-color: $d7teal;
                    text-align: center;
                    white-space: nowrap;
                    padding: 5px 10px;
                    border-radius: 6px;
                    position: absolute;
                    bottom: 125%;
                    left: -8.5px;
                    opacity: 0;
                    z-index: 1;
                
                        &::after {
                            content: " ";
                            position: absolute;
                            top: 100%; /* At the bottom of the tooltip */
                            left: 15%;
                            margin-left: -5px;
                            border-width: 5px;
                            border-style: solid;
                            border-color: $d7teal transparent transparent transparent;
                        }
                    }
                }
            }

            .password-wrap {
                display: flex;
                flex: 1;
                margin: auto;
                padding-bottom: 10px;
                button {
                    @extend %solid-button;
                    @extend %sfpro-default;
                    width: 128px;
                    height: 40px;
                    margin: 0 0 0 16px;
                    box-sizing: border-box;
                    background: $d7teal;
                    border: solid 1px $d7teal;
                    border-radius: 6.93px;
                }

                .password-container {
                    display: flex;
                    flex-flow: row;
                    justify-content: space-between;
                    width: 200px;
                    padding: 0 8px 0 0;
                    border: solid 1px $grey;
                    border-radius: 6.93px;
                    input {
                        font-size: 15px;
                        border: none;
                    }
                    .eye {
                        content: url("../../images/admin/eye.svg");
                    }
                }
            }
        }

        .user-export-buttons,
        .save-changes-buttons {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            width: 100%;

            button {
                @extend %sfpro-default;
                width: 176px;
                height: 40px;
                border-radius: 6.93px;
                margin: 0 16px 0 0;
                &:last-of-type {
                    margin: 0;
                }
            }

            .clear-button {
                @extend %clear-button;
            }

            .solid-button {
                @extend %solid-button;
                background: $d7teal;
                border: solid 1px $d7teal;
            }
        }

        .user-export-buttons {
            margin: 47px 0 0;
        }

        .save-changes-buttons {
            margin: 24px 0 0;
        }
    }

}
