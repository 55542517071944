.queue-grid {
    width: 100%;
    display: grid;
    column-gap: 20px;
    padding: 12px 22px;
    grid-template-columns: auto auto;
    overflow-wrap: normal;

    &--settings {
        display: flex;
        flex-direction: row;
        margin-left: 16px;
        margin-top: 16px;
    }

    .grid-item {
        max-height: 72px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        &--large {
            grid-column-start: 1;
            grid-column-end: 3;
        }
    }

    .grid-item-row {
        max-height: 72px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
    }

    .number-input {
        display: block;
        position: relative;
        margin: 6px 0;

        .basic-modal__input--number {
            margin-top: 0;
        }

        &--create-curriculum,
        &--curriculum-config,
        &--add-question {
            margin: 0
        }
    }
}

.queue-table {
    width: 100%;
    margin: 12px 24px;
    border-radius: 4px;
    background-color: $lightgrey;
    padding-left: 12px;
    tr:last-of-type td {
        padding-bottom: 12px;
    }
    th {
        padding: 12px 12px 6px;
        vertical-align: bottom;
        font-size: 13px;
        color: $d7navy;
    }
    td {
        padding: 0px 12px 0px;
    }
    .dropdown {
        button {
            height: 40px;
        }
    }

}

.queue-track-graphical {
    width: calc(100% - 16px);
    border-collapse: collapse;
    margin: 24px 8px 0;

    &--delivery {
        margin-left: 24px;
        font-weight: bold;
        color: var(--drip7-secondary-color);
    }

    &--row {
        display: flex;
        height: 48px;
        margin-bottom: 20px;
        justify-content: center;
    }

    &--name {
        height: calc(100%);
        width: 128px;
        margin-right: 8px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        justify-content: center;
    }

    &--visualize {
        display: flex;
        align-items: center;
        position: relative;

        width: calc(100%);
        height: calc(100% - 4px);

        &--entry {
            position: absolute;
            height: 100%;
            cursor: pointer;
        }
    }
}

.dropdown-side-by-side {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-end;
}
