.central {
    height: 880px;
    &__nav {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        border-bottom: solid 1px $lightgrey;

        &--no-list {
            border-bottom: none;
        }

        &-head {
            width: 100%;
            padding: 24px 16px 16px;
            text-align: left;
            color: $d7navy;
            font: {
                family: $roboto;
                size: 20px;
                weight: 700;
            }
        }

        &-list {
            display: flex;
            align-items: center;
            list-style: none;
            padding: 0;
            margin: 0;
            letter-spacing: 0.37px;
            font: {
                family: $sfpro;
                size: 16px;
                weight: 500;
            }
        }
        &-item {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            position: relative;
            min-width: 175px;
            padding: 24px;
            margin: 0 2px 0 0;
            white-space: nowrap;
            color: $d7navy;
            transition: 0.1s;
            &:hover {
                color: $d7teal;
                transition: 0.1s;
                cursor: pointer;
            }
            &:last-of-type {
                margin: 0;
            }
            svg {
                min-width: 15px;
                margin: 0 11px 0 0;
            }
            &--selected {
                color: $d7teal;
                &:hover {
                    cursor: initial;
                }
                &::after {
                    display: block;
                    content: "";
                    width: 100%;
                    height: 3px;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    background: $d7teal;
                }
            }
        }
    }

    &__main {
        // overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        .solid-button {
            @extend %solid-button;
        }
    }

    &__head {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        position: sticky;
        width: 100%;
        top: 0;
        padding: 16px 16px 16px 24px;
        background: #fff;
        box-shadow: 0 0 1px 1px #eceff1;
        z-index: 5;
        &-container {
            display: flex;
            flex-flow: row;
            align-items: center;
            color: $d7navy;
            & > * {
                margin: 0 16px 0 0;

                &:last-child {
                    margin: 0;
                }
            }
        }

        &-dropdown {
            display: flex;
            flex-flow: column;
            position: relative;
            &-button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 97px;
                height: 40px;
                padding: 0 16px;
                box-sizing: content-box;
                background: $d7teal;
                color: #fff;
                border: none;
                border-radius: 6.93px;
                font: {
                    family: $sfpro;
                    size: 13px;
                    weight: 400;
                }
                svg {
                    transition: 0.2s;
                }
                &--bottom {
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    text-align: left;
                }
            }
            &--expand {
                .central__head-dropdown-button {
                    &--top {
                        border-radius: 6.93px 6.93px 0 0;
                    }
                    &--bottom {
                        height: 38px;
                        border-radius: 0 0 6.93px 6.93px;
                        border-top: solid 1px #fff;
                        animation: filter-dropdown-expand 0.25s ease-out;
                        position: absolute;
                        top: 40px;
                    }
                    svg {
                        transform: rotate(180deg);
                        transition: 0.2s;
                    }
                }
            }
            &--collapse {
                .central__head-dropdown-button {
                    &--top {
                        border-radius: 6.93px;
                        border: none;
                        animation: filter-dropdown-reset 0.25s ease-out;
                    }
                    &--bottom {
                        top: 0;
                        animation: filter-dropdown-collapse 0.25s ease-out;
                        border-radius: 6.93px;
                    }
                    svg {
                        transform: rotate(0);
                        transition: 0.2s;
                    }
                }
            }
        }

        &-search {
            &-wrapper {
                display: flex;
                align-items: center;
                width: 260px;
                height: 40px;
                padding: 0 0 0 6px;
                color: $d7navy;
            }
            width: 226px;
            padding: 0;
            margin: 0 0 0 16px;
            border: none;
            font: {
                family: $sfpro;
                size: 13px;
                weight: 400;
            }
            &:focus {
                outline: none;
            }
            &::placeholder {
                color: $greymedium;
            }
        }

        &-date {
            margin: 0 48px 0 0;
            font: {
                family: $sfpro;
                size: 16px;
                weight: 600;
            }
        }

        &-nav-button {
            border: none;
            line-height: 20px;
            background: rgba(236, 239, 241, 0.3);
            color: $d7navy;
            font: {
                family: $sfpro;
                size: 13px;
                weight: 500;
            }
            &-wrapper {
                display: inline-flex;
                height: 40px;
                border: solid 1px $navgrey;
                border-radius: 6.93px;
                overflow: hidden;
                &--day {
                    width: 128px;
                }
                &--week {
                    width: 96px;
                }
                .central__head-nav-button {
                    border-right: solid 1px $navgrey;
                    &:last-of-type {
                        border: none;
                    }
                }
            }

            &--arrow {
                width: 32px;
            }

            &--text {
                width: 64px;
                padding: 10px 0;
            }
        }

        &-button {
            width: 176px;
            height: 40px;
            border-radius: 6.93px;
            font: {
                family: $sfpro;
                size: 13px;
                weight: 400;
            }
            &--add-category {
                width: 152px;
                svg {
                    margin: 0 10px 0 0;
                }
            }
            &--solid {
                @extend %solid-button;
                background-color: $d7teal !important;
                border: solid 1px $d7teal;
            }
            &--clear {
                @extend %clear-button;
                margin: 0 16px 0 0;
            }
            &--remove {
                @extend %solid-button;
                background: $red !important;
                border: solid 1px $red;
            }
            &-container {
                display: inline-flex;
                gap: 8px;
            }
        }
    }

    &__filters {
        display: inline-flex;
        align-items: center;
        width: 100%;
        margin: 24px 0 0;
        position: sticky;
        top: 0;

        &-container {
            display: inline-flex;
            justify-content: space-between;
            flex-grow: 1;
            margin: 0;
        }
        &-button {
            height: 40px;
            border-radius: 6.93px;
            font: {
                family: $sfpro;
                size: 13px;
                weight: 500;
            }
            &--solid {
                @extend %solid-button;
                width: 152px;
                background: $d7teal;
                border: solid 1px $d7teal;
            }
            &--clear {
                background: none;
                color: $red;
                border: none;
                svg {
                    margin: 0 11px 0 0;
                }
            }
        }

        .dropdown {
            @extend %attached-drop;
            margin: 0 8px 0 0;
            &:last-of-type {
                margin: 0;
            }

            .btn {
                @extend %solid-button;
                background: $d7teal;
                border: solid 1px $d7teal;
                border-radius: 6.93px;

                &:focus {
                    background: $d7teal;
                    border: solid 1px $d7teal;
                    box-shadow: none !important;
                }
            }
        }

        .dropdown-toggle {
            &::after {
                display: inline-flex;
                content: url('../../images/shared/drop-triangle-white.svg');
                border: none;
                margin: 0 0 3px 3px;
            }
        }
    }
    &__add-category {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 24px 0 0;
    }
}
