/**
* @description responsive view-port , set breakpoint and auto create responsivility
*/
@mixin style-for($breakpoint) {
    @if $breakpoint==tablet {
        @media only screen and (max-width: 600px) {
            @content;
        }
    }

    @if $breakpoint==mobile {
        @media only screen and (max-width: 767px) {
            @content;
        }
    }

    @if $breakpoint==desktop {
        @media only screen and (min-width: 767px) {
            @content;
        }
    }

    @if $breakpoint ==desktop-large {
        @media only screen and (min-width: 1440) {
            @content;
        }
    }
}
