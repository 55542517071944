.create-group {
    width: 100%;
    padding: 24px;
    margin: 12px 24px;
    border-radius: 4px;
    background-color: $lightgrey;

    .search-bar-container {
        width: 280px;
        padding: 12px 8px;
        line-height: 16px;
        border: solid 1px #cfd8dd;
        border-radius: 6.93px;
        background: #fff;

        &:focus-within {
            border-color: var(--drip7-primary-color);
        }
        .search-input:focus {
            outline: none;
            border: none;
        }
    }

    p {
        font-size: 0px;
    }

    &__users {
        &-container {
            display: flex;
            flex-flow: row;
            align-items: center;
            gap: 16px;
            width: 100%;
            margin: 16px 0 0;

            svg {
                width: 24px;
                height: 24px;
                color: $d7navy;
            }
        }

        width: calc(50%);
        font: {
            family: $roboto;
        }

        h5 {
            color: $d7navy;
            font: {
                size: 16px;
                weight: 700;
            }
        }
        h3 {
            color: $d7navy;
            padding: 0;
            font: {
                size: 16px;
                weight: 700;
            }
        }

        &-list {
            list-style: none;
            padding: 0;
            height: 160px;
            max-height: 160px;
            overflow-y: scroll;
            scrollbar-width: 6px;
            &::-webkit-scrollbar {
                width: 6px;
                &-track {
                    background: none;
                }
                &-thumb {
                    background: $navgrey;
                    border-radius: 6px;
                    width: 6px;
                }
            }
            background: #fff;
            padding: 4px 8px;
            border-radius: 6.93px;
        }

        &-item {
            width: 100%;
            line-height: 22px;
            border-radius: 4px;
            padding: 2px 4px;
            cursor: pointer;
            font: {
                size: 13px;
            }
            &:hover {
                background: var(--drip7-primary-color-25);
            }
        }

        .create-group-users-item-button {
            padding: 0px;
            border: none;
            background-color: none;
        }
    }
}
