.edit-user-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 152px;
    height: 112px;
    position: absolute;
    top: -16px;
    right: 42px;
    background: #fff;
    box-shadow: $defaultshadow;
    border-radius: 12px;

    .edit-user-wrap {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 16px 8px;

        ul {
            padding: 0;
            list-style: none;
            font: {
                family: $roboto;
                size: 13px;
                weight: 700;
            }

            li {
                display: flex;
                flex-flow: row;
                align-items: center;
                margin: 16px 0;
                color: $d7navy;

                img {
                    width: 24px;
                    height: 16px;
                    margin: 0 8px 0 0;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            .delete-user {
                color: $red;
            }
        }
    }

    &::after {
        display: block;
        content: "";
        width: 0;
        height: 0;
        margin: -13px;
        border: solid 13px #fff;
        border-radius: 5px;
        transform: rotate(45deg);
        position: relative;
        right: 5px;
        bottom: 5px;
    }
}
