.button-container {
    & > * {
        margin: 0 8px 0 0;
        &:last-child {
            margin: 0;
        }
    }
}

.buttons-trivia {
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: center;
    align-items: center;
}
