@import "../helpers/mixin";

.daily-questions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    min-height: 70vh;
    padding: 32px 0;
    &__heading {
        width: 100%;
        text-align: left;
        @include style-for(mobile) {
            display: none;
        }
        h1 {
            margin: 35px 0 33px;
        }
    }

    &__progression {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 90%;
        &-stats {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            width: 100%;
            h4 {
                margin: 0 0 4px;
                color: $darkgrey;
            }
        }

        &-bar {
            width: 100%;
            height: 16px;
            position: relative;
            background: $lightgrey;
            border-radius: 8px;
            &-bg {
                background: $lightgrey;
            }
            &-fg {
                height: 100%;
                position: absolute;
                left: 0;
                background: $d7teal;
                border-radius: 8px;
                transition: 0.2s;
            }
        }
    }

    &__body {
        display: flex;
        padding: 1rem;
        flex-flow: column;
        // justify-content: center;
        gap: 1rem;
        flex-grow: 1;
        align-items: center;
        width: 100%;
        > div:last-child {
            margin-bottom: auto;
        }
    }

    .daily-questions__answers {
        margin: auto;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .checkbox {
            height: 16px;
            max-width: 16px;
            border-radius: 6.93px;

            &__container {
                display: grid;
                width: 85%;
                grid-template-columns: 16px 1fr;
                margin: 20px;
            }
        
            &__label {
                font: {
                    family: $roboto;
                    size: 14px;
                    weight: 700;
                }
            }
        }
    }

    &__question {
        display: flex;
        justify-content: center;
        width: 100%;
        text-align: center;
        margin-top: auto;

        h4 {
            @include style-for(desktop) {
                width: 85%;
                margin: 0;
            }
            overflow: visible;
            color: $d7navy;
            line-height: 32px !important;
            font: {
                size: 22px !important;
            }
        }

        .question-blank {
            color: $grey;
        }

        &--image {
            margin: 0;
        }
    }

    &__video {
        width: 100%;
        max-width: 368px;
        // height: 350px;
        margin: 40px 0;
        background: linear-gradient(
            0deg,
            rgba(96, 125, 139, 0.4),
            rgba(96, 125, 139, 0.4)
        );
        border-radius: 15px;
        box-shadow: $darkshadow;
    }

    &__image {
        max-height: 350px;
        min-height: 150px;
        border-radius: 15px;
        box-shadow: $darkshadow;
        overflow: hidden;
        max-width: 100%;

        & ~ .daily-questions__answers {
            margin: 16px 0 0;
        }
    }

    button {
        min-height: 48px;
        box-sizing: border-box;
        /*border: none;*/
        border-radius: 6.93px;
        font: {
            family: $roboto;
            size: 17px;
            weight: 700;
        }
    }


    &__answer--large {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 20px;
        color: $d7navy;
        width: 85%;
        padding: 8px;
        min-height: 64px;
        border-radius: 6.93px;
        box-shadow: $defaultshadow;
        font: {
            family: $roboto;
            size: 14px;
            weight: 700;
        }
        &:hover {
            box-shadow: $darkshadow;
            transition: .4s box-shadow;
        }

        .drop-chevron--answer {
            height: 10px;
            transform: rotate(-90deg)
        }
    }

    &__answers {
        display: grid;
        grid: 1fr 1fr / 1fr 1fr;
        gap: 8px 18px;
        padding: 0 16px;
        margin: 32px 0 0;

        .slide-value {
            color: $d7navy;
            font: {
                size: 14px;
                weight: 700;
            }
        }

        &--slider {
            display: inline-block;
            width: 85%;

            & > div {
                width: 100%;
                display: flex;
                grid-template-columns: 2fr 1fr 2fr;
                text-align: center;
                font-size: 13px;
                color: $d7navy;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
            }
        }

        &--large {
            display: flex;
            width: 85%;
            height: 350px;
        }

        &--checkbox {
            display: flex;
        }
    }

    &__answer {
        display: inline-flex;
        align-items: center;
        padding: 8px;
        position: relative;
        text-align: left;
        line-height: 1;
        background: #fff;
        color: $d7navy;
        box-shadow: $defaultshadow;

        &--text {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 22px;
            overflow: hidden;

            & > div:last-of-type {
                color: $greymedium;
                font-weight: 300;
                font-size: 13px;
            }
        }

        span {
            color: $d7teal;
            margin: 0 32px 0 0;
            width: 12px;
        }

        &:hover {
            cursor: pointer;
            transition: .4s box-shadow;
            box-shadow: $darkshadow;
        }

        &--correct,
        &--incorrect,
        &--selection {
            color: #fff;
            span {
                color: #fff;
            }
            i {
                filter: brightness(0) invert(1);
            }
            .daily-questions__answer--text > div:last-of-type {
                color: #fff
            }
            &:hover {
                cursor: initial;
            }
        }

        &--correct {
            background: $green;
            /*
            &:after {
                content: url("../../images/user-portal/answer-checkmark.svg");
                width: 17px;
                height: 20px;
                position: absolute;
                right: 8px;
            }
            */
        }

        &--incorrect {
            background: $red;
            /*
            &:after {
                content: url("../../images/user-portal/answer-x.svg");
                width: 12px;
                height: 20px;
                position: absolute;
                right: 8px;
            }
            */
        }

        &--selection {
            background: $d7teal;
        }

        &--chevron {
            display: flex;
            align-items: center;
        }
    }

    &__true-or-false {
        display: flex;
        justify-content: space-between;
        width: 392px;
    }

    &__true,
    &__false {
        width: 187px;
        position: relative;
        background: #fff;
        color: $d7navy;
        box-shadow: $defaultshadow;
        &:hover {
            transition: .4s box-shadow;
            box-shadow: $darkshadow;
        }

        &--selection {
            background: $d7teal;
            color: #fff;
        }

        &--correct {
            background: $green;
            color: #fff;
            &:after {
                content: url("../../images/user-portal/answer-checkmark.svg");
            }
        }

        &--incorrect {
            background: $red;
            color: #fff;
            &:after {
                content: url("../../images/user-portal/answer-x.svg");
            }
        }
    }

    &__true {
        &--correct,
        &--incorrect {
            &:after {
                position: absolute;
                left: 8px;
            }
        }
    }

    &__false {
        &--correct,
        &--incorrect {
            &:after {
                position: absolute;
                right: 8px;
            }
        }
    }

    &__fitb {
        display: flex;
        justify-content: center;
        width: 100%;
        &-answer {
            width: 312px;
            height: 48px;
            padding: 8px !important;
            box-sizing: border-box !important;
            border: solid 1px $grey !important;
            border-radius: 6.93px !important;
            color: $d7navy !important;
            &--correct {
                color: $green !important;
            }
            &--incorrect {
                color: $red !important;
            }
            font: {
                family: $roboto;
                size: 15px;
                weight: 700;
            }
            &::placeholder {
                color: $greymedium !important;
            }
            &:focus {
                outline: none;
                border-color: $d7teal
            }
        }

        & > textarea {
            height: 96px;
            resize: none;
        }
    }

    &__scramble {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 100%;

        &-letters,
        &-answer {
            display: flex;
            flex-flow: row;
            justify-content: center;
            width: 100%;
        }

        &-letters {
            height: 72px;
            margin: 32px 0;
        }

        &-answer {
            margin: 0;
        }

        &-hint {
            line-height: 32px;
            margin: 24px 0 88px;
            color: $d7teal;
            text-decoration: underline;
            font: {
                family: $roboto;
                size: 17px;
                weight: 700;
            }
            &:hover {
                cursor: pointer;
            }
        }

        &-tile,
        &-tile--holder,
        &-tile--solved,
        &-tile--used {
            width: 32px;
            height: 32px;
            margin: 0 4px;
            border-radius: 6.93px;
        }

        &-tile,
        &-tile--solved {
            display: flex;
            justify-content: center;
            align-items: center;
            background: $d7teal;
            color: #fff;
            font: {
                family: $roboto;
                size: 15px;
                weight: 700;
            }
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        &-tile {
            &:hover {
                cursor: grab;
            }
            &:active {
                cursor: grabbing;
            }

            &--hint {
                background: $green;
                &:hover {
                    cursor: initial;
                }
                &:active {
                    cursor: initial;
                }
            }

            &--bottom {
                align-self: flex-end;
            }

            &--top {
                align-self: flex-start;
            }

            &--used {
                opacity: 0;
                &:hover {
                    cursor: initial;
                }
                &:active {
                    cursor: initial;
                }
            }

            &--solved {
                &:hover {
                    cursor: initial;
                }
                &:active {
                    cursor: initial;
                }
            }

            &--holder {
                background: none;
                border: solid 1px $grey;
                box-sizing: border-box;
            }
        }
    }

    &__nav {
        display: flex;
        justify-content: space-between;
        width: 560px;
        &-button {
            position: relative;
            border: none;
            background-color: $d7teal;
            color: #fff;
            line-height: 20px;
            &:hover {
                transition: .4s;
                filter: brightness(1.12)
            }
            &--previous,
            &--next {
                width: 208.37px;
                svg {
                    position: absolute;
                }
            }
            &--help {
                width: 117.21px;
                svg {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            &--previous {
                svg {
                    left: 8px;
                }
            }
            &--next {
                svg {
                    right: 8px;
                }
            }
            &:disabled {
                background-color: $lightgrey;
                color: $greymedium;
                filter: none;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .daily-questions__answers--slider {
        width: 100%;
    }
    
    
    .daily-questions {
        padding: 16px;
        height: 90vh;
        min-height: 90vh;
        gap: 20px;
        &__body {
            max-height: 80vh;
            overflow: auto;
        }
        &__progression {
            width: 100%;
        }

        &__question {
            h4 {
                margin: 0 !important;
            }
        }
        &__true-or-false,
        &__answers {
            width: 100%;
            max-width: 540px;
            margin: 0;
        }

        &--fitb {
            .daily-questions__fitb {
                margin: 0;
            }
        }

        &__answers {
            display: flex;
            flex-flow: row wrap;
            height: fit-content;
            gap: 8px;

            &--large {
                width: 100%;
            }
        }

        &__true,
        &__false {
            width: 49%;
            gap: 8px;
        }

        &__answer {
            width: 100%;
            box-shadow: $defaultshadow;

            &--large {
                width: 100%;
            }
        }

        &__scramble {
            &-tile {
                min-width: 32px;
            }
        }

        &__nav {
            width: calc(100% - 32px);
            max-width: 540px;
            bottom: 16px;
            &-button {
                flex-grow: 2;
                padding: 14px;
                &--help {
                    width: 72px;
                    margin: 0 8px;
                }
            }
        }
    }
}
