.curriculum__timeline {
    display: grid;
    grid-auto-flow: column;
    min-height: 680px;
    padding: 0 0 0 24px;
    margin: 20px 0 0;
    position: relative;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    &-queue {
        &-container {
            display: flex;
            flex-flow: column;
            width: 304px;
            height: 100%;
            margin: 19px 8px 0 0;
        }

        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        width: 304px;
        height: 56px;
        padding: 0 8px 0 16px;
        margin: 4px 0;
        position: relative;
        background: rgba(96, 125, 139, 0.05);
        color: $d7navy;
        &-text {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            width: 219px;
            svg {
                &:hover {
                    cursor: pointer;
                }
            }
        }

        &-name {
            margin: 0;
            letter-spacing: 0.37px;
            font: {
                family: $sfpro;
                size: 16px;
                weight: 600;
            }
        }

        &-add {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            background: $d7teal;
            border: none;
            border-radius: 6.93px;
            color: #fff;
        }

        &-new {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            height: 56px;
            padding: 18px 0;
            margin: 12px 0 0;
            background: #fff;
            border: none;
            border-radius: 8px;
            box-shadow: $defaultshadow;
            line-height: 20px;
            color: $d7navy;
            font: {
                family: $sfpro;
                size: 13px;
                weight: 500;
            }
            svg {
                margin: 0 11px 0 0;
                height: 15px;
            }
        }
    }

    &-slider {
        &-container {
            margin-top: 23px;
        }
        &-row {
            display: flex;
            align-items: center;
            width: 100%;
            height: 56px;
            margin: 0 0 8px;
            position: relative;
        }
        display: flex;
        align-items: center;
        min-width: 136px;
        height: 40px;
        padding: 10px;
        position: absolute;
        left: 23px;
        white-space: nowrap;
        border-radius: 6px;
        overflow: hidden;
        z-index: 2;
        &-name {
            width: 100%;
            margin: 0;
            text-align: center;
            color: #fff;
            font: {
                family: $roboto;
                size: 13px;
                weight: 500;
            }
            svg {
                margin: 0 5px 0 0;
            }
        }
        &:before,
        &:after {
            display: block;
            content: "";
            width: 10px;
            height: 40px;
            position: absolute;
            top: 0;
        }
        &:before {
            left: 0;
        }
        &:after {
            right: 0;
        }
        &--1-out {
            left: calc(23px + $calblockwidth);
        }
        &--2-out {
            left: calc(23px + ($calblockwidth * 2));
        }
        &--3-out {
            left: calc(23px + ($calblockwidth * 3));
        }
        &--4-out {
            left: calc(23px + ($calblockwidth * 4));
        }
        &--5-out {
            left: calc(23px + ($calblockwidth * 5));
        }
        &--6-out {
            left: calc(23px + ($calblockwidth * 6));
        }
        &--7-out {
            left: calc(23px + ($calblockwidth * 7));
        }
        &--8-out {
            left: calc(23px + ($calblockwidth * 8));
        }
        &--9-out {
            left: calc(23px + ($calblockwidth * 9));
        }
        &--10-out {
            left: calc(23px + ($calblockwidth * 10));
        }
        &--11-out {
            left: calc(23px + ($calblockwidth * 11));
        }
        &--12-out {
            left: calc(23px + ($calblockwidth * 12));
        }
        &--13-out {
            left: calc(23px + ($calblockwidth * 13));
        }
        &--14-out {
            left: calc(23px + ($calblockwidth * 14));
        }
        &--15-out {
            left: calc(23px + ($calblockwidth * 15));
        }
        &--16-out {
            left: calc(23px + ($calblockwidth * 16));
        }
        &--17-out {
            left: calc(23px + ($calblockwidth * 17));
        }
        &--18-out {
            left: calc(23px + ($calblockwidth * 18));
        }
        &--19-out {
            left: calc(23px + ($calblockwidth * 19));
        }
        &--20-out {
            left: calc(23px + ($calblockwidth * 20));
        }
        &--21-out {
            left: calc(23px + ($calblockwidth * 21));
        }
        &--22-out {
            left: calc(23px + ($calblockwidth * 22));
        }
        &--23-out {
            left: calc(23px + ($calblockwidth * 23));
        }
        &--24-out {
            left: calc(23px + ($calblockwidth * 24));
        }
        &--25-out {
            left: calc(23px + ($calblockwidth * 25));
        }
        &--26-out {
            left: calc(23px + ($calblockwidth * 26));
        }
        &--27-out {
            left: calc(23px + ($calblockwidth * 27));
        }
        &--28-out {
            left: calc(23px + ($calblockwidth * 28));
        }
        &--29-out {
            left: calc(23px + ($calblockwidth * 29));
        }
        &--30-out {
            left: calc(23px + ($calblockwidth * 30));
        }
        &--2-long {
            width: calc($calblockwidth * 2);
        }
        &--3-long {
            width: calc($calblockwidth * 3);
        }
        &--4-long {
            width: calc($calblockwidth * 4);
        }
        &--5-long {
            width: calc($calblockwidth * 5);
        }
        &--6-long {
            width: calc($calblockwidth * 6);
        }
        &--7-long {
            width: calc($calblockwidth * 7);
        }
        &--purple {
            background: $purple;
            &:before,
            &:after {
                background: $purpletab;
            }
        }
        &--blue {
            background: $calendarblue;
            &:before,
            &:after {
                background: $bluetab;
            }
        }
        &--pink {
            background: $pink;
            &:before,
            &:after {
                background: $pinktab;
            }
        }
        &--magenta {
            background: $magenta;
            &:before,
            &:after {
                background: $magentatab;
            }
        }
    }

    &-calendar {
        display: inline-flex;
        width: 100%;
        min-height: 680px;
        height: 100%;
        padding: 0 24px 0 0;
        overflow-y: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        &-date {
            min-width: $calblockwidth;
            &:last-of-type {
                .curriculum__timeline-calendar-block {
                    border: solid 1px #f3f3f3;
                }
            }
        }

        &-day {
            text-align: center;
            color: $d7navy;
            font: {
                family: $roboto;
                size: 13px;
                weight: 700;
            }
        }

        &-block {
            min-height: 680px;
            height: calc(100% - 30px);
            position: relative;
            border: {
                top: solid 1px #f3f3f3;
                right: none;
                bottom: solid 1px #f3f3f3;
                left: solid 1px #f3f3f3;
            }
        }
    }
}
