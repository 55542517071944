.curriculum {
    &__group {
        &-container {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
        }
        display: flex;
        flex-flow: column;
        width: 256px;
        margin: 0 8px 24px;
        &-label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 56px;
            padding: 0 16px;
            position: relative;
            background: rgba(96, 125, 139, 0.05);
            border-radius: 8px 8px 0 0;
            color: $d7navy;
            svg {
                &:hover {
                    cursor: pointer;
                }
            }
        }
        &-name {
            margin: 0;
            font: {
                family: $sfpro;
                size: 16px;
                weight: 600;
            }
        }
    }

    &__details {
        border-radius: 12px;
        overflow: hidden;
        box-shadow: $defaultshadow;
        margin: 8px 0;
        &-head {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 48px;
            position: relative;
            color: #fff;
            svg {
                position: absolute;
                left: 8px;
                opacity: 0.5;
            }
            &--purple {
                background: $purple;
            }
            &--blue {
                background: $blue;
            }
            &--magenta {
                background: $magenta;
            }
            &--pink {
                background: $pink;
            }
            &:hover {
                cursor: grab;
            }
            &:active {
                cursor: grabbing;
            }
        }

        &-name {
            margin: 0;
            text-align: center;
            font: {
                family: $sfpro;
                size: 16px;
                weight: 500;
            }
        }

        &-body {
            display: flex;
            flex-flow: row wrap;
            padding: 16px;
        }

        &-list {
            list-style: none;
            padding: 0;
        }

        &-item {
            display: inline-flex;
            justify-content: space-between;
            width: 100%;
            p {
                margin: 0;
                color: $d7navy;
                font: {
                    family: $roboto;
                    size: 13px;
                    weight: 700;
                }
            }
        }
        &-stat {
            opacity: 0.5;
        }

        &-wrapper {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }

        &-status {
            width: 84px;
            height: 24px;
            padding: 2px 7px;
            margin: 0;
            border-radius: 12px;
            font: {
                family: $sfpro;
                size: 13px;
                weight: 500;
            }
            &--in-progress {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                color: $green;
                background: rgba(45, 223, 159, 0.1);
                &::before {
                    content: "In Progress";
                    margin: 0;
                }
            }
        }

        &-user {
            &-container {
                display: flex;
                flex-flow: row-reverse;
                width: 96px;
                height: 38px;
            }
            width: 38px;
            height: 38px;
            margin: 0 -9px 0 0;
            border: solid 3px #fff;
            border-radius: 50%;
            background: purple;
        }

        &-add {
            width: 100%;
            height: 56px;
            padding: 18px 0;
            margin: 8px 0 0;
            line-height: 20px;
            background: #fff;
            border: none;
            border-radius: 12px;
            color: $d7navy;
            box-shadow: $defaultshadow;
            font: {
                family: $sfpro;
                size: 13px;
                weight: 500;
            }
            svg {
                margin: 0 11px 4px 0;
            }
        }
    }
}
