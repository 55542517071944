.login-page {
    display: flex;
    justify-content: center;
    background: $bgblue;
    line-height: 20px;
    font: {
        family: $roboto;
        weight: 700;
        size: 17px;
    }

    p {
        color: $d7navy;
    }

    a {
        color: $d7teal;
    }
    &__language-select {
        position: fixed;
        top: 10px;
        right: 10px;
        z-index: 10;
        display: flex;
        .dropdown-menu {
            width: auto;
        }
        .language-button {
            .btn-primary {
                background-color: transparent;
                border-color: transparent; 
                padding: 4px;
                &:focus,
                &:active {
                    background-color: transparent !important;
                }
                &:before {
                    content: url("../../images/shared/sphere.svg");
                    display: inline-block;
                    border: none;
                    width: 18px;
                    margin-right: 6px;
                    position: relative; 
                    top: 2px;
                }
            }
        }
    }
}

.login-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: row wrap;
    width: 392px;

    .logo {
        max-height: 400px;
        margin-top: 1rem;
        img {
            max-height: 400px;
            width: 100%;
        }
    }
}

.login-error {
    border-radius: 5px 5px 5px 5px;
    overflow: hidden;
    background: #fff;
    box-shadow: $defaultshadow;
    border-left: solid 8px $errorred;
    margin-bottom: 1rem;

    .login-error-wrap {
        padding: 16px 20.1px 16px 30.15px;
        p {
            margin: 0;
        }
    }
}

.login-fields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    div.user-password-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        margin-bottom: 1.4rem;
    }

    .btn {
        background: $d7teal;
        color: #fff;
        width: 392px;
        padding: 0.8rem;
        border: none;
        border-radius: 6.93px;
        &:last-of-type {
            margin: 0;
        }
        line-height: 20px;
        font: {
            family: $roboto;
            size: 17px;
            weight: 700;
        }
        &:hover {
            cursor: pointer;
        }
    }

    .login-help {
        text-align: center;
        line-height: 1.5rem;
        p {
            margin: 0;
        }
    }
}

.login {
    .header-logo {
        max-height: 400px;
    }
}

@media only screen and (max-width: 450px) {
    .login-wrap {
        width: 85%;
    }

    .login-menu {
        width: 100%;
    }

    .login-fields {
        .input-container,
        .btn {
            width: 100%;
            .input-field {
                width: 100%;
            }
        }
    }
}
