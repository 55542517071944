.radial-progress__half-circle,
.radial-progress__half-circle-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    z-index: 50;
    transform-origin: right center;
}

.radial-progress {
    &__half-circle {
        background: $d7teal;
        &--first-50 {
            transform: rotate(180deg);
        }

        &--second-50 {
            transform: rotate(270deg);
            z-index: 51;
        }

        &-cover {
            background: $lightgrey;
        }
    }
}
