.register {
    display: flex row;
    background-color: #fff!important;
}

.register-header {
    background-color: #1F4760!important;
}

.register-banner {
    display: flex;
    color: #fff!important;
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
    width: 100%;
}

.register-welcome {
    h1 {
        font-size: 5vh;
        padding-left: 2em;
        padding-right: 1em;
        padding-top: 1.5em;
        padding-bottom: 10px;
        color:#fff;
    }
    p{
        padding-left: 2em;
        padding-right: 1em;
        padding-bottom: 10px;
        color: #fff;
        font-size: 2.3vh;
        line-height: 1.8em;
    }
}

.register-text {
    padding-bottom: 1.5em;
}

.register-video {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
}

.register-wave {
    background-image:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSI2NHB4IiB2aWV3Qm94PSIwIDAgMTI4MCAxNDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iI0ZGRkZGRiI+PHBhdGggZD0iTTEyODAgMy40QzEwNTAuNTkgMTggMTAxOS40IDg0Ljg5IDczNC40MiA4NC44OWMtMzIwIDAtMzIwLTg0LjMtNjQwLTg0LjNDNTkuNC41OSAyOC4yIDEuNiAwIDMuNFYxNDBoMTI4MHoiIGZpbGwtb3BhY2l0eT0iLjMiLz48cGF0aCBkPSJNMCAyNC4zMWM0My40Ni01LjY5IDk0LjU2LTkuMjUgMTU4LjQyLTkuMjUgMzIwIDAgMzIwIDg5LjI0IDY0MCA4OS4yNCAyNTYuMTMgMCAzMDcuMjgtNTcuMTYgNDgxLjU4LTgwVjE0MEgweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik0xMjgwIDUxLjc2Yy0yMDEgMTIuNDktMjQyLjQzIDUzLjQtNTEzLjU4IDUzLjQtMzIwIDAtMzIwLTU3LTY0MC01Ny00OC44NS4wMS05MC4yMSAxLjM1LTEyNi40MiAzLjZWMTQwaDEyODB6Ii8+PC9nPjwvc3ZnPg==);
    background-size: 100% 64px;
    bottom: 0;
    height: 64px;
    z-index: 1;
    transform: scale(1,1);
}

.register-menu {
    width: 392px;
    margin-left: auto;
    margin-right: auto;
}

.register-error {
    border-radius: 5px 5px 5px 5px;
    overflow: hidden;
    background: #fff;
    box-shadow: $defaultshadow;
    border-left: solid 8px $errorred;
    margin-bottom: 1rem;

    .register-error-wrap {
        padding: 16px 20.1px 16px 30.15px;
        p {
            margin: 0;
        }
    }
}

.register-fields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1em;
    div.user-password-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        margin-bottom: 1.4rem;
    }

    input {
        width: 392px;
    }

    .btn {
        background: $d7teal;
        color: #fff;
        width: 392px;
        padding: 0.8rem;
        border: none;
        border-radius: 6.93px;
        &:last-of-type {
            margin: 0;
        }
        line-height: 20px;
        font: {
            family: $roboto;
            size: 17px;
            weight: 700;
        }
        &:hover {
            cursor: pointer;
        }
    }

    .register-help {
        text-align: center;
        line-height: 1.5rem;
        p {
            margin: 0;
        }
    }
}

@media only screen and (max-width: 450px) {
    .register-welcome {
        h1 {
            font-size: 4vh;
            padding-left: 1em;
            padding-right: 1em;
            padding-top: 1em;
            padding-bottom: 10px;
            color:#fff;
        }
        p{
            padding-left: 1em;
            padding-right: 1em;
            padding-bottom: 10px;
            color: #fff;
            font-size: 2vh;
            line-height: 1.8em;
        }
    }
    .register-menu {
        width: 100%;
    }
    .register-fields {
        margin-left: 10%;
        margin-right: 10%;
            .input-container,
        .btn {
            width: 100%;
            .input-field {
                width: 100%;
            }
        }
    }
    .register-video {
        visibility: hidden;
        width: 0%;
    }
}