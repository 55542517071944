.user-mini-profile {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 39px;
    border: none;
    background-color: transparent;

    .user-profile-photo {
        width: 34px;
        height: 34px;
        background: var(--drip7-secondary-color);;
        border-radius: 50%;
        border: 3px solid #ffffff;
        box-sizing: content-box;
        filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.15));
    }
    
    a {
        color: inherit;
        text-decoration: none;
    }
    .user-mini-info {
        margin: 0 14px 0 19px;
        font-family: $sfpro;
        line-height: 20px;

        h3 {
            margin: 0;
            font: {
                size: 16px;
                weight: 500;
            }
        }
        p {
            margin: -2px 0 0;
            font: {
                size: 16px;
                weight: 500;

            }
            letter-spacing: -0.24px;
            &.user-name {
                margin: 0;
                letter-spacing: 0;
                opacity: 1;
                font: {
                    size: 16px;
                    weight: 600;
                }
            }
        }
    }

    svg {
        &:hover {
            cursor: pointer;
        }
    }
}

.button-user-mini-profile {
    background-color: transparent;
    color: inherit;
    border: none;
}