.sidebar {
    @extend %scroll-nobar;
    display: flex;
    flex-flow: column;
    width: 224px;
    padding: 18px 0 8px;
    background-color: #fff;
    border-right: 1px solid $lightgrey;
    height: 100vh;
    position: fixed;
    z-index: 50;
    font: {
        family: $sfpro;
        size: 16px;
        weight: 600;
    }

    &__header {
        display: flex;
        flex-flow: row;
        justify-content: center;
        width: 100%;
        position: relative;
        & img {
            object-fit: scale-down;
            position: relative;
            width: 100px;
            height: 60px;
            margin-bottom: -10px;
            opacity: 1;
            transition: 0.4s;
            z-index: 1;
            &.active {
                opacity: 0;
                transition: 0.4s;
            }
        }
    }

    &__hamburger {
        position: relative;
        z-index: 2;
        cursor: pointer;
        position: absolute;
        top: 6px;
        right: 16px;
    }

    &__nav {
        margin: 22px 0 0;
        flex-grow: 1;
        &-list {
            width: 100%;
            list-style: none;
            padding: 0;
        }
        &-item {
            display: inline-flex;
            align-items: center;
            width: 100%;
            height: 40px;
            padding: 10px 0 10px 16px;
            margin: 8px 0;
            position: relative;
            color: $d7navy;
            transition: 0.1s;

            &:hover {
                color: $d7teal;
                transition: 0.1s;
                cursor: pointer;
            }

            &:first-of-type {
                margin: 0 0 8px;
            }

            &:last-of-type {
                margin: 8px 0 0;
            }

            p {
                height: 20px;
                margin: 0;
                position: absolute;
                left: 42px;
            }

            svg {
                height: 15px;
            }

            &--15 {
                padding: 10px 0 10px 15px;
            }

            &--17 {
                padding: 10px 0 10px 17px;
            }

            &--selected {
                background-color: var(--drip7-primary-color-05);
                color: $d7teal;
                &:hover {
                    cursor: default;
                }
                &::after {
                    @extend %navbar-selected;
                    background-color: $d7teal;
                }
            }
        }
    }

    &__logout {
        display: inline-flex;
        align-items: center;
        width: 100%;
        height: 40px;
        min-height: 40px;
        padding: 0 0 0 16px;
        margin: 22px 0 0;
        color: $red;
        transition: 0.1s;
        position: relative;

        p {
            margin: 0;
            position: absolute;
            left: 42px;
        }

        &:hover {
            cursor: pointer;
            &::after {
                @extend %navbar-selected;
                background-color: $red;
            }
        }
    }
}
