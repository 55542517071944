%navbar-selected {
    display: block;
    content: "";
    width: 4px;
    height: 40px;
    position: absolute;
    left: 0;
    top: 0;
}

%solid-button {
    color: #fff;
    box-sizing: border-box;
    line-height: 20px;
    cursor: pointer;
    &:disabled {
        cursor: initial;
    }
    &:hover {
        filter: brightness(1.12);
        transition: 0.1s;
    }
}

%clear-button {
    border: solid 1px $d7teal;
    background: #fff;
    color: $d7teal;
    box-sizing: border-box;
    line-height: 20px;
    cursor: pointer;
    opacity: 0.8;
    transition: 0.075s;
    &:hover {
        opacity: 1;
        transition: 0.075s;
    }
}

%drop-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

%scroll-nobar {
    overflow-y: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        width: 0;
    }
}

%sfpro-default {
    font: {
        family: $sfpro;
        size: 13px;
        weight: 400;
    }
    letter-spacing: -0.24px;
}

%roboto15 {
    font: {
        family: $roboto;
        size: 15px;
    }
    letter-spacing: -0.24px;
}

%roboto13 {
    font: {
        family: $roboto;
        size: 13px;
    }
}

%sidebar-selection {
    background-color: var(--drip7-primary-color-05);
    color: $d7teal;
}

%custom-checkmark {
    input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 16px;
        width: 16px;
        z-index: 1;
        &:hover {
            cursor: pointer;
        }
        &:focus {
            & + .checkmark {
                border: 2px solid $d7teal;
            }
        }
        &:checked {
            & + .checkmark {
                background: $d7teal;
                border: solid 1px $d7teal;
                content: url("../../images/shared/checkmark.svg");
            }
            & + .dash {
                background: $d7teal;
                border: solid 1px $d7teal;
                content: url("../../images/shared/check-dash.svg");
            }
            & + .square {
                background: #fff;
                border: solid 2px $d7teal;
                content: "";
                width: 14px;
                height: 14px;
                background: $d7teal;
            }
        }
    }

    .checkmark,
    .dash {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        border: solid 1px $grey;
        border-radius: 4px;
        box-sizing: border-box;
    }

    .checkmark {
        padding: 1px 1px 0 0;
    }

    .dash {
        padding: 0 2px;
    }
}

%attached-drop {
    .dropdown-toggle[aria-expanded="true"] {
        border-radius: 8px 8px 0 0;
        border-bottom: none;
        p {
            transform: translateY(-1px);
        }
    }

    .dropdown {
        button[aria-expanded="true"] {
            border-radius: 8px 8px 0 0;
            border-bottom: none;
            p {
                transform: translateY(-1px);
            }
        }
    }

    .dropdown-menu {
        padding: 4px 0 0px;
        border-radius: 0 0 8px 8px;
        max-height: 200px;
        overflow-y: scroll;
        font: {
            family: $roboto;
            size: 12px;
            weight: 500;
        }
        transform: translate(0, 39px) !important;
    }

    .dropdown-item {
        padding: 4px 16px;
        margin: 0 0 4px;
    }
}
