#tenant-navigation {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;
}

.tenant-navigation-entry {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    border-bottom: 1px solid #eceff1;
    margin-top: 10px;
    width:100%;
    max-width: 1200px;
    justify-content: space-between;

    label {
        margin-right: 16px;
    }

    &__container {
        margin-right: 16px;
        input {
            width: 240px
        }
    }
}
