.admin-card {
    &__container {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        width: 100%;
        margin: 20px;
        height: auto;
        & > * {
            height: auto;
        }
    }
    display: flex;
    flex-flow: row wrap;
    flex: 1 1 0px;
    align-items: flex-start;
    min-width: 275px;
    padding: 18px 16px 16px;
    background: #fff;
    box-sizing: border-box;
    box-shadow: $defaultshadow;
    border-radius: 12px;
    color: $d7navy;

    & > * {
        width: 100%;
    }

    font: {
        family: $roboto;
        weight: 700;
    }

    h2 {
        height: 20px;
        margin: 0;
        font: {
            size: 15px;
            weight: 700;
        }
    }

    h3 {
        height: 20px;
        margin: 0;
        font: {
            size: 15px;
            weight: 700;
        }
    }

    h4 {
        font: {
            size: 13px;
            weight: 700;
        }
    }
    
    p {
        line-height: 1.2;
        font: {
            size: 13px;
            weight: 700;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        gap: 1rem;
        row-gap: 1.5rem;
        list-style: none;
    }

    &__item {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0;
        line-height: 20px;
        gap: 0.4rem;
        font: {
            size: 13px;
        }

        &--status {
            margin: 0 0 12px;
            h4 {
                margin: 0;
            }
            &:last-child {
                margin: 0;
            }
        }
    }

    &__user {
        &-photo img {
            width: 32px !important;
            height: 32px;
            border-radius: 50%;
            background: purple;
            // padding-left: 20px;
        }

        &-name {
            margin: 0 0 0 16px;
            flex-grow: 1;
        }

        &-points {
            margin: 0;
            color: $d7teal;
        }

        &-badges {
            & > * {
                width: 32px;
                height: 32px;
                margin: 0 0 0 -20px;
            }
        }
    }
    &--user-count{
        min-width: 400px;
        max-width: 500px;
    }
    &--leaderboard .admin-card__list{
        padding: 0 16px;
    }
}

.placeholder {
    width: 55%;
    height: 8px;
    background-color: lime;
}
