:root {
    --drip7-primary-color: #41c8ffff;
    --drip7-primary-color-05: #41c8ff0d;
    --drip7-primary-color-075: #41c8ff13;
    --drip7-primary-color-20: #41c8ff31;
    --drip7-primary-color-25: #41c8ff40;
    --drip7-primary-color-50: #41c8ff80;
    --drip7-secondary-color: #ffffffff;
}

$primary: #41c8ff;
$white: #ffff;

$d7teal: var(--drip7-primary-color);
$d7orange: var(--drip7-secondary-color);
$red: #fa5c3c;
$errorred: #f65555;
$d7navy: #1f4760;
$d7periwinkle: #1482e6;
$yellow: #FFD84F;
$green: #2ddf9f;
$darkgreen: #277444;
$d7green: #87dcaa;
$d7ecru: #f3f6d7;
$darkgrey: #607d8b;
$greymedium: #8fa4ad;
$grey: #cfd8dd;
$lightgrey: #eceff1;
$bgblue: #f0f5fa;
$indigo: #4541ff;
$chartgreen: #4ef5b9;
$purple: #8f62fe;
$purpletab: #6230dd;
$magenta: #e42480;
$magentatab: #c01265;
$blue: #62b3fe;
$calendarblue: #a3d3ff;
$bluetab: #569fe1;
$pink: #fd75ef;
$pinktab: #d23cc3;
$navgrey: #cfd8dd;
$lightblue: #bcecff;
$fontgray: #666666;
$gold: #ffdf8b;
$silver: #cfd8dd;
$bronze: #e9ac8b;

$roboto: "Roboto", sans-serif;
$sfpro: "SF Pro Display", "Roboto", sans-serif;

$calblockwidth: 68px;

$defaultshadow: 0px 4px 30px rgba(162, 218, 240, 0.3);
$darkshadow: 0px 4px 40px rgba(141, 198, 222, 0.5);
