.user-menu {
    width: 100%;

    .clear-button {
        @extend %clear-button;
    }

    .solid-button {
        @extend %solid-button;
        background: $d7teal;
        border: solid 1px $d7teal;
    }

    h2,
    h3 {
        color: $d7navy;
        font: {
            family: $roboto;
            weight: 700;
        }
    }

    h2 {
        font-size: 20px;
        padding: 24px 0 0 24px;
    }

    h3 {
        font-size: 15px;
        padding: 18px 0 0 16px;
    }

    .users-list-header {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px 23px 24px;
        border-bottom: solid 1px $lightgrey;

        .header-buttons {
            button {
                width: 176px;
                height: 40px;
                margin: 0 16px 0 0;
                border-radius: 6.93px;
                &:last-of-type {
                    margin: 0;
                }

                font: {
                    family: $sfpro;
                    size: 13px;
                    weight: 500;
                }
            }
        }
    }

    .default-users-selectors-wrap {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px 0 24px;
        margin: 24px 0;

        .selectors {
            button {
                height: 40px;
                margin: 0 16px 0 0;
                border-radius: 6.93px;
                &:last-of-type {
                    margin: 0;
                }
            }

            .drop-button {
                @extend %sfpro-default;
                border: solid 1px $grey;
                padding: 0 12px 0 16px;
                color: $d7navy;
                .drop-triangle {
                    content: url("../../images/shared/drop-triangle.svg");
                    margin: 0 0 0 9px;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            .solid-button {
                padding: 0 26px 0 21px;
                border: solid 1px $d7teal;
                font: {
                    family: $sfpro;
                    size: 13px;
                    weight: 400;
                }
            }
        }

        .reset-filters {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            width: 117px;
            color: $red;
            font: {
                family: $sfpro;
                size: 13px;
                weight: 500;
            }

            p {
                margin: 0;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .selected-users-buttons-wrap {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        padding: 0 16px 0 0;
        margin: 24px 0;

        button {
            width: 200px;
            height: 40px;
            margin: 0 11px 0 0;
            border-radius: 10px;
            &:last-of-type {
                margin: 0;
            }
            font: {
                family: $roboto;
                size: 13px;
                weight: 700;
            }
        }
    }

    .table-container {
        width: 100%;
        box-sizing: border-box;
        padding: 0 8px 32px;
        overflow-x: hidden;

        .users-table {
            width: 100%;
            border-collapse: collapse;

            th {
                width: auto;
                height: 56px;
                white-space: nowrap;
                text-align: left;
                line-height: 20px;
                letter-spacing: -0.24px;
                color: $d7navy;
                opacity: 0.8;
                font: {
                    family: $roboto;
                    weight: 700;
                    size: 13px;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            .select-all-col {
                padding: 0 16px 0 0;
                opacity: 1;
                @extend %custom-checkmark;

                .sel-all {
                    &:checked {
                        & + .checkmark {
                            content: url("../../images/shared/check-dash.svg");
                        }
                    }
                }
            }

            .user-name-col {
                padding: 0 70px 0 0;
            }
            .user-id-col {
                padding: 0 61px 0 0;
            }
            .access-level-col {
                padding: 0 31px 0 0;
            }
            .dept-col {
                padding: 0 74px 0 0;
            }
            .email-col {
                padding: 0 150px 0 0;
            }
            .activity-col {
                padding: 0 59px 0 0;
            }
            .progress-col {
                padding: 0 70px 0 0;
            }

            tr {
                position: relative;
            }

            td {
                height: 64px;
                color: $d7navy;
                border-bottom: solid 1px $lightgrey;
                font: {
                    family: $roboto;
                    size: 13px;
                    weight: 700;
                }
                &:hover {
                    cursor: pointer;
                }
            }

            input[type="checkbox"] {
                width: 16px;
                height: 16px;
            }

            .user-table-select {
                @extend %custom-checkmark;
                padding: 1px 0 0;
            }

            .user-table-photo {
                padding: 0 16px;
                .user-profile-photo {
                    display: block;
                    content: "";
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                }
            }

            .user-table-progress {
                width: 103px;
            }

            .more-button {
                content: url("../../images/shared/more.svg");
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.delete-user {
    display: inline-flex;
    align-items: center;
    color: $red;
    cursor: pointer;
    font: {
        family: $roboto;
        size: 12px;
        weight: 700;
    }
    img {
        margin: 0 8px 0 0;
    }
}
