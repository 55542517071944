.toggle {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 32px;
    &:hover {
        cursor: pointer;
    }
    &:focus-within{
        outline: 2px solid;
    }

    input {
        opacity: 0;
        width: 0;
        height: 0;
        &:checked {
            & + .slider {
                background: $d7teal;
                &:before {
                    transform: translateX(18px);
                }
            }
        }
    }

    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $grey;
        border-radius: 16px;
        -webkit-transition: 0.25s;
        transition: 0.25s;
        &:before {
            position: absolute;
            content: "";
            width: 22px;
            height: 22px;
            top: 5px;
            left: 5px;
            background: #fff;
            -webkit-transition: 0.25s;
            transition: 0.25s;
            border-radius: 50%;
        }
    }
}
