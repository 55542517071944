.up-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    box-shadow: $defaultshadow;
    overflow: hidden;
    font-family: $roboto;

    &--change-avatar {
        width: 568px;
        border-radius: 12px;
        overflow-y: scroll;
        scrollbar-width: none;
        
        .up-modal {
            &__header {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 14px 16px;
                background: var(--drip7-primary-color-05);
                color: $d7navy;
            }

            &__heading {
                margin: 0;
                line-height: 20px;
                font: {
                    size: 18px;
                    weight: 700;
                }
            }

            &__close {
                border-radius: 50%;
                box-sizing: content-box;
                padding: 5px;
                transition: 0.1s;
                &:hover {
                    color: #fff;
                    background: $d7navy;
                    transition: 0.1s;
                    cursor: pointer;
                }
            }

            &__body {
                display: flex;
                flex-flow: row wrap;
                padding: 24px 40px;
            }

            &__user-avatar {
                &-wrapper {
                    width: 136px;
                }

                width: 131px;
                height: 131px;
                background: url("./../../images/shared/avatar.png") center
                    no-repeat;
                background-size: contain;
                border: solid 5px #fff;
                border-radius: 50%;
                box-shadow: $darkshadow;

                &-upload {
                    width: 100%;
                    line-height: 30px;
                    margin: 7px 0 0 0;
                    text-align: center;
                    a {
                        color: $d7teal;
                        font: {
                            size: 17px;
                            weight: 700;
                        }
                        &:hover {
                            color: $d7navy;
                        }
                    }
                }
            }

            &__default-avatars {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-flow: row wrap;
                width: 272px;
                margin: 0 0 0 42px;
            }

            &__default-avatar {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: 0.2s;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                &-wrapper {
                    width: 80px;
                    height: 80px;
                    margin: 0 0 16px;
                    position: relative;
                    background: var(--drip7-primary-color-20);
                    border-radius: 50%;
                    overflow: hidden;
                    transition: 0.2s;
                    &:hover {
                        cursor: pointer;
                        background: var(--drip7-primary-color-50);
                        transition: 0.2s;
                        .up-modal__default-avatar {
                            transform: translate(-50%, -50%) scale(1.08);
                            transition: 0.2s;
                        }
                    }
                }
            }
        }
    }

    &__buttons {
        display: flex;
        flex-flow: row;
        justify-content: center;
        width: 100%;
        margin: 24px 0 0;
    }

    &__button {
        width: 216px;
        height: 48px;
        margin: 0 8px;
        border-radius: 6.93px;
        font: {
            size: 17px;
            weight: 700;
        }

        &--cancel {
            @extend %clear-button;
        }

        &--save {
            @extend %solid-button;
            background: $d7teal;
            border: solid 1px $d7teal;
        }

        &:disabled {
            background: $lightgrey;
            border: solid 1px $lightgrey;
            color: $greymedium;
            cursor: initial;
        }
    }
}

@media only screen and (max-width: 767px) {
    .up-modal {
        width: 80%;
        z-index: 45;
        
        &__body {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            width: 100%;
            padding: 16px 51px 36px;
        }

        &__user-avatar {
            
            &-wrapper {
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                width: 100%;
                margin: auto;
            }

            width: 160px;
            height: 160px;
            background: url("../../images/shared/avatar.png") center no-repeat;
            background-size: contain;
            border: solid 5px #fff;
            border-radius: 50%;
            box-shadow: $darkshadow;

            &-upload {
                width: 100%;
                line-height: 30px;
                margin: 7px 0 0 0;
                text-align: center;
                a {
                    color: $d7teal;
                    font: {
                        size: 17px;
                        weight: 700;
                    }
                    &:hover {
                        color: $d7navy;
                    }
                }
            }
        }

        &__default-avatars {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-flow: row wrap;
            width: 100%;
            max-width: 272px;
            margin: 46px 0 36px !important;
            gap: 16px;
        }

        &__default-avatar {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: 0.2s;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            &-wrapper {
                width: 80px;
                height: 80px;
                margin: 0;
                position: relative;
                background: var(--drip7-primary-color-20);
                border-radius: 50%;
                overflow: hidden;
                transition: 0.2s;
                &:hover {
                    cursor: pointer;
                    background: var(--drip7-primary-color-50);
                    transition: 0.2s;
                    .up-modal__default-avatar {
                        transform: translate(-50%, -50%) scale(1.08);
                        transition: 0.2s;
                    }
                }
            }
        }

        &__buttons {
            margin: 0;
        }
    }
}
