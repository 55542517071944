.badge-status {
    min-height: 350px;
    &__heading {
        width: 100%;
        text-align: left;
        h1 {
            margin: 35px 0 17px;
        }
    }

    padding: 16px 24px;
    margin-bottom: 2rem;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    &__loader {
        margin: 16px 0 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 100%;
        height: max-content;
    }

    &__group {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 100%;
        height: max-content;

        &-name {
            width: 341px;
            height: 24px;
            margin: 0 0 40px;
            text-align: center;
            text-transform: uppercase;
            color: $greymedium;
            font: {
                size: 18px;
            }

            .container-symbol {
                margin: 0 0 0 16px;
                height: 20px;
                color: $d7teal;
                transition: 0.2s;
                transform: rotate(180deg);
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
    &__badge-group-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 100%;
        margin: 0 0 24px;
        margin: auto;

        .current-topics {
            &__topic {
                box-shadow: none;
                background: none;
            }

            &__continue {
                display: none;
            }
        }
    }

    &__badge {
        &-wrapper {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            width: 120px;
            height: 144px;
            padding: 5px 0;
            margin: 0 4px 16px;
            box-sizing: border-box;
            color: $d7navy;
        }

        &-progress {
            width: 72px;
            height: 72px;
            border-radius: 50%;
            background: #fff;
            border: solid 8px $lightgrey;
            position: relative;

            &-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 10;
                background: #fff;
            }
        }

        &-name {
            width: 100%;
            height: 44px;
            margin: 6px 0 0;
            line-height: 20px;
            text-align: center;
        }
    }

    &__progress-meter {
        width: 100%;
        height: 100%;
        position: relative;
    }
}

.badge-table {
    width: 100%;
    border-collapse: collapse;
    overflow: visible;
    z-index: 5;

    &__badge {
        height: 95px;
        width: 100%;
        padding: 4px 0;
        border-bottom: 1px solid $lightgrey;
        cursor: pointer;
        position: relative;
        color: $d7navy;

        &:hover {
            box-shadow: $defaultshadow;
            border: none;
        }

        &:last-of-type {
            border: none;
        }
    }

    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 95px;
        width: 95px;
    }
        
    &__icon-wrapper {
        background-color: $lightgrey;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 81px;
        height: 81px;
        border-radius: 68px;
        overflow: hidden;
        // box-shadow: 0px 4px 40px rgba(141, 198, 222, 0.5);
        position: relative;
    }

    &__progress-meter {
        width: 100%;
        height: 100%;
        position: relative;

        .radial-progress__half-circle-cover {
            background: $lightgrey;
        }
    }

    &__icon {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 50%;
        top: 50%;
        height: 69px;
        width: 69px;
        transform: translate(-50%, -50%);
        background: #fff;
        border-radius: 50%;
        z-index: 52;
        overflow: hidden;
        
        img {
            // height: 44px;
            // width: 44px;
            height: 60px;
            width: 60px;
            border-radius: 50%;
            object-fit: scale-down;
        }
    }

    &__name {
        &-percent {
            color: $darkgrey;
            font-size: 14px !important;
        }
    }

    &__points {
        text-align: center;
        color: $darkgrey;
        &-text{
            font-size: 14px !important;
        }
    }

    &__message {
        color: $fontgray;
        overflow: wrap;
        width: 400px;
        p {
            margin: 0 !important;
        }
        font: {
            family: $roboto;
            size: 13px;
            weight: 300;
        }
    }

}

@media only screen and (max-width: 767px) {
    .badge-status {
        padding: 32px 0;
        &__loader {
            svg {
                width: 120px;
            }
        }

        .badge-table {
            &__badge {
                border: none;
            }

            &__message {
                display: none;
            }

            &__owned-text {
                font: $sfpro
            }
        }
        &__badge-group-container {
            padding: 24px 0 36px 0;
            gap: 8px;
            .current-topics__topic {
                align-content: flex-start;
                width: 120px;
                // height: 160px;
                height: auto;
                .d7badge {
                    width: 80px;
                    height: 80px;

                    &__icon {
                        left: 50%;
                        top: 50%;
                        width: 64px;
                        height: 64px;
                        img {
                            width: 60px;
                            height: 60px;
                        }
                    }
                }
            }
        }
        &__group-name {
            margin: 0 0 8px;
        }
    }
}
