.userdash {
    &-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        /*background-color: rgb(240, 245, 250);*/
        justify-content: center;
        padding-bottom: 50px;
    }
    &-component {
        margin-top: 20px;
        border-radius: 33px;
        text-align: center;
        max-width: 600px;
    }
}

.centered-div {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
}

.shadowed {
    box-shadow: 10px 20px 25px rgba(0, 0, 0, 0.15);
}

.training {
    &-section {
        color: #fff;
        background: linear-gradient(
            to bottom,
            rgb(0, 193, 255),
            rgb(0, 124, 240)
        );
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        height: 60px;
        margin: 25px auto 0px;
        border-radius: 50px;
        padding: 20px;
        text-align: start;
        min-width: 250px;
    }

    &-button {
        margin-right: -10px;
        &-icon {
            width: 40px;
            height: 40px;
            background-color: #fff;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &-summary {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    &-time {
        padding-right: 20px;
        /*font-weight: 100 !important;*/
        font-size: 13px !important;
    }
    &-question {
        /*font-weight: 100 !important;*/
        font-size: 13px !important;
    }
    &-content {
        padding-left: 10px;
    }
    &-img {
        padding-right: 5px;
        font-weight: 100 !important;
    }
    &-title {
        font-size: 1.1em !important;
        font-weight: 500 !important;
        letter-spacing: 0.04em;
    }
}

.avatar-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    height: 70px;
    border-radius: 50px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin-top: 20px;
    z-index: 3;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50px;
    max-width: 350px;
    &-img {
        margin: 4px;
        border-radius: 50%;
        max-width: 60px;
    }
    &-container {
        position: relative;
    }
    &-tier {
        position: absolute;
        z-index: 1;
        width: 32px;
        height: 32px;
        bottom: 0px;
        right: 0px;
        &-img {
            position: absolute;
            top: 3px;
            right: 3px;
            width: 32px;
            height: auto;
            z-index: 1;
        }
        &-number {
            position: absolute;
            font-size: 1em;
            top: 5px;
            left: 8px;
            z-index: 2;
            color: white;
        }
    }
    &-info {
        display: flex;
        flex-direction: column;
        margin-left: 13px;
        margin-right: 20px;
        &-name {
            font-weight: 100 !important;
            color: grey;
            font-size: 1em !important;
            line-height: normal !important;
            margin-bottom: 0 !important;
        }
        &-tenant {
            font-weight: 600 !important;
            font-size: 1em !important;
            line-height: normal !important;
            margin-bottom: 0 !important;
            color: rgb(19, 70, 99);
        }
    }
}


.first-slider-card {
    margin-left: 10vw !important;
}
.last-slider-card {
    margin-right: 10vw !important;
}

.slider {
    display: flex;
    flex-direction: row;
    transition: transform 0.3s ease-in-out;
    &--card {
        height: 46px;
        width: 175px;
        display: flex;
        font-size: 18px;
        overflow: hidden;
        flex-direction: column;
        margin: 0 2px;
        &-top {
            height: 12px;
            width: 100%;
            position: relative;
            display: block;
        }
        &-info {
            margin-top: 2px;
            margin-left: 5px;
            font-weight: 300;
            display: flex;
            flex-direction: column;

            &-date {
                margin-top: 2px;
                font-weight: 200;
                font-size: 0.65em;
                line-height: normal;
                color: gray;
            }
            &-curric {
                white-space: nowrap; 
                overflow: hidden;
                text-overflow: ellipsis;

                font-weight: 600;
                font-size: 0.7em;
                line-height: normal;
                color: rgb(19, 70, 99);
            }
        }
        & img {
            max-width: 15px;
            position: absolute;
            z-index: 1;
        }
    }
    &--container {
        padding-top: 10px;
        width: 100%;
        overflow: hidden;
    }
}

.slider--container {
    margin-top: 4px;
    display: flex;
    justify-content: center;
    & .splide {
        max-width: 100vw;
    }
}

.slider--container .splide .splide__slide {
    flex-shrink: 1;
    margin-right: 0 !important;
}

.prev-content--container .splide__slide {
    width: 80vw !important;
    /*margin-right: 5vw;*/
    max-width: 600px;
}

.tier {
    &-chart {
        &-indicator {
            position: absolute;
            height: 256px;
            width: 9px;
            background-color: white;
            z-index: 5;
            // border: 20px solid rgba(155, 195, 155, 0.5);
            border-radius: 4px;
            margin-left: 12%;
            border: 3px solid rgba(91, 91, 91, 0.5);
            -webkit-background-clip: padding-box; /* for Safari */
            background-clip: padding-box;
            margin-top: 80px;
            margin-bottom: 10px;
        }
        &-container {
            position: relative;
            display: flex;
            align-items: center;
            margin-top: -30px;
        }
        &-inner {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &-background {
            width: 100%;
            height: 65%;
            position: absolute;
        }
        &-company {
            color: white;
            display: flex;
            margin: auto;
        }
    }
    &-container {
        padding-top: 20px;
        // height: 210px;
        max-width: 100vw;
        & button {
            width: 50%;
            max-width: 400px;
            font-weight: 100;
        }
        & .dropdown {
            display: flex;
            justify-content: center;
        }
    }
    &-background {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    &-title {
        color: white !important;
        text-align: center !important;
    }
    &-img {
        width: 35px;
        z-index: 1;
    }
    &-divider {
        width: 100%;
        border-bottom: 1px solid lightgrey;
        margin: auto;
    }
    &-scroll {
        background-color: rgba(240, 245, 250, 1);
        position: absolute;
        left: 0;
        top: 136px;
        bottom: 0;
        right: 0;
    }
    &-card {
        margin-top: 23px !important;
        margin-bottom: 50px !important;
        z-index: 3;
        &-title {
            color: rgb(19, 70, 99);
            font-size: 16px !important;
        }
        &-col {
            color: rgb(19, 70, 99);
            font-size: 16px !important;
            display: flex;
            flex-direction: column;
        }
    }
    &-arrow {
        height: 20px;
        width: 20px;
        margin-right: 5px;
    }
    &-top-card {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    &-card-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    &-sep {
        border-top: 1px solid lightgray;
    }
    &-category {
        display: flex;
        flex-direction: column;
        text-align: start;
        &-date {
            font-weight: 100 !important;
            font-size: 0.9em !important;
            color: grey;
        }
        &-rank {
            font-weight: 600 !important;
            color: rgb(19, 70, 99);
        }
        &-marker {
            position: relative;
            margin-top: 12px;
        }
    }&-number{
        position: absolute;
        font-size: 1.1em;
        top: 2px;
        left: 12px;
        z-index: 2;
        color: white;
    }
}
#tier_chart {
    margin-right: 10px;
}
.card {
    display: flex;
    justify-content: center;
    max-width: 100%;
    position: relative;
    overflow: inherit;
    padding: 1.4rem 1.2rem;
    margin: 5px 10vw;

    &-divider {
        width: 20px;
        border-bottom: 2px solid gray;
    }
    &-title {
        text-transform: capitalize;
        font-weight: 100 !important;
        letter-spacing: 4px;
        color: gray;
        font-size: 0.9em !important;
    }
    &-slide {
        display: block;
        margin-top: 20px;
        margin-right: 0vw;
        margin-left: 0vw;
        padding-left: 30px;
        padding-right: 30px;
        max-width: 600px;
    }
    &-question {
        color: rgb(19, 70, 99);
        font-size: 1.2em !important;
        margin: 0 !important;
    }
    &-answer {
        padding: 5px 30px;
        margin-top: 20px;
        border-radius: 25px;
        /* font-weight: 100 !important; */
        &-div {
            width: -moz-fit-content;
            width: fit-content;
            margin: auto;
            padding-bottom: 20px;
        }
        &-true {
            background-color: rgb(0, 229, 156);
            color: white;
        }
        &-false {
            background-color: rgb(255, 75, 17);
            color: white;
        }
    }
    &-results {
        display: flex;
        flex-direction: row;
        justify-content: center;
        &-container {
            display: flex;
            justify-content: center;
            padding: 0 10px;
        }
    }
    &-grade {
        padding-right: 40px;
    }
    &-point {
        /* font-weight: 100 !important; */
        color: rgb(19, 70, 99);
        padding-left: 2px;
    }
}

.weekly-schedule {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: auto;
    height: 50px;
    margin: 20px 8%;

    &-day {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.day-letter {
    font-weight: 100;
    font-size: 0.8em;
    color: grey;
    &-skipped {
        color: lightgrey;
        font-weight: 100;
        font-size: 0.8em;
    }
    &-current {
        color: rgb(0, 193, 255);
        font-weight: 600;
        font-size: 1.0em;
    }
}

.center-divider {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 15px;
}

.question {
    &-summary {
        margin-bottom: 10px !important;
        color: rgb(19, 70, 99);
    }
}
.lesson-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.lesson-block {
    width: 45%;
}

.prev-content--container .splide__track {
    overflow: visible !important;
}

.prev-content--container-desktop .splide__track {
    overflow: hidden;
}

.desktop-card {
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
    border-radius: 33px;
}

.mobile {
    &-collapse {
        display: flex;
        flex-direction: column;
    }
}

.userdash-container .avatar-card {
    margin-left: 8%;
    margin-right: 8%;
}

.next-lesson-card{
    margin-top:15px
}
@media (min-width: 768px) {
    .tier {
        &-card {
            &-col {
                justify-content: center;
            }
            &-container {
                width: 20vw;
                max-width: 400px;
            }
        }
        &-divider {
            margin-top: 0;
            margin-bottom: 0;
        }
        &-title {
            font-size: 2em;
            margin-top: .2em;
        }
        &-category {
            &-marker {
                margin-left: 10px;
            }
        }&-top{
            display: flex;
            align-items: flex-start;
        }
    }

    .slider--container {
        & .splide {
            max-width: 80vw;
        }
    }
    .prev-content--container-desktop {
        display: block;
    }
    .prev-content--container {
        display: none;
    }
    .next-lesson-card {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
    .lesson-block {
        width: 45%;
    }
    .lesson-block:first-child {
        display: block;
    }
    .weekly-schedule {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
    .first-slider-card {
        margin-left: 0 !important;
    }
    .last-slider-card {
        margin-right: 0 !important;
    }
    .slider--container {
        overflow: visible;
        margin-top: 40px;
        order: 1;
    }

    .slider--container .splide__track {
        overflow: visible;
    }
    .lesson-container {
        flex-direction: row-reverse;
        margin-top: 20px;
    }

    .tier-title {
        color: black !important;
        line-height: 20px;
        font-size: 28px;
        font-weight: 700;
    }
    .mobile {
        &-collapse {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
    }
    .userdash-container .avatar-card {
        margin-left: auto;
        margin-right: auto;
    }
    .weekly-schedule:nth-of-type(1) {
        display: flex;
        height: 25%;
    }
    .weekly-schedule:nth-of-type(2) {
        display: none;
    }

    .tier{
        &-top{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        }&-card{
            width:75vw;
        }&-background .avatar-card{
            margin-top: 0;
        }
    }
    .next-lesson-card{
        margin-top:5px
    }
}
@media (max-width: 768px) {
    .prev-content--container-desktop {
        display: none;
    }
    .prev-content--container {
        display: flex;
        justify-content: center;
    }
    .lesson-block {
        width: initial;
    }
    .lesson-block:first-child {
        display: none;
    }
}
