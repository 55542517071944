.basic-modal {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 672px;
    max-height: 650px;
    padding-bottom: 16px;
    border-radius: 12px;
    background: #fff;
    box-shadow: $defaultshadow;
    @extend %scroll-nobar;

    &--create-curriculum {
        height: 800px;
        width: 850px;
        overflow-y: scroll;
        @extend %scroll-nobar;

        .basic-modal {
            &__button-container {
                width: 100%;
                padding: 0 16px;
            }

            &__slot_header {
                display: inline-flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
            }

            &__slot_container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 90%;
            }

            &__slot_component {
                margin: 12px;
            }

            &__slot_checkbox_container {
                margin: 12px 24px;
            }

            &__config {
                padding: 0;

                .central__filters {
                    display: flex;
                    flex-flow: row;
                    justify-content: space-between;
                    position: initial;
                    margin: 0 0 8px;
                }

                .central_block__filters {
                    width: 100%;
                    display: flex;
                    flex-flow: row wrap;
                    position: initial;
                    margin: 0 0 8px;
                }

                .buttons {
                    display: flex;
                    flex-flow: row;
                    align-content: center;
                    justify-content: flex-start;
                    align-items: center;
                }

                .buttons__centered {
                    width: 100%;
                    margin: 8px;
                }

                .progress-bar_container {
                    width: 100%;
                    display: inline-flex;
                    flex-direction: column;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                }

                .basic-modal__header {
                    margin: 16px 0;
                    color: $d7navy;
                    font: {
                        size: 20px;
                        weight: 700;
                    }
                }

                .slot__add {
                    display: inline-flex;
                    flex-direction: column;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                }

                .slot__wrapper {
                    @extend %scroll-nobar;
                    height: 180px;
                    width: 180px;
                    border: 1px solid $lightblue;
                    padding: 4px 8px;
                    border-radius: 6.93px;
                    margin: 8px;
                }

                .table__wrapper {
                    @extend %scroll-nobar;
                    width: 100%;
                    height: 255px;
                    background: $lightgrey;
                    padding: 4px 8px;
                    border-radius: 6.93px;
                    margin-bottom: 16px;

                    .questions__table {
                        width: calc(100% - 12px);
                        &-row {
                            height: 48px;
                            &--head {
                                position: sticky;
                                top: -6px;
                                background: $lightgrey;
                                height: 30px;
                                padding: 8px 0;
                                & > * {
                                    min-width: unset;
                                    width: fit-content;
                                }
                            }
                        }

                        &-head {
                            &--name {
                                .questions__table-heading {
                                    margin: 0;
                                }
                            }
                        }

                        &-data {
                            height: 48px;

                            &--name {
                                position: initial;
                            }
                        }

                        &-text {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    &--add-category {
        height: 240px;
    }

    &--add-question {
        flex-flow: column;
        justify-content: flex-start;
        align-content: center;

        .basic-modal__button-container {
            margin-top: 10px;
        }
    }

    &--add-badge {
        flex-flow: column;
        justify-content: flex-start;
        align-content: center;
    }

    &--wide-modal {
        width: 1024px;
    }

    &__head {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 66px;
        padding: 24px 24px 20px;
        color: $d7navy;
        background: #fff;
        /*z-index: 99;*/
        position: sticky;
        top: 0;
        &--config {
            height: 40px;
            padding: 0;
            margin: 0 0 14px;
        }
        h4 {
            margin: 0;
            font: {
                family: $roboto;
                size: 15px;
                weight: 700;
            }
        }
        h2 {
            margin: 0;
            padding: 0px;
            font: {
                size: 15px;
            }
        }
    }
    &__close-button {
        box-sizing: content-box;
        padding: 3px;
        border-radius: 50%;
        transition: 0.1s;
        &:hover {
            background: $d7navy;
            color: #fff;
            cursor: pointer;
            transition: 0.1s;
        }
    }
    &__field {
        display: flex;
        flex-flow: row wrap;
        width: 304px;
        flex-direction: column;
        &-container {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            padding: 0 24px;

            &--mt-10 {
                margin: 10px 0 0;
            }
            
            &--add-category {
                margin: 10px 0 8px;
            }
            &--add-question {
                margin: 10px 0 0;
                overflow-y: scroll;
                @extend %scroll-nobar;
            }
            &--add-badge {
                margin: 10px 0 0;
                overflow-y: scroll;
                .basic-modal__add-image-container {
                    margin: 12px 0 0;
                }
            }
            &--add-phish {
                display: flex;
                justify-content: flex-start;
                column-gap: 24px;
            }
        }
        &--config {
            width: 280px;
            .dropdown-toggle {
                height: 40px;
            }
        }
        &--add-category {
            width: 624px;
        }
        &--add-question {
            margin: 0 0 14px;
            .basic-modal__input {
                // margin: 6px 0 0;
            }
            .dropdown-toggle {
                margin: 6px 0 0;
                height: 40px;
            }
        }
    }

    &__label {
        height: 20px;
        color: $d7navy;
        font: {
            family: $roboto;
            size: 12px;
            weight: 700;
        }
    }

    &__code {
        height: 20px;
        color: $d7navy;
        font: {
            family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;;
            size: 12px;
            weight: 500;
        }
    }

    &__info {
        height: 20px;
        color: $d7navy;
        font: {
            family: $roboto;
            size: 14px;
            weight: 500;
        }
    }

    &__input {
        width: 304px;
        height: 40px;
        padding: 12px 8px;
        margin: 6px 0;
        line-height: 16px;
        border: solid 1px $grey;
        border-radius: 6.93px;
        font: {
            family: $sfpro;
            size: 1rem;
            weight: 500;
        }
        &--add-category {
            width: 624px;
        }
        &--number {
            height: 32px;
            padding: 0;
            border: none;
        }
        &--add-question {
            width: 608px;
            height: 80px;
            resize: none;
        }

        &--add-answer {
            width: 608px;
            height: 38px;
            border: none;
            margin: 0;
            padding: 12px 0;
        }

        &--phish {
            width: 608px;
        }

        &--textarea {
            height: 80px;
            resize: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }

        &-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 280px;
            height: 40px;
            padding: 12px 8px;
            border: solid 1px $grey;
            border-radius: 6.93px;
            background: #fff;
            &--add-answer {
                width: 608px;
                margin: 0 0 8px;
                &:last-child {
                    margin: 0;
                }
            }
            &--boolean {
                .basic-modal__answer-checkmark {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            &:focus-within {
                outline: none;
                border-color: var(--drip7-primary-color);
            }
        }
        &::placeholder {
            color: $greymedium;
        }
        &:focus {
            outline: none;
            border-color: var(--drip7-primary-color);
        }
    }

    &__question-collection {
        margin: 10px 0;
        
        .basic-modal__field--config {
            width: 304px;
        }
    }

    &__wrapper {
        display: flex;

        &--column {
            justify-content: space-between;
            flex-flow: column;
            
            .basic-modal__language-selection {
                padding: 0;
                flex-flow: column;

                .dropdown-menu {
                    max-height: 230px;
                    overflow-y: scroll;
                    box-shadow: $darkshadow;
                }
            }

            .basic-modal__field {
                & > * {
                    width: 100%;
                }
            }
        }

        &--add-badge {
            flex-flow: row;
            justify-content: space-between;
            margin: 6px 0;

            input[type="text"] {
                flex-grow: 2;
                width: unset;
                margin-top: 10px;
            }

            button {
                flex-grow: 1;
                width: unset;
                margin: 10px 0 0 8px;
            }
        }
    }

    &__config {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        width: 100%;
        padding: 24px;
        margin: 18px 0 0;

        .questions__table {
            width: 100%;
        }
    }

    &__button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 176px;
        height: 40px;
        border-radius: 6.93px;
        line-height: 13px;
        font: {
            family: $sfpro;
            size: 13px;
            weight: 400;
        }
        &-container {
            display: flex;
            justify-content: flex-end;
            width: 640px;
            margin: 24px 0 0;
            background: #fff;
            & > button {
                margin: 0 16px 0 0;
                &:last-child {
                    margin: 0;
                }
            }

            &--save {
                display: flex;
                justify-content: flex-end;
                width: 640px;
                & > button {
                    margin: 0 16px 0 0;
                    &:last-child {
                        margin: 0;
                    }
                } 

                &-bottom {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    & > button {
                        margin: 16px 8px 0;
                        &:last-child {
                            margin-right: 24px;
                        } 
                    }
                }
            }
            &-centered {
                display: flex;
                justify-content: center;
                width: 100%;
                & > button {
                    margin: 16px 8px 0;
                }
            }
            &-around {
                display: flex;
                justify-content: space-around;
                width: 100%;
                & > button {
                    margin: 16px 8px 0;
                }
            }
        }
        &--solid {
            @extend %solid-button;
            background: $d7teal;
            background-color: $d7teal;
            border: solid 1px $d7teal;
            &.selected {
                background: linear-gradient(180deg, var(--drip7-primary-color) 0%, #1482E6 100%);
                &:hover {
                        background: linear-gradient(180deg, var(--drip7-primary-color) 0%, #1482E6 0%);
                }
            }
        }

        &--clear {
            @extend %clear-button;
        }
        &--random,
        &--add {
            width: 152px;
            padding: 4px 8px;
            svg {
                margin: 0 10px 0 0;
            }
        }
        &--delete {
            display: inline-flex;
            align-items: center;
            white-space: nowrap;
            width: 142px;
            padding: 8px 12px;
            background: none;
            border: none;
            color: $red;
            font: {
                family: $roboto;
                size: 13px;
                weight: 700;
            }
            svg {
                margin: 0 8px 0 0;
            }
        }
        &--browse {
            width: 168px;
        }
        &--upload {
            width: 120px;
        }
        &--browse {
            width: unset;
        }
    }

    &__language {
        &-selection {
            display: flex;
            flex-flow: row wrap;
            width: 100%;
            margin: 0 0 12px;
        }
        &-container {
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            margin: 2px 0 10px;
        }
        width: 64px;
        height: 38px;
        margin: 0 8px 0 0;
        position: relative;
        text-align: center;
        color: $d7navy;
        h4 {
            margin: 14px 0 0;
            letter-spacing: 0.37px;
            font: {
                family: $sfpro;
                size: 16px;
                weight: 600;
            }
        }
        &:hover {
            cursor: pointer;
        }
        &--selected {
            &::after {
                display: block;
                content: "";
                width: 64px;
                height: 8px;
                position: absolute;
                top: 0;
                background: $d7teal;
                border-radius: 8px 8px 0 0;
            }
        }
        &-button {
            &-container {
                display: inline-flex;
                justify-content: space-between;
                width: 100%;
                padding: 0 8px;
            }
        }
    }

    &__add-language {
        @extend %solid-button;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        margin: 0 0 0 2px;
        background: $d7teal;
        border: solid 1px $d7teal;
        border-radius: 6.93px;
    }

    &__add-question {
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        justify-content: center;
        width: 100%;
        margin: 0 0 8px;
        @extend %scroll-nobar;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__upload-label {
        width: 100%;
        margin: 8px 0 23px;
        line-height: 20px;
        text-align: center;
        font: {
            family: $sfpro;
            size: 12px;
            weight: 500;
        }
        span {
            color: $greymedium;
            opacity: 0.5;
        }
        &--border {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 272px;
            height: 12px;
            margin: 16px 0;
            text-align: center;
            position: relative;
            &::before,
            &::after {
                display: block;
                content: "";
                width: 120px;
                height: 1px;
                position: absolute;
                background: $grey;
            }
            &::before {
                left: -9px;
            }
            &::after {
                right: -9px;
            }
        }
    }

    .video-input-wrap {
        display: flex;
        flex-direction: column;
        width: 100%;

        .video-input{
            width: 100%;
            display: flex;
            justify-content: space-between;

            & > input {
                flex-grow: 2;
                margin-top: 6px;
            }

            & > button {
                margin-top: 6px;
            }
        }
    }

    &__add-image {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 100%;
        margin: 14px 0 43px;

        &-container {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            width: 100%;
            padding: 10px 0px;

            & > p {
                margin: 23px 0 8px;
            }

            img {
                width: 150px;
                height: 150px;
                object-fit: scale-down;
            }
        }

        &-icon {
            padding: 7px 0;
            box-sizing: content-box;
        }

        &-button {
            @extend %solid-button;
            width: 168px;
            height: 40px;
            margin: 0;
            background: $d7teal;
            border: solid 1px $d7teal;
            border-radius: 6.93px;
            font: {
                family: $sfpro;
                size: 13px;
                weight: 500;
            }
        }
    }

    &__add-video {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 100%;
        &-container {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            padding: 0 24px;
            margin: 0 0 14px;
        
        & > p {
            margin: 23px 0 8px;
        }
        }
    }

    &__add-answer {
        &-container {
            display: flex;
            flex-flow: row wrap;
            /*max-height: 184px;*/
            margin: 10px 0 0;
        }
    }

    &__answer-checkmark {
        width: 32px;
        height: 32px;
        padding: 6px 8px;
        background: $greymedium;
        border-radius: 6.93px;
        transition: 0.1s;
        margin-right: 10px;
        cursor: pointer;
        &--correct-answer {
            background: $d7green;
        }
        &:hover {
            background: $purple;
            transition: 0.1s;
        }
    }

    &__video-upload {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        color: $d7navy;
        .basic-modal__upload-label {
            margin: 0 0 8px;
        }
        &-url {
            width: 208px;
            padding: 11px 8px 13px;
            margin: 0 8px 0 0;
            line-height: 16px;
            border: solid 1px $grey;
            border-radius: 6.93px;
            font: {
                family: $sfpro;
                size: 12px;
                weight: 500;
            }
            &:focus {
                outline: none;
            }
        }
    }

    &__delete {
        cursor: pointer;
        transition: 0.1s;
        &:hover {
            color: $red;
            transition: 0.1s;
        }
    }

    .fields {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: 0 24px 0;
        align-items: baseline;
        column-gap: 16px;
    }

    .column {
        flex-flow: column;
    }


    .dropdown-toggle {
        &::after {
            display: inline-flex;
            content: url('../../images/shared/drop-triangle-white.svg');
            border: none;
        }
    }

}

.number-button {
    &__container {
        display: flex;
        flex-flow: column;
    }
    &--up {
        transform: translateX(0.5px) rotate(180deg);
        margin: 0 0 1px;
    }
    &:hover {
        cursor: pointer;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.header__wrapper {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
}

.customization-checkbox {
    display: flex;
    position: relative;
    align-items: center;

    &:hover .tooltip__text {
        visibility: visible;
        transition: .2s;
        opacity: 1;
    }

    label {
        margin: 0 0 0 24px;
        color: $d7navy;

        font: {
            family: $roboto;
            size: 12px;
            weight: 700;
        }
    }

    &__badge {
        margin-top: 16px;
        margin-left: 16px;
    }

    .tooltip {
        &__text {
            filter: drop-shadow(0px 4px 10px var(--drip7-primary-color-50));
            visibility: hidden;
            color: #fff;
            background-color: $d7teal;
            text-align: center;
            white-space: nowrap;
            padding: 5px 10px;
            border-radius: 6px;
            position: absolute;
            bottom: 120%;
            opacity: 0;
            z-index: 1;

            &::after {
                content: " ";
                position: absolute;
                top: 100%; 
                left: 15%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: $d7teal transparent transparent transparent;
            }
        }
    }

    @extend %custom-checkmark;
}

.badge-img {
    width: 150px;
    height: 150px;
    object-fit: scale-down;
}

.delivery-top {
    display:flex;
    justify-content:center;
    padding-top:30px;
}
.delivery-bottom {
    display:flex;
    justify-content:center;
    padding-bottom:30px;
}

.tool-tip {
    &__container {
    position: relative;    

    &:hover .tool-tip__text {
        visibility: visible;
        transition: visibility 0s linear .5s;
    }
  }
    &__text {
        filter: drop-shadow(0px 4px 10px var(--drip7-primary-color-50));
        visibility: hidden;
        color: #fff;
        background-color: $d7teal;
        text-align: center;
        word-break: normal;
        padding: 5px 10px;
        border-radius: 6px;
        border: 1px solid $lightgrey;
        position: absolute;
        bottom: 115%;
        z-index: 10;
        width: 275px;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-weight: 700;
        &::after {
            content: " ";
            position: absolute;
            top: 100%; 
            left: 10%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: $d7teal transparent transparent transparent;
        }
        &__right {
            right:50%;
            &::after {
                left:80%;
                right: 20%;
            }
        }
        &-mini{
            width:130px;
            bottom: 70%;
        }
    }
}
.rmdp-input{
    margin:6px 0 0 0 !important;
    height: 40px !important;
    padding: 12px 8px !important;
}
.mt10{
    margin-top:10px;
}
.user-trash-icon{
    margin-right: 26px;
}

.basic-button__button--remove_left {
    border: solid 1px #fa5c3c;
    border-radius: 7px;
    margin-right: auto !important;
}

.queue-modal {
    width: 85%;
    max-width: 1024px;
    padding-bottom: 0;
    max-height: 700px !important;
    font-family: $roboto;

    .basic-modal__button-container {
        width: 100%;
        padding: 12px 24px;
        justify-content: space-between;
        border-top: solid 1px rgba(31, 71, 96, 0.13);
        margin-top: 0;
        position: sticky;
        bottom: 0;
    }

    .basic-modal__head {
        border-bottom: solid 1px rgba(31, 71, 96, 0.13);
    }

    .preview {
        width: 33.33334%;
    }


}

.progress-steps {
    display: inline-flex;
    font-family: "Roboto", sans-serif;
    padding: 18px 0 12px;
    column-gap: 16px;
    h5 {
        font-size: 14px;
        margin: 0;
    }

    &__step {
        display: flex;
        flex-flow: column;
        align-items: center;
        width: 72px;
        flex: 1 1 0px;
        position: relative;
        &::before {
                display: block;
                content: "";
                width: calc(100% + 18px);
                position: absolute;
                top: 5px;
                height: 1px;
                background: rgba(31, 71, 96, 0.13);
                z-index: -1;
            }
        &:first-child {
            padding-left: 0 !important;
            &::before {
                width: calc(50% + 9px);
                right: -9px;
            }
        }
        &:last-child {
            padding-right: 0 !important;
            &::before {
                width: calc(50% + 9px);
                left: -9px;
            }
        }
        &.active {
            h5 {
                color: var(--drip7-primary-color);
                font-weight: bold;
            }
            
            .progress-steps__status {
                border-color: var(--drip7-primary-color);
                background-color: var(--drip7-primary-color);
            }
        }
    }

    &__status {
        display: block;
        content: "";
        border: 1px solid #6B8696;
        background: #fff;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        margin-bottom: 8px;
    }
}

.preview {
    padding: 24px;
}

.curriculums {
    width: 66.66666%;
    border-right: solid 1px rgba(31, 71, 96, 0.13);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 36px;
    padding: 36px 24px;
    height: 615px;
    overflow: scroll;
    @extend %scroll-nobar;
}

@media (max-width: 1200px) {
    .curriculums {
        grid-template-columns: 1fr;
    }
}

.curriculum {
    &-card {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        border-radius: 12px;
        border: 1px solid rgba(31, 71, 96, 0.13);
        position: relative;
        padding: 78px 24px 24px;
        font-family: 'Roboto', sans-serif;
        
    }

    &-icon {
        position: absolute;
        top: -24px;
        background: grey;
        width: 84px;
        height: 84px;
        border-radius: 50%;
    }

    &-desc {
        width: 100%;
        text-align: center;
        color: $d7navy;
        h4 {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 12px;
        }
        p {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 6px;
            &:last-child {
                margin-bottom: 0;
            }
            span {
                font-weight: bold;
            }
        }
    }

    &-badges {
        display: inline-flex;
        margin: 24px 0;
        width: 100%;
        justify-content: center;
        column-gap: 6px;

        & * {
            max-width: 48px;
        }

        /*FOR PLACEHOLDER BADGES*/
        div {
            width: 48px;
            height: 48px;
            background: grey;
            border-radius: 50%;
        }
    }

    a {
        width: 100%;
        text-align: center;
        margin-top: 24px;
        color: var(--drip7-primary-color) !important;
    }

}

.fields-row {
    flex-flow: row wrap;
    &:not(:last-child) {
        margin-bottom: 24px;
    }
}

.queue-settings {
    width: 100%;
    padding: 24px 0 36px;

    .dropdown button {
        height: 40px;
        width: 100%;
    }

    .fields .basic-modal__field {
        width: unset;
    }

    .dropdown--add-question {
        width: unset;
    }

    input {
        margin: unset;
        width: 100%;
    }

    .basic-modal__label {
        margin-bottom: 6px;
    }

    .checkboxes {
        width: calc(100% - 317px) !important;

        & > div {
            padding: 8px 0 15px;
        }
    }

    @media (max-width: 1200px) {
        .checkboxes {
            width: 100% !important;
        }
    }
}

.audience {
    width: 100%;
    padding: 24px;

    .create-group {
        width: unset !important;
        margin: 12px 0 !important;
    }

}
