.up-popup {
    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(207, 216, 221, 0.7);
        z-index: 99;
    }

    .message {
        margin-bottom: 20px;
    }

    width: 312px;
    padding: 0 16px 24px;
    box-sizing: border-box;
    position: relative;
    background: $d7teal;
    text-align: center;
    border-radius: 15px;
    color: #fff;
    overflow-wrap: break-word;
    font: {
        family: $roboto;
    }

    &__head {
        text-align: center;
        margin-bottom: 40px;
        .up-popup__badge-name{
            margin-bottom: 8px;
        }
    }

    &::before {
        content: "";
        position: absolute;
        top: -4px; 
        right: -4px; 
        bottom: -4px; 
        left: -4px; 
        border: 5px solid $d7teal;
        border-radius: 19px;
        z-index: -1;
        opacity: .5;
      }

    &--info {
        .up-popup__icon {
            background: #fff;
            &--shadow {
                color: $d7teal;
                margin: 1.29px 0 0 7.75px;
            }
        }

        p {
            margin: 63px 0 32px;
        }
    }
    &--correct {
        background: $white;
        color: black;

        &::before {
            border-color: $green;
        }

        .up-popup__icon-wrapper--cropped {
            background: $green;
        }

        .up-popup__icon {
            background: #fff;
            color: $green;
            &--shadow {
                margin: 7px 0 0 1px;
            }
        }
    }

    &--incorrect {
        background: $white;
        color: black;

        &::before {
            border-color: $red;
        }
        
        .up-popup__icon-wrapper--cropped {
            background: $red;
        }

        .up-popup__icon {
            background: #fff;
            color: $red;
            &--shadow {
                margin: 5px 0 0 4px;
            }
        }
    }

    &--badge-info {
        padding-top: 40px;
        background: #fff;
        color: $d7teal;
        filter: drop-shadow($defaultshadow);

        &::before {
        }

        .up-popup__badge-stats {
            h2 {
                margin: 8px 0;
            }
        }

        .up-popup__progress {
            width: 112px;
            height: 112px;
            top: -54px
        }

        .up-popup__icon-wrapper {
            width: 122px;
            height: 55px;
            top: -59px;
            &--cropped {
                width: 122px;
                height: 122px;
            }
        }
    }

    &--user-info {
        padding-top: 40px;
        
        .up-popup__badge-name {
            color: white;
        }

        .up-popup__badge-stats {
            h2 {
                margin: 8px 0;
            }
        }

        .up-popup__icon-wrapper {
            width: 122px;
            height: 55px;
            top: -59px;
            &--cropped {
                width: 122px;
                height: 122px;
            }
        }
    }

    &__icon-wrapper {
        width: 82px;
        height: 37px;
        box-sizing: content-box;
        position: absolute;
        top: -41px;
        left: 50%;
        transform: translateX(-50%);
        overflow: hidden;
        &--cropped {
            width: 82px;
            height: 82px;
            top: 0;
            background: $d7teal;
            border-radius: 50%;
            opacity: 0.5;
        }

    }

    &__scrollable-container{
        max-height: 45vh;
        margin: 50px 0 15px 0;
        overflow: scroll;
        &::-webkit-scrollbar{
            width: 0;
            height: 0;
        }
    }
    

    &__icon {
        width: 64px;
        height: 64px;
        box-sizing: content-box;
        position: absolute;
        top: -37px;
        left: 50%;
        transform: translateX(-50%);
        background-size: contain;
        border: solid 5px #fff;
        border-radius: 50%;
        color: $d7teal;
        &--avatar {
            background: url("../../images/shared/avatar.png") center no-repeat;
            background-size: contain;
        }
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &--shadow {
            opacity: 0.25;
        }
    }

    &__greeting {
        margin: 61px 0 32px;
        line-height: 25px;
        letter-spacing: 0.37px;
        font: {
            size: 24px;
            weight: 500;
        }
    }

    &__heading {
        margin: 0 0 24px;
        line-height: 24px;
        letter-spacing: 0.37px;
        font: {
            size: 18px;
            weight: 500;
        }
        &__correct {
            margin: px 0 24px        
        }
    }

    &__p {
        margin: 0 0 32px;
        line-height: 20px;
        letter-spacing: -0.24px;
        white-space: pre-wrap;
        font: {
            size: 15px;
            weight: 500;
        }
        span{
            font-size: 25px;
            
        }
    }

    &__button {
        width: 136px;
        height: 48px;
        letter-spacing: -0.24px;
        border: none;
        border-radius: 6.93px;
        color: $d7teal;
        font: {
            size: 17px;
            weight: 500;
        }
        &--skip,
        &--restart {
            background: #1fb1ec;
            color: #fff;
        }
        &:hover {
            cursor: pointer;
        }
    }

    &__buttons {
        width: 100%;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
    }

    &__progress {
        width: 112px;
        height: 112px;
        box-sizing: content-box;
        position: absolute;
        background: #fff;
        border-radius: 50%;
        top: -54px;
        left: 50%;
        transform: translateX(-50%);
        &-meter {
            width: 88px;
            height: 88px;
            position: relative;
            background: $lightgrey;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            overflow: hidden;
        }

        .up-popup__icon {
            width: 64px;
            height: 64px;
            position: absolute;
            /*background: url("../../images/user-portal/water-shape.svg") bottom no-repeat $d7ecru; */
            background-color: white;
            background-size: contain;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 105;
            object-fit: scale-down;
        }
    }
    &__badge {

        &-name {
            line-height: 20px;
            margin: 77px 0 34px;
            font: {
                size: 24px;
                weight: 500;
            } 
        }

        &-message {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 95%;
            background: $d7teal;
            border-radius: 10px;
            color: #fff;
            padding: 6px 8px;
            margin: 20px auto 0;
            min-height: 40px;
            box-shadow: $defaultshadow;
            font: {
                size: 13px !important;
                weight: 300 !important;
            }
        }

        &-earned {
            color: $d7navy;
        }

        &-stats {
            display: flex;
            flex-flow: row;
            margin: 0 0 30px;
            text-align: center;
            line-height: 20px;
            &-percentage {
                width: 50%;
            }
            &-points {
                width: 50%;
            }
            h2 {
                margin: 0 0 6px;
                line-height: 20px;
                font: {
                    size: 24px;
                    weight: 500;
                }
            }
            h4 {
                margin: 0;
                font: {
                    size: 15px;
                    weight: 500;
                }
            }
        }
    }

    .user-popup {

        &__icon {
            width: 88px;
            height: 88px;
            border-radius: 50%;
            position: absolute;
            background: url("../../images/user-portal/water-shape.svg") bottom
                no-repeat $d7ecru;
            background-size: contain;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 105;
        }

        &__badge {
            width: 100%;
            display: flex;
            flex-flow: row;
            height: 88px;
            overflow-x: scroll;
            background-color: #fff;
            padding: 5px;
            border-radius: 15px;
            border: solid 2px var(--drip7-primary-color-50);

            img {
                border-radius: 50%;
                margin-right: 5px;
                max-width: 74px;
            }
        }

        &__user-trophy {
            background-color: #fff;
            border-radius: 50%;
            width: 38px;
            height: 38px;
            border: 2px solid $d7teal;
            position: absolute;
            left: 55%;
            top: 6%;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
                max-width: 26px;
                max-height: 26px;
            }
        }

        &__badge-stats {
            display: flex;
            flex-flow: column;
            text-align: center;
            line-height: 20px;
            margin-top: 40px;
            &-points {
                width: 100%;
            }
            h4 {
                line-height: 20px;
                margin: 0 0 6px;
                font: {
                    size: 15px;
                    weight: 700;
                }
            }
        }
    }
}
