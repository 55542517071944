@import "helpers/variables";
@import "helpers/extends";
@import "helpers/animations";
@import "components/attached-dropdown";
@import "components/toggle-switches";
@import "components/badge";
@import "components/radial-progress";
@import "components/admin-header";
@import "components/header-mini-profile";
@import "components/admin-sidebar";
@import "components/admin-widgets-settings";
@import "components/admin-user-menu";
@import "components/admin-card";
@import "components/admin-customization";
@import "components/admin-table";
@import "components/categories";
@import "components/admin-users-list";
@import "components/admin-delete-user";
@import "components/admin-edit-user";
@import "components/admin-add-users";
@import "components/admin-action-toast";
@import "components/admin-personal-info-modal";
@import "components/admin-membership-modal";
@import "components/admin-head";
@import "components/admin-curriculum-board";
@import "components/admin-curriculum-timeline";
@import "components/admin-add-curriculum-modal";
@import "components/admin-basic-modal";
@import "components/admin-curriculum-timeline-menus";
@import "components/admin-curriculum-management";
@import "components/admin-questions-categories";
@import "components/_admin-queue-modal";
@import "components/admin-create-group";
@import "components/userportal-header";
@import "components/userportal-popup-prompts";
@import "components/userportal-central-container";
@import "components/userportal-user-progress";
@import "components/userportal-dashboard";
@import "components/userportal-daily-questions";
@import "components/userportal-badge-status";
@import "components/userportal-profile-settings";
@import "components/userportal-leaderboards";
@import "components/userportal-question-archive";
@import "components/userportal-curriculum-list";
@import "components/userportal-modals";
@import "components/searchbar";
@import "components/button-container";
@import "components/user-avatar";
@import "components/input_with_Icon";
@import "components/spinner";
@import "components/games";
@import "layout/login";
@import "layout/register";
@import "layout/tenant";
@import "layout/admin-dashboard";
@import "layout/userportal";
@import "layout/userportal-home";
@import "helpers/shared-classes";
/* @import "helpers/bootstrap_rewrite"; */

button {
    cursor: pointer;
}

button.blue-button,
button.btn {
    transition: 0.4s;
    &:hover {
        filter: brightness(1.12);
        color: $white;
    }
}

#app {
    @media only screen and (max-width: 767px) {
        background-color: $d7teal;
    }
}

body {
    margin: 0;
    background: unset;
}

body > iframe {
    z-index: 0;
    display: none;
}

.blour {
    outline: 1px solid $d7teal;
}

.capitalize-text {
  text-transform: unset !important;
}

