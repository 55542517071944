html,
.admin-page {
    background-color: $bgblue;
}

.admin-header {
    width: 100%;
    left: 224px;
    position: fixed;
    top: 0;
    z-index: 13;
    transition: 0.3s;
    &.active {
        left: 43px;
        transition-delay: 150ms !important;
        transition: 0.4s;
    }
    & button.dropdown-toggle {
        background-color: $d7teal;
        border-color: $d7teal;
        &:focus {
            border-color: $d7teal;
            background-color: $d7teal;
            filter: brightness(1.12);
        }
    }
}

.sidebar {
    position: fixed;
    top: 0;
    max-width: 100%;
    transition: 0.4s;
    &.active {
        max-width: 42px;
        transition: 0.4s;
    }
}

.admin-main {
    position: relative;
    width: 70vw;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    row-gap: 2rem;
    top: 88px;
    left: calc(224px + 4.5%);
    padding: 0 0 24px;
    transition: 0.4s;
    &.active {
        transition: 0.4s;
        width: 80vw;
        transition-delay: 0.4s;
        left: calc(100px + 4.5%);

        .popup-modal {
            transition: 0.4s;
            transition-delay: 0.4s;    
            width: calc(100% - 42px); 
            left: 42px;
        }
    }
    .main-modal {
        width: 100%;
        min-height: 880px;
        background: #fff;
        border-radius: 12px;
        box-shadow: $defaultshadow;
        overflow-x: hidden;
    }
}

.admin-header-wrap {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    border: none;
    background-color: transparent;

    .widgets-settings-button {
        display: flex;
        align-items: center;
        margin: 0 0 0;
        color: $d7navy;
        &:hover {
            cursor: pointer;
        }

        h4 {
            margin: 0 0 0 8px;
            font: {
                family: $roboto;
                size: 13px;
                weight: 700;
            }
        }
    }
}

.popup-modal {
    width: calc(100% - 225px);
    height: calc(100% - 72px);
    position: fixed;
    top: 72px;
    left: 225px;
    background: rgba(207, 216, 221, 0.7);
    z-index: 10;
    & > div {
        position: absolute;
        top: 50%;
        left: 50%;
        max-height: calc(100% - 60px);
        transform: translateX(-50%) translateY(-50%);
    }
}

.popup-submodal {
    width: calc(100% - 50px);
    height: calc(100% - 75px);
    position: fixed;
    top: 50px;
    background: rgba(207, 216, 221, 0.7);
    z-index: 10;
    & > div {
        position: absolute;
        top: 50%;
        left: 50%;
        max-height: calc(100% - 60px);
        transform: translateX(-50%) translateY(-50%);
    }
}

.hidden-heading {
    text-indent: -999em;
    position: absolute;
}

.form-groups {
    padding: 0;
    margin-top: 15px;
}

.no-border{
    button{
        border: 0 !important;
        box-shadow: none !important;
    }
}
