@import "../helpers/variables";
@import "../helpers/mixin.scss";

.user-portal {
    &__main {
        display: flex;
        justify-content: center;
        margin: auto;
        position: relative;
        width: 100%;
        max-width: 80%;
        /*top: 72px;*/
        h1 {
            color: $d7navy;
            line-height: 20px;
            font: {
                family: $roboto;
                size: 28px;
                weight: 700;
            }
        }

        h2 {
            margin: 32px 0 40px;
            color: $d7navy;
            line-height: 20px;
            font: {
                family: $roboto;
                size: 24px;
                weight: 700;
            }
        }

        h3 {
            line-height: 20px;
            font: {
                family: $roboto;
                size: 18px;
                weight: 700;
            }
        }

        p.button-text {
            line-height: 20px;
            font: {
                family: $roboto;
                size: 18px;
                weight: 700;
            }
        }

        h4 {
            line-height: 20px;
            font: {
                family: $roboto;
                size: 15px;
                weight: 700;
            }
        }

        /*
        div {
            line-height: 20px;
            font: {
                family: $roboto;
                size: 15px;
                weight: 700;
            }
        }
        */

        p {
            margin: 32px 0 40px;
            line-height: 20px;
        }

        &-container {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }

    &__nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 16px 0;
        column-width: 300px;
        & > div {
            width: 100%;
        }
    }

    &__filter {
        &-wrapper {
            display: inline-flex;
            align-items: center;
            height: 56px;
            padding: 18px 16px;
            letter-spacing: 0.37px;
            position: relative;
            font: {
                family: $roboto;
                size: 18px;
                color: $d7navy;
            }
            span {
                color: $darkgrey;
                font-weight: 500;
                margin: 0 16px 0 0;
            }
            &:hover {
                span {
                    filter: opacity(.5);
                }
            }
        }

        &-button {
            display: flex;
            justify-content: space-between;
            position: relative;
            gap: 12px;
            color: $d7navy;
            transition: 0.2s;
            h3 {
                margin: 0;
            }
            &:hover {
                cursor: pointer;
            }
            &--question-archive {
                justify-content: flex-start;
                svg {
                    margin: 0 0 0 8px;
                }
            }
        }

        &-dropmenu {
            position: relative;
            top: 30px;
            left: -34px;
            z-index: 50;
            width: fit-content;
            opacity: 0;
            transition: 0.4s;
            &.active {
                opacity: 1;
            }
            & > div.dropmenu-container {
                position: absolute;
            }
            & ul {
                width: 100%;
                cursor: auto;
                background: #fff;
                border-radius: 12px;
                box-shadow: $defaultshadow;
                padding: 1rem;
                position: relative;
                & li {
                    width: fit-content;
                    & > div {
                        display: flex;
                        align-items: center;
                        gap: 6px;
                        & input[type="checkbox"] {
                            cursor: pointer;
                            appearance: none;
                            height: 24px;
                            width: 24px;
                            border: 2px;
                            outline: 2px solid $d7teal;
                            border-radius: 4px;
                            transition: 0.2s;
                            &:checked {
                                border: 2px solid $white;
                                background-color: $d7teal;
                            }
                        }
                        & label {
                            cursor: pointer;
                        }
                    }
                }
            }
            &-button {
                height: 48px;
                border-radius: 6.93px;
                font: {
                    family: $roboto;
                    size: 17px;
                    weight: 500;
                }
                &--clear {
                    @extend %clear-button;
                    &:disabled {
                        opacity: 0.5;
                        cursor: initial;
                    }
                }
                &--solid {
                    @extend %solid-button;
                    background: $d7teal;
                    border: solid 1px $d7teal;
                    &:disabled {
                        background: $lightgrey;
                        border: solid 1px $lightgrey;
                        color: $greymedium;
                        cursor: initial;
                    }
                }
            }

            &:after {
                display: block;
                content: "";
                width: 16px;
                height: 16px;
                background: #fff;
                transform: rotate(45deg);
                position: absolute;
                top: -5px;
                left: 21px;
            }
        }

        &-list {
            list-style: none;
            padding: 0;
            margin: 0 0 9px;
            color: $d7navy;
        }
    }
}

.expand {
    svg {
        transition: 0.4s all;
        transform: rotate(180deg);
        & > path {
            fill: black;
            transition: 0.4s all;
        }
    }
    &.active {
        svg {
            & > path {
                transition: 0.4s all;
                fill: $d7teal !important;
            }
            transform: rotate(0);
        }
    }
}

.mobile-toggle {
    display: none;
}

.search {
    height: 20px;
    padding: 0;
    margin: 0 0 0 8px;
    border: none;
    color: $d7navy;
    font: {
        family: $roboto;
        size: 18px;
        weight: 500;
    }
    &::placeholder {
        color: $greymedium;
    }
    &:focus {
        outline: none;
        text-decoration: underline;
        &::placeholder {
            opacity: 0;
        }
    }
    &-wrapper {
        display: inline-flex;
        align-items: center;
        width: 326px;
        height: 56px;
        padding: 0 16px;
        background: #fff;
        border-radius: 8px;
        color: $greymedium;
        box-shadow: $defaultshadow;
        &--mobile {
            display: none;
        }
    }
}

.skip-to-content-link {
    height: 30px;
    padding: 8px;
    position: absolute;
    z-index: 99999;
    transform: translateY(-100%);
    &:focus {
        transform: translateY(0%);
    }
}

.page-heading-hidden {
    position: absolute;
    text-indent: -999em;
}

.profile-settings__personal-information {
    h2{
        font-size: 18px;
        line-height: 20px;
        margin: 0 0 16px;
    }
}
@include style-for(mobile) {
    .expand svg > path {
        fill: #fff;
    }

    .expand.active svg > path {
        transition: 0.4s all;
        fill: #fff !important;
    }

    .user-portal {
        &__main {
            max-width: 100%;
            h2.user-portal__greeting {
                margin: 0;
                line-height: 20px;
                text-align: center;
                color: $white;
                font: {
                    size: 20px;
                }
            }

            &-container {
                background-color: $d7teal;
                width: 100%;
                height: 100%;

                &--badge-status {
                    .user-portal__nav {
                        display: flex;
                        padding: 0 16px;
                        margin-top: 58px;
                        margin-bottom: 0;
                        justify-content: center;
                        gap: 32px;
                        > div {
                            margin-top: 0;
                            width: auto;
                        }
                        .input-whit-icon-container {
                            display: none;
                        }
                    }

                    .user-portal__filter-wrapper {
                        display: none;
                    }
                    .selected {
                        background: #fff;
                        color: $d7teal;
                        border: 0.5px solid rgba(0, 0, 0, 0.04);
                        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12),
                            0px 3px 1px rgba(0, 0, 0, 0.04);
                        text-decoration: none;
                    }
                    
                    .central_container {
                        height: 100%
                    }
                    
                    .badge-status {
                        top: 72px
                    }
                }

                &--leaderboard {            
                    .user-portal__nav {
                        display: flex;
                        padding: 0 16px;
                        margin-top: 58px;
                        margin-bottom: 0;
                        justify-content: center;
                        gap: 32px;
                        > div {
                            width: auto;
                        }
                        .input-whit-icon-container {
                            display: none;
                        }
                    }
                    // might want to consider making this global if there are issues with others being too far to the right
                    .user-portal__filter-button {
                        grid-gap: 0;
                        gap: 0;
                        .categories {
                            left: 1rem;
                            .content {
                                transform: translateX(-100%);
                            }
                        }
                    }
                }
            }
        }

        &__filter-wrapper {
            padding: 0;
            padding-bottom: 1rem;
            height: auto;
            span {
                color: #fff;
                font-weight: 500;
            }
        }

        &__nav {
            display: none;
        }
    }

    .mobile {
        &-heading {
            position: fixed;
            background: $d7teal;
            z-index: 99;
            margin-top: -1px;
            h1 {
                color: #fff;
                text-align: center;
                margin: 19px;
                font: {
                    size: 24px;
                }
            }
        }
        &-toggle {
            display: block;
            position: absolute;
            right: 16px;
        }
    }

    .search {
        &-wrapper {
            display: none;
            &--mobile {
                padding: 0;
                width: 100%;
                & input {
                    width: 100%;
                    background: $lightgrey;
                }
                display: flex;
                margin: auto;
                font: {
                    size: 17px;
                    weight: 400;
                }
            }
        }
    }
}
