.customization-menu {
    .customization-header {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px $lightgrey;

        .customization-nav {
            display: flex;
            align-items: center;
            position: relative;
            padding: 24px 0;

            ul {
                padding: 0;
                margin: 0;
                color: $d7navy;

                li {
                    display: inline-flex;
                    align-items: baseline;
                    justify-content: center;
                    width: 208px;
                    line-height: 20px;
                    transition: 0.1s;

                    font: {
                        family: $sfpro;
                        size: 16px;
                    }

                    &:hover {
                        cursor: pointer;
                        color: $d7teal;
                        transition: 0.1s;
                    }

                    svg {
                        margin: 0 10px 0 0;
                        height: 15px;
                    }
                }

                .selected {
                    color: $d7teal;

                    &:hover {
                        cursor: default;
                    }

                    &::after {
                        display: block;
                        content: "";
                        width: 208px;
                        height: 3px;
                        position: absolute;
                        bottom: 0;
                        background: $d7teal;
                    }
                }
            }
        }

        .customization-save-buttons {
            display: flex;
            padding: 8px 16px 8px 16px;
            // padding-right: 16px;
            // padding-left: 16px;
            column-gap: 16px;

            /*
            button {
                width: 176px;
                height: 40px;
                border-radius: 6.93px;

                font: {
                    family: $sfpro;
                    size: 13px;
                    weight: 500;
                }

                &:last-of-type {
                    margin: 0;
                }
            }

            .clear-button {
                @extend %clear-button;
            }

            .solid-button {
                @extend %solid-button;
                background: $d7teal;
                border: solid 1px $d7teal;
            }
            */
        }

    }

    @media screen and (max-width: 1142px) {
    
        .customization-header {
            border-bottom: none;
        }
    
        .customization-nav {
            width: 100%;
            border-bottom: solid 1px $lightgrey;
        }
    
        .customization-save-buttons {
            width: 100%;
            justify-content: flex-end;
            margin-top: 16px;
        }
    }

    .customization-fields,
    .support-info {
        display: flex;
        flex-flow: column wrap;
        margin: 2em;

        input,
        textarea,
        button {
            padding: 4px 8px;
            box-sizing: border-box;
            border: solid 1px $grey;
            border-radius: 6.93px;
            margin: 0 0 16px;
            color: $d7navy;

            font: {
                family: $sfpro;
                size: 12px;
                weight: 500;
            }

            &::placeholder {
                color: $greymedium;
            }

            &:focus {
                outline: none;
            }
        }


        input {
            height: 40px;

            &[type="checkbox"] {
                width: 16px;
                height: 16px;
                margin: 0;
            }
            &:focus {
                border-color: var(--drip7-primary-color);
            }
        }

        .dropdown {
            @extend %attached-drop;

            .btn {
                @extend %solid-button;
                background: $d7teal;
                border: solid 1px $d7teal;
                border-radius: 6.93px;

                &:focus {
                    background: $d7teal;
                    border: solid 1px $d7teal;
                    box-shadow: none !important;
                }
            }

            &-item {
                border: none;
            }
        }

        .dropdown-toggle {
            &::after {
                display: inline-flex;
                content: url('../../images/shared/drop-triangle-white.svg');
                border: none;
                margin: 0 0 3px 0;
            }
        }

        .customization-checkbox {
            display: block;
            position: relative;
            align-items: center;
            height: 16px;
            margin: 0 0 32px;
                
            @media screen and (max-width: 1368px) {
                margin: 0 0 16px;
            }
                
            label {
                margin: 0 0 0 24px;
                line-height: 20px;
            }
                
            @extend %custom-checkmark;
        }
    }

    label {
        display: flex;
        align-items: center;
        height: 16px;
        margin: 0 0 8px;
        color: $d7navy;

        font: {
            family: $roboto;
            size: 12px;
            weight: 700;
        }

        .help {
            margin: 0 0 0 8px;
        }
    }

    .customization-fields {
        column-gap: 36px;
        display: grid;
        grid-template-columns: 1fr 1fr;


        @media screen and (max-width: 1368px) {
            height: auto;
        }

        .grid-item {
            display: flex;
            flex-flow: column;
            position: relative;
        }

        .tooltip-container {
            display: none;

            position: relative;
            padding-top: 5px;
            
            &:hover {
                .tooltip-container__text {
                    visibility: visible;
                    transition: .4s;
                    opacity: 1;
                }
            }

            &:hover:after {
                visibility: visible;
                transition: .4s;
                opacity: 1;
            }

            &:after {
                content: " ";
                position: absolute;
                visibility: hidden;
                top: 50%;
                left: 20px;
                opacity: 0;
                margin-top: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent $d7teal transparent transparent;
            }

            &__text {
                filter: drop-shadow(0px 4px 10px var(--drip7-primary-color-50));
                visibility: hidden;
                color: #fff;
                font: {
                    weight: 500;
                    family: $roboto
                }
                background-color: $d7teal;
                text-align: center;
                padding: 5px 10px;
                border-radius: 6px;
                position: absolute;
                top: -5px;
                left: 130%;
                width: 222px;                
                opacity: 0;
                z-index: 1;
            }
        }

        textarea {
            height: 96px;
            resize: none;
            line-height: 20px;
            &:focus {
                border-color: var(--drip7-primary-color);
            }
        }
        button {
            height: 40px;
        }

        .drop-button {
            @extend %drop-button;
            margin: 0 0 8px;
            height: 40px;
            background: #fff;
            color: $greymedium;
        }

        #leaderboard_url {
            margin-top: 8px;
        }
    }

    .support-info {
        width: 30%;

        input {
            width: 100%;
        }

        @media screen and (max-width: 1368px) {
            width: auto;
        }
    }

    .design-panes {
        display: flex;
        flex-flow: row;
        min-height: 807px;

        .design-settings {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: flex-start;
            padding: 55px 0 0;
            border-right: solid 1px $lightgrey;

            .tooltip-container {
                display: none;
                position: relative;
                padding-top: 5px;
                
                &:hover {
                    .tooltip-container__text {
                        visibility: visible;
                        transition: .4s;
                        opacity: 1;
                    }
                }
    
                &:hover:after {
                    visibility: visible;
                    transition: .4s;
                    opacity: 1;
                }
    
                &:after {
                    content: " ";
                    position: absolute;
                    visibility: hidden;
                    top: 50%;
                    left: 20px;
                    opacity: 0;
                    margin-top: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: transparent $d7teal transparent transparent;
                }
    
                &__text {
                    filter: drop-shadow(0px 4px 10px var(--drip7-primary-color-50));
                    visibility: hidden;
                    color: #fff;
                    font: {
                        weight: 500;
                        family: $roboto
                    }
                    background-color: $d7teal;
                    text-align: center;
                    padding: 5px 10px;
                    border-radius: 6px;
                    position: absolute;
                    top: -5px;
                    left: 130%;
                    width: 222px;                
                    opacity: 0;
                    z-index: 1;
                }
            }

            .color-selectors {
                margin: 0 0 40px;

                .sketch-picker {
                    filter: drop-shadow(0px 4px 10px rgba(162, 218, 240, 0.3));
                    position: relative;
                    z-index: 5;
                    box-shadow: none !important;
                    background-color: #fff;
                }

                .sketch-picker::before {
                    display: block;
                    content: "";
                    width: 0px;
                    height: 0px;
                    left: 50%;
                    margin-left: -10px;
                    border-left: 10px solid #fff;
                    border-right: 10px solid transparent;
                    border-top: 10px solid #fff;
                    border-bottom: 10px solid transparent;
                    position: absolute;
                    transform: rotate(45deg);
                    top: -10px;
                    background-color:#fff;
                }

                .drop-button {
                    @extend %drop-button;
                    width: 377px;
                    height: 40px;
                    margin: 0 0 16px;
                    border: solid 1px $grey;
                    border-radius: 6.93px;
                    background: #fff;
                    color: $d7navy;

                    font: {
                        family: $sfpro;
                        size: 12px;
                        weight: 500;
                    }

                    line-height: 20px;

                    .color-value {
                        display: flex;
                        flex-flow: row;
                        align-items: center;

                        .swatch {
                            display: block;
                            content: "";
                            width: 24px;
                            height: 24px;
                            margin: 0 8px 0 0;
                            background: $d7teal;
                            border-radius: 50%;
                        }
                    }

                    &:last-of-type {
                        margin: 0;
                    }
                }

                .solid-button {
                    @extend %solid-button;
                    width: 220px;
                    height: 40px;
                    margin: 12px 0 40px;
                    background: $d7teal;
                    border: solid 1px $d7teal;
                    border-radius: 6.93px;
                }
            }

            .image-uploads {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                width: 377px;

                .image-uploader {
                    width: 168px;

                    label {
                        display: flex;
                        justify-content: space-between;
                    }

                    .image-preview {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 168px;
                        border: solid 1px $grey;
                        border-radius: 6.93px;
                        background: #fafafa;

                        .image-placeholder {
                            display: flex;
                            flex-flow: row wrap;
                            justify-content: center;

                            img {
                                max-width: 75px;
                            }

                            p {
                                width: 100%;
                                margin: 8px 0 0;
                                line-height: 20px;
                                text-align: center;
                                color: $greymedium;
                                opacity: 0.5;

                                font: {
                                    family: $sfpro;
                                    size: 12px;
                                    weight: 500;
                                }
                            }
                        }
                    }

                    /*
                    button {
                        @extend %solid-button;
                        width: 100%;
                        height: 40px;
                        margin: 12px 0 40px;
                        background: $d7teal;
                        border: solid 1px $d7teal;
                        border-radius: 6.93px;

                        font: {
                            family: $sfpro;
                            size: 13px;
                            weight: 500;
                        }
                    }
                    */
                }
            }
        }

        .design-preview {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            width: 53%;

            .phone-preview-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 210px;
                height: 413.67px;
            }

            .phone-preview {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 210px;
                height: 413.67px;
                content: url("../../images/admin/phone-example/preview-phone.svg");
            }

            .logo {
                content: url("../../images/admin/phone-example/drip7-logo-white.svg");
                position: absolute;
                max-width: 105px;
                max-height: 206.86px;
            }

            .example-ui {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-end;
                align-items: flex-start;
                width: 180px;
                position: absolute;

                .topbar {
                    margin: auto;
                    max-height: 21px;
                }

                .example-main {
                    display: flex;
                    flex-flow: column;
                    justify-content: flex-start;
                    align-items: center;
                    width: 184px;
                    height: 322px;
                    margin: 25px 0 0;
                    border-radius: 22px;
                    background: #fff;

                    .example-ui-user {
                        display: flex;
                        flex-flow: row wrap;
                        justify-content: center;
                        text-align: center;
                        position: relative;
                        top: -15px;

                        font: {
                            family: $sfpro;
                        }

                        .example-user-photo {
                            width: 31.38px;
                            height: 31.38px;
                            background: url("../../images/admin/phone-example/avatar.png") center;
                            background-size: contain;
                            border: solid 2px #fff;
                            border-radius: 50%;
                        }

                        .example-user-name {
                            width: 100%;
                            margin: 6px 0 0;
                            color: $d7teal;

                            font: {
                                size: 11px;
                                weight: 600;
                            }
                        }

                        .example-user-dept {
                            width: 100%;
                            margin: 0;
                            color: $d7navy;

                            font: {
                                size: 7px;
                                weight: 500;
                            }
                        }
                    }

                    .example-diagram {
                        margin: -10px 0 0
                    }

                    .example-legend {
                        ul {
                            padding: 0;
                            list-style: none;

                            font: {
                                family: $sfpro;
                                size: 7px;
                                weight: 500;
                            }

                            li {
                                display: flex;
                                flex-flow: row;
                                margin: 8px 0;

                                span {
                                    display: flex;
                                    flex-flow: row;

                                    .legend-key {
                                        margin: 0 4px 0 0;
                                        width: 7.85px;
                                        height: 7.85px;
                                        border-radius: 3px;
                                    }
                                }
                            }

                            .leg-psw {
                                span {
                                    color: $d7teal;

                                    .legend-key {
                                        background: $d7teal;
                                    }
                                }
                            }

                            .leg-core {
                                span {
                                    color: $chartgreen;
                                }

                                .legend-key {
                                    background: $chartgreen;
                                }
                            }

                            .leg-phish {
                                span {
                                    color: $indigo;
                                }

                                .legend-key {
                                    background: $indigo;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.req{
    color:red;
}
#tab_panel_main_information{
    max-width:1000px;
    margin: auto;
    margin-top: 2em;
}

.field-container{
    margin: 2em;
}

#profile_screen{
    height: 63px;
}
