.curriculum {
    &__add {
        &-menu {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            width: 352px;
            height: 274px;
            padding: 13px 8px 0;
            position: absolute;
            background: #fff;
            top: 73px;
            left: 240px;
            border-radius: 12px;
            box-shadow: $defaultshadow;
            z-index: 4;
            ::-webkit-scrollbar {
                width: 8px;
            }
            scrollbar {
                width: 8px;
            }
            ::-webkit-scrollbar-button {
                display: none;
            }
            scrollbar-button {
                display: none;
            }
            ::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: $grey;
            }
            scrollbar-thumb {
                border-radius: 10px;
                background: $grey;
            }
            &::after {
                display: block;
                content: "";
                border: solid 13px #fff;
                transform: rotate(45deg);
                position: absolute;
                left: 23px;
                top: -12px;
                z-index: -1;
            }
        }
        &-search {
            &-wrapper {
                display: inline-flex;
                align-items: center;
                width: 336px;
                height: 40px;
                padding: 10px 11px;
                border: solid 1px $grey;
                border-radius: 6.93px;
                color: $d7navy;
            }
            margin: 0 0 0 11px;
            border: none;
            color: $d7navy;
            font: {
                family: $sfpro;
                size: 12px;
                weight: 500;
            }
            &:focus {
                outline: none;
            }
            &::placeholder {
                color: $greymedium;
            }
        }
        &-curriculums {
            width: 296px;
            height: 184px;
            overflow-y: scroll;
            &-list {
                list-style: none;
                padding: 0;
                margin: 0;
            }
            &-item {
                margin: 0 0 8px;
                line-height: 24px;
                color: $d7navy;
                font: {
                    family: $roboto;
                    size: 13px;
                    weight: 700;
                }
                &:last-of-type {
                    margin: 0;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    &__edit-queue {
        height: 80px;
        padding: 16px 8px;
        position: absolute;
        left: 154px;
        top: 62px;
        background: #fff;
        border-radius: 12px;
        box-shadow: $defaultshadow;
        z-index: 2;
        &-list {
            padding: 0;
            list-style: none;
            font: {
                family: $roboto;
                size: 13px;
                weight: 700;
            }
        }
        &-item {
            display: flex;
            align-items: center;
            color: $d7navy;
            margin: 0 0 16px;
            line-height: 16px;
            svg {
                width: 24px;
                margin: 0 8px 0 0;
            }
            &--red {
                color: $red;
                margin: 0;
            }
            &:hover {
                cursor: pointer;
            }
        }
        &::after {
            display: block;
            content: "";
            border: solid 13px #fff;
            border-radius: 5px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: rotate(45deg) translateX(-50%);
        }
    }
}
