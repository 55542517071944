.central-container {
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    border-radius: 12px;
    box-shadow: $defaultshadow;
    position: relative;
    transition: 0.2s;

    &__filter-button {
        display: inline-flex;
        align-items: center;
        height: 56px;
        padding: 18px 16px;
        margin: 0 8px 0 0;
        line-height: 20px;
        letter-spacing: 0.37px;
        border-radius: 6px;
        color: $d7teal;
        white-space: nowrap;
        transition: 0.1s;
        background-color: transparent;
        border: none;

        svg {
            min-width: fit-content;
        }
        &:last-of-type {
            margin: 0;
        }
        &:hover {
            background: var(--drip7-primary-color-075);
            cursor: pointer;
            transition: 0.1s;
        }

        &-container {
            display: flex;
            flex-flow: row;
            justify-content: flex-start;
            width: 100%;
            padding: 16px 0 2px;
            background: #fff;
            top: 0;
            z-index: 10;
            &--mobile {
                display: none;
            }
        }

        &--selected {
            background: var(--drip7-primary-color-20);
            text-decoration: underline;
            text-underline-offset: 1px;
            &:hover {
                background: var(--drip7-primary-color-20);
                cursor: initial;
            }
        }

        &--leaderboard {
            width: 256px;
            .central-container__filter-button-name {
                margin: 0 0 0 15px;
            }
        }

        &--question-archive {
            min-width: fit-content;
            .central-container__filter-button {
                flex: 1 1 0px;
                &-name {
                    margin: 0 0 0 32px;
                }
            }
        }

        &--curriculum-list {
            min-width: fit-content;
            .central-container__filter-button {
                flex: 1 1 0px;
                &-name {
                    margin: 0 0 0 17px;
                }
            }

        }

        &--unanswered {
            padding: 18px 11px 18px 16px;
            .central-container__filter-button-name {
                margin: 0 0 0 11px;
            }
        }
    }
}

#show_departmentFilter {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .central-container {
        width: 100%;
        min-width: unset;
        height: 100%;
        /* height: 100vh; */
        border-radius: 30px 30px 0 0;
        overflow-y: scroll;
        transition: 0.2s;
        &__filter-button {
            &-container {
                display: none;
                &--mobile {
                    display: inline-flex;
                    align-items: center;
                    background: none;
                    gap: 4px;
                    width: 100%;
                    height: 48px;
                    padding: 0 4px;
                    color: #fff;
                    justify-content: space-between;
                    position: absolute;
                    z-index: 98;
                    top: 72px;
                    background: rgba(31, 71, 96, 0.1);
                    border-radius: 8.91px;
                    .central-container__filter-button {
                        flex: 1 1 0px;
                        height: 42px;
                        padding: 13px 0;
                        margin: 0;
                        justify-content: center;
                        background: $d7teal;
                        color: #fff;
                        border-radius: 6.93px;
                        svg {
                            display: none;
                        }
                        &-name {
                            margin: 0;
                            letter-spacing: -0.24px;
                            font: {
                                size: 15px;
                                padding: 0;
                            }
                        }
                        &--selected {
                            background: #fff;
                            color: $d7teal;
                            border: 0.5px solid rgba(0, 0, 0, 0.04);
                            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12),
                                0px 3px 1px rgba(0, 0, 0, 0.04);
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}
