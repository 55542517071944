.membership-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: $defaultshadow;
    border-radius: 12px;

    .membership-heading {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0 0 24px;

        h3 {
            padding: 0;
            margin: 0;
            line-height: 20px;
            color: $d7navy;
            font: {
                family: $roboto;
                size: 15px;
                weight: 700;
            }
        }
        h2 {
            line-height: 20px;
            font: {
                size: 15px;
            }    
        }
    }
    .membership-list {
        overflow-y: scroll;
    }
    .membership-button {
        margin-top: 12px;

        button {
            @extend %sfpro-default;
            width: 176px;
            height: 40px;
            border-radius: 6.93px;
            margin: 0 16px 0 0;
            &:last-of-type {
                margin: 0;
            }
        }

        .solid-button {
            @extend %solid-button;
            background: $d7teal;
            border: solid 1px $d7teal;
        }
    }
}
