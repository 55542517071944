@keyframes team-details-expand {
    from {
        height: 48px;
    }

    to {
        height: 176px;
    }
}

@keyframes team-details-collapse {
    from {
        height: 176px;
    }

    to {
        height: 48px;
    }
}

@keyframes filter-dropdown-expand {
    from {
        top: 4px;
    }
    to {
        top: 40px;
    }
}

@keyframes filter-dropdown-collapse {
    from {
        top: 40px;
        border-radius: 0 0 6.93px 6.93px;
    }
    to {
        top: 0px;
        border-radius: 6.93px;
    }
}

@keyframes filter-dropdown-reset {
    from {
        border-radius: 6.93px 6.93px 0 0;
    }
    to {
        border-radius: 6.93px;
    }
}
