.add-users-modal {
    display: flex;
    justify-content: center;
    min-width: 520px;
    width: auto;
    min-height: 336px;
    max-height: 640px;
    background: #fff;
    box-shadow: $defaultshadow;
    border-radius: 12px;

    .add-users-wrap {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
        padding: 24px;
    }

    .add-users-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 16px;
        margin: 0 0 40px;

        h4 {
            margin: 0;
            line-height: 20px;
            color: $d7navy;
            font: {
                family: $roboto;
                size: 15px;
                weight: 700;
            }
        }
        h2 {
            margin: 0;
            padding: 0;
            line-height: 20px;
            font: {
                size: 15px;
            }

        }
    }

    h5 {
        width: 100%;
        margin: 0 0 8px;
        color: $d7navy;
        font: {
            family: $roboto;
            size: 12px;
            weight: 600;
        }
    }
    .form-heading {
        margin: 0 0 8px;
        color: $d7navy;
        text-transform: none;
        font: {
            family: $roboto;
            size: 12px;
            weight: 600;
        }
    }

    .modal-field-heading {
        margin: 0 0 8px;
        color: $d7navy;
        text-transform: none;
        font: {
            family: $roboto;
            size: 12px;
            weight: 600;
        }
    }

    button,
    input {
        height: 40px;
        line-height: 20px;
        border-radius: 6.93px;
    }

    button {
        font: {
            family: $sfpro;
            size: 13px;
            weight: 500;
        }
    }

    .solid-button {
        @extend %solid-button;
        &:disabled {
            cursor: initial;
        }
    }

    .add-users-file-select {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0;

        input {
            flex: 1 1 0px;
            padding: 4px 8px;
            border: solid 1px $grey;
            box-sizing: border-box;
            color: $greymedium;
            font: {
                family: $roboto;
                size: 12px;
                weight: 500;
            }
        }

        .solid-button {
            width: 144px;
            background: $d7teal;
            border: solid 1px $d7teal;
            margin: 0 0 0 8px;
        }
    }

    p {
        width: 100%;
        margin: 8px 0 0;
        text-transform: lowercase;
        color: $greymedium;
        font: {
            family: $roboto;
            size: 13px;
            weight: 400;
        }
    }

    .add-users-radios {
        width: 100%;

        .radio-choice {
            display: flex;
            flex-flow: row;
            align-items: center;
            margin: 16px 0;
            color: $d7navy;
            font: {
                family: $roboto;
                size: 12px;
                weight: 700;
            }

            input {
                width: 16px;
                height: 16px;
            }

            label {
                margin: 0 0 0 8px;
                letter-spacing: -0.24px;
            }
        }
    }

    .upload-buttons {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        width: 100%;

        button {
            width: 176px;
            margin: 0 16px 0 0;
            &:last-of-type {
                margin: 0;
            }
        }

        .clear-button {
            @extend %clear-button;
        }

        .solid-button {
            background: $d7teal;
            border: solid 1px $d7teal;
        }
    }
}
