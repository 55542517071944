.widgets-settings {
    display: flex;
    flex-flow: row;
    justify-content: center;
    width: 352px;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 12;
    background-color: #fff;
    box-shadow: $defaultshadow;

    .widgets-settings-wrap {
        display: flex;
        flex-flow: column;
        width: 100%;
        padding: 16px 16px 40px;
        margin: 72px 0 0;

        .save-widgets-buttons {
            display: flex;
            justify-content: space-between;
            margin: 0 0 40px;

            button {
                @extend %sfpro-default;
                width: 152px;
                height: 40px;
                border-radius: 6.93px;
            }

            .clear-button {
                @extend %clear-button;
            }

            .solid-button {
                @extend %solid-button;
                background: $d7teal;
                border: solid 1px $d7teal;
                opacity: 0.5;
            }
        }

        .widgets-wrap {
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            flex-grow: 1;
            color: $d7navy;

            .widget-toggles {
                h3 {
                    @extend %roboto15;
                    margin: 12px 0 12px;
                }

                .widget-toggle {
                    display: flex;
                    flex-flow: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 48px;
                    box-sizing: border-box;
                    padding: 0 8px;
                    margin: 8px 0;
                    background: #fff;
                    box-shadow: $defaultshadow;
                    border-radius: 6px;
                    &:first-of-type {
                        margin: 0 0 8px;
                    }
                    &:last-of-type {
                        margin: 8px 0 0;
                    }

                    .widget-name {
                        display: flex;
                        flex-flow: row;
                        align-items: center;

                        .handle {
                            content: url("../../images/shared/handle.svg");
                            &:hover {
                                cursor: grab;
                            }
                            &:active {
                                cursor: grabbing;
                            }
                        }

                        p {
                            margin: 0 0 0 16px;
                            font: {
                                family: $sfpro;
                                size: 12px;
                                weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }
}
