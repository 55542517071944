@import "../helpers/variables";

.curriculum-heading {
    margin-bottom: 88px;
}

.curriculum-list {
    padding: 0 22px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-bottom: 1rem;
    &::-webkit-scrollbar {
        display: none;
    }
    &__loader {
        svg {
            display: block;
        }
        &--item {
            margin-bottom: 16px;
            width: 100%;
            overflow: hidden;
        }
    }
    &__body {
        margin: 32px 0 16px 0;
        display: flex;
        flex-direction: column;
        min-height: 350px;
        &--no-content {
            display: flex;
            justify-content: center;
            align-items: center;
            height: calc(100% - 74px);
            margin: 0;
        }
    }
    &__backbtn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        width: 58px;
        height: 25px;
        line-height: 20px;
        cursor: pointer;
        margin: 0px 0px 20px!important;
        color: $d7navy;
        
        i {
            display: inline-block;
        }
        &:hover {
            background: $d7teal;
            color: #fff;

            i {
                filter: brightness(0) invert(1)
            }
        }
    }
    &__expand {
        margin: 0 0 32px;
        display: flex;
        flex-direction: column;
        min-height: 350px;
        background-color: white;
        &--no-content {
            display: flex;
            justify-content: center;
            align-items: center;
            height: calc(100% - 74px);
            margin: 0;
        }
    }
    &__item {
        display: grid;
        grid-template-columns: 1fr 200px;
        width: 100%;
        padding: 14px 0 10px;
        letter-spacing: -0.24px;
        border-top: solid 1px $lightgrey;
        margin: 16px 0 0;
        &:first-of-type {
            margin: 0;
        }

        &--expand {
            pointer-events: none;
        }
    }
    &__text {
        display: flex;
        flex-direction: column;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 8px;
    }

    &__date,
    &__points {
        font-size: 12px;
    }
    &__date {
        color: $greymedium;
        font-weight: 700;
    }
    &__title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;
    }
    &__title-text {
        letter-spacing:-0.24px;
        height: 20px;
        width: 97%;
        color: $d7navy;
        font: {
            family: $roboto;
            weight: 700;
            size: 15px !important;
        }
    }
    &__title-icon {
        height: 10px;
        color: $greymedium;
        &--favorite {
            color: $d7teal
        }
    }
    &__search-match {
        color: $d7teal;
        text-decoration: underline;
    }
    &__information {
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
    &__short-description {
        margin: 16px 0 18px 0;
        line-height: 20px;
        letter-spacing: -0.24px;
        font: {
            family: $roboto;
            weight: 500 !important;
            size: 12px !important;
        }
        color: $greymedium;
    }
    &__description {
        font-weight: normal !important;
        padding: 12px 21px;
        color: var(--drip7-primary-color);
        font-style: italic;
    }
    &__button {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
        width: 100%;
        padding-top: 12px;
    }
    &__start {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 185px;
        height: 48px;
        letter-spacing: -0.24px;
        box-sizing: border-box;
        background: var(--drip7-primary-color);
        border: none;
        border-radius: 6.93px;
        color: #fff;
        line-height: 20px;
        transition: .4s filter;
        font: {
            family: $sfpro;
            size: 17px;
            weight: 600;
        }
        svg {
            margin: 0 9px 0 0;
        }
        span {
            margin: 0 7px 0 0;
            font-size: 19px;
        }
        &:hover {
            filter: brightness(1.12);
        }

        &--assigned {
            background: $d7green
        }
    }

    &__favorite {
        background: #fff;
        border: 1px solid $d7orange;
        color: $d7orange;
        svg path{
            fill: $d7orange
        }

        &--true {
            background: $d7orange;
            &:hover {
                filter: brightness(1.08);
            }
        }

    }


    &__points {
        color: $d7teal;
    }

    &__no-content {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        width: 140px;
        margin: 0 0 36px;
        text-align: center;
        color: $d7teal;
        &-icon {
            width: 84px;
            height: 84px;
            position: relative;
            overflow: visible;
            border-radius: 50%;
            margin: 0;
            background: var(--drip7-primary-color-20);
            img {
                width: 106px;
                position: absolute;
                bottom: 1px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        &-text {
            margin: 28px 0 0;
            span {
                line-height: 25px;
                color: $d7teal;
                letter-spacing: 0.37px;
            }
        }
    }

    & div.no-result {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 50vh;
        & h2 {
            text-align: center;
            color: $d7teal;
            line-height: 25px;
        } 
    }
}

@media only screen and (max-width: 767px) {
    .curriculum-list {
        height: unset;
        overflow-y: visible;
        margin-top: 140px;
        padding: 24px 16px;
        margin-bottom: 0px;
        min-height: 69vh;

        &__body {
            width: 100%;
            margin: 12px 0 0;
        }

        &__item {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 108px;
            margin: 12px 0 0;
        }

        &__title {
            height: unset;
        }

        &__information {
            align-items: center;
        }

        &__start {
            width: 100%;
        }
    }
}
