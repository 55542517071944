.leaderboard {
    margin-bottom: 2rem;
    padding: 0 22px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    &__loader {
        margin: 16px 0 0;
        &--mobile {
            display: none;
        }
    }
    &__table {
        margin: 16px 0 0;
        width: 100%;
        border-collapse: collapse;
        overflow: visible;
    }

    &__user-info {
        @media screen and (min-width: 768px) {
            // &--me {
            //     &::after {
            //         top: 0;
            //         width: 100%;
            //         height: 100%;
            //     }
            // }
            &--rank-1,
            &--rank-2,
            &--rank-3 {
                &::after {
                    content: "";
                    width: 50px;
                    height: 40px;
                    z-index: 0;

                    position: absolute;
                    left: -23px;
                    top: 13px;
                    border-radius: 0 20px 20px 0;
                }
            }
            &--rank-1 {
                &::after {
                    background: $gold;
                }
            }
            &--rank-2{
                &::after {
                    background: $silver;
                }
            }
            &--rank-3 {
                &::after {
                    background: $bronze;
                }
            }
        }
    }

    &__text {
        margin: 6px 0 0;
        line-height: 20px;
        color: $d7navy;
        position: relative;
        z-index: 1;
        font: {
            family: $roboto;
            size: 17px;
            weight: 700;
        }
        &--rank {
            width: 31px;
            margin-right: 9px;
        }
        &--name {
            margin-left: 8px;
        }
        &--points {
            width: 105px;
            margin-left: 22px;
            text-align: right;
            color: $d7teal;
        }
    }
    &__user,
    &__team {
        &-info {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
            height: 48px;
            padding: 16px 0 0;
            margin: auto;
            box-sizing: content-box;
            background: #fff;
            position: relative;
            border-bottom: solid 1px $lightgrey;
            cursor: pointer;
            &--me {
                &::before {
                    $paddingOffset: 22px;
                    content: '';
                    position: absolute;
                    top: -1px;
                    left: -$paddingOffset;
                    width: calc(100% + #{$paddingOffset * 2});
                    height: calc(100% + 2px);
                    background-color: $lightgrey;
                }
            }
            &--active-user {
                .leaderboard__text {
                    color: $d7teal;
                }
                &::before {
                    display: block;
                    content: "";
                    width: 1004px;
                    height: 64px;
                    position: absolute;
                    left: -22px;
                    top: 0;
                    background: var(--drip7-primary-color-20);
                    z-index: 0;
                }
                .leaderboard__text--points {
                    width: 200px;
                    text-align: right;
                    &::before {
                        content: "your balance: ";
                        font: {
                            family: $roboto;
                            size: 17px;
                            weight: 500;
                        }
                    }
                }
            }
        }
        &-name {
            flex: 2;
        }
    }

    &__stats {
        flex: 2;
        display: inline-flex;
    }

    &__user {
        &-avatar {
            position: relative;
            .user-avatar {
                width: 32px;
                height: 32px;
                overflow: hidden;
            }
        }

        &-avatars {
            display: inline-flex;
            align-items: center;
            .user-avatar,
            .leaderboard__more-users {
                width: 30px;
                height: 30px;
                margin: 0 0 0 -10px;
                border: solid 2px #fff;
            }
            .leaderboard__user-avatar {
                &:first-of-type {
                    width: 26px;
                    height: 26px;
                    margin: 0;
                    border: none;
                }
            }
        }
    }

    &__more-users {
        width: 32px;
        height: 32px;
        padding: 3px 0;
        text-align: center;
        position: relative;
        background: $grey;
        border-radius: 50%;
        z-index: 2;
        color: $d7navy;
        font: {
            family: $sfpro;
            size: 12px;
            weight: 400;
        }
        &-count {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__team {
        &-info {
            overflow: hidden;
            &:hover {
                cursor: pointer;
            }
            &--expand {
                height: 176px;
                border-bottom: none;
                overflow: visible;
                animation: team-details-expand 0.5s;
                .leaderboard__team-details {
                    height: auto;
                    &:hover {
                        cursor: initial;
                    }
                    &-module {
                        box-shadow: 0px 4px 30px rgba(143, 164, 173, 0.2);
                    }
                }
                & + .leaderboard__team-info {
                    padding: 0;
                }
            }
            &--collapse {
                animation: team-details-collapse 0.5s;
            }
        }
        &-name {
            margin: 0 0 0 6px;
        }
        &-details {
            display: inline-flex;
            justify-content: space-between;
            align-self: flex-end;
            width: 920px;
            height: 0;
            margin: 27px 0 24px;
            padding: 0;
            &-module {
                display: flex;
                flex-flow: row wrap;
                width: 296px;
                height: 96px;
                padding: 16px 24px;
                border-radius: 5px;
                position: relative;
                background: #fff;
                overflow: hidden;
                &::after {
                    display: block;
                    content: "";
                    width: 8px;
                    height: 96px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: $d7teal;
                }
            }
            &-heading {
                width: 100%;
                margin: 0 0 14px;
            }
        }
        &-captain {
            display: inline-flex;
            align-items: center;
            .user-avatar {
                width: 30px;
                height: 30px;
                border: solid 2px #fff;
            }
            p {
                margin: 0 0 0 4px;
                line-height: 20px;
                color: $d7navy;
                font: {
                    family: $roboto;
                    size: 12px;
                    weight: 500;
                }
            }
        }

        &-award {
            margin: 0 16px 0 0;
            &:last-of-type {
                margin: 0;
            }
            &-container {
                margin: 2px 0 0;
            }
        }
    }

    &__trophy-icon {
        width: 32px;
        height: 32px;
        position: relative;
        z-index: 1;
        &--rank-1 {
            content: url("./../../images/trophies/rank-1.svg");
        }
        &--rank-2 {
            content: url("./../../images/trophies/rank-2.svg");
        }
        &--rank-3 {
            content: url("./../../images/trophies/rank-3.svg");
        }
        &--rank-4 {
            content: url("./../../images/trophies/rank-4.svg");
        }
        &--rank-5 {
            content: url("./../../images/trophies/rank-5.svg");
        }
    }

    &__badge {
        width: 112px;
        height: 32px;
        margin-left: 22px;
        position: relative;

        .leaderboard__text {
            left: 72px;
            position: relative;
            color: $darkgrey
        }

        &-icon {
            position: absolute;
            width: 32px;
            height: 32px;
            z-index: 1;
            border: 1px solid $white;
            border-radius: 50%;
            background-color: $d7teal;

            &-0 {
                z-index: 4;
            }
            &-1 {
                z-index: 3;
                left: 12px;
            }
            &-2 {
                z-index: 2;
                left: 24px;
            }
            &-3 {
                z-index: 1;
                left: 36px;
            }

            img {
                display: block;
                max-height: 100%;
                max-width: 100%;
                border-radius: 50%;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .leaderboard {
        height: fit-content;
        padding: 24px 0 36px 0;
        margin-bottom: 0;
        .search-wrapper--mobile {
            padding: 0 16px;
        }
        &__loader {
            &--desktop {
                display: none;
            }
            &--mobile {
                display: block;
            }
        }
        &__table {
            width: 100%;
        }

        &__user,
        &__team {
            &-info {
                width: 100%;
                padding: 4px 0;
                border: none;
                &--active-user {
                    .leaderboard__text {
                        color: $d7navy;
                    }
                    &::before {
                        display: none;
                        width: unset;
                    }
                }
                &--rank-1 {
                    .leaderboard__rank {
                        background: $gold;
                    }
                }
                &--rank-2{
                    .leaderboard__rank {
                        background: $silver;
                    }
                }
                &--rank-3 {
                    .leaderboard__rank {
                        background: $bronze;
                    }
                }
            }
        }

        &__user {
            &-info {
                align-content: center;
                align-items: center;
                .leaderboard__user-name {
                    flex: unset;
                    grid-column: 1 / 2;
                    grid-row: 1 / 2;
                }
                .leaderboard__user-points {
                    grid-column: 1 / 2;
                    grid-row: 2 / 3;
                }
                .leaderboard__user-trophy {
                    grid-column: 2 / 3;
                    grid-row: 1 / 3;
                }
                &--me {
                    &::after {
                        top: 0;
                        width: calc(100% + 23px);
                        height: 100%;
                    }
                }
            }

            &-avatar {
                margin: 0 8px 0 0;
                .user-avatar {
                    width: 48px;
                    height: 48px;
                }
            }
        }

        &__rank {
            position: relative;
            margin: 0 8px 0 0;
            border-radius: 0 20px 20px 0;
        }

        &__team {
            &-info {
                &--expand {
                    height: fit-content;
                    margin: 0 0 24px;
                    .leaderboard__team-details {
                        margin: 12px 0 0 56px;
                    }
                }
                .leaderboard {
                    &__team-name {
                        grid-column: 1 / 2;
                        grid-row: 1 / 2;
                        margin: 0;
                    }
                    &__team-points {
                        grid-column: 1 / 2;
                        grid-row: 2 / 3;
                    }
                    &__user-avatars {
                        grid-column: 2 / 3;
                        grid-row: 1 /3;
                    }
                }
            }
            &-details {
                display: flex;
                flex-flow: row wrap;
                width: 100%;
                margin: 0;
                & > * {
                    width: 100%;
                    margin: 0 0 16px;
                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }

        &__text {
            margin: 6px 0 0;
            line-height: 20px;
            font: {
                family: $roboto;
            }
            &--rank {
                width: 48px;
                height: 32px;
                margin: 0;
                line-height: 32px;
                text-align: center;
            }
            &--name {
                margin-left: 0;
            }
            &--points {
                width: unset;
                text-align: left;
                font: {
                    size: 12px;
                    weight: 500;
                }
                &::before {
                    display: none;
                }
            }
        }

        &__stats {
            display: grid;
            grid-template-columns: auto 80px;
            grid-template-rows: 1fr 1fr;
            width: calc(100% - 100px);
            & > * {
                .leaderboard__text {
                    margin: 0;
                }
            }
            &--user {
                margin-right: 23px;
                grid-template-columns: calc(100% - 32px) 32px;
            }
        }

        &__badge {
            display: none;
        }
    }
}
