.search-bar-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    width: 232px;
    height: 40px;

    .search-icon {
        content: url("../../images/shared/search.svg");
        width: 15px;
        margin: 0 16px 0 0;
    }

    .search-input {
        width: calc(100% - 32px);
        height: 20px;
        outline: none;
        border: none;
        color: $d7navy;
        border-radius: 4px;
        font: {
            family: $sfpro;
            size: 13px;
            weight: 500;
        }
        &::placeholder {
            color: $d7navy;
            opacity: 0.4;
        }
        &:active {
            outline: none;
        }
        &:focus {
            outline: none;
            border: var(--drip7-primary-color) 1px solid;
        }
    }
}
