@import "../helpers/variables";

.question-archive {
    padding: 0 22px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-bottom: 1rem;
    &::-webkit-scrollbar {
        display: none;
    }
    &__loader {
        svg {
            // padding-top: 16px;
            display: block;
        }
        &--item {
            border-top: solid 1px $lightgrey;
            margin-bottom: 16px;
            width: 100%;
            overflow: hidden;
        }
        &--answer {
            margin-bottom: 8px;
            margin-left: 5px;
            height: 1.1em;
            width: 100%;
            overflow: hidden;
        }
    }
    &__body {
        margin: 32px 0 0;
        display: flex;
        flex-direction: column;
        min-height: 350px;
        &--no-content {
            display: flex;
            justify-content: center;
            align-items: center;
            height: calc(100% - 74px);
            margin: 0;
        }
    }
    &__item {
        width: 100%;
        padding: 14px 0 10px;
        letter-spacing: -0.24px;
        border-top: solid 1px $lightgrey;
        margin: 16px 0 0;
        &:first-of-type {
            margin: 0;
        }
    }

    &__date,
    &__points {
        font-size: 12px;
    }
    &__date {
        color: $greymedium;
        font-weight: 700;
    }
    &__question {
        min-height: 22px;
        color: $d7navy;
    }
    &__answer {
        word-break: break-word;
        margin-left: 5px;
        margin-right: 5px;
        font-style: italic;
        min-height: 22px;
        color: $d7navy;
    }
    &__search-match {
        color: $d7teal;
        text-decoration: underline;
    }
    &__information {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
    }

    &__category {
        padding: 2px 7px;
        text-transform: capitalize;
        background: var(--drip7-primary-color-20);
        color: $d7teal;
        border-radius: 12px;
        box-shadow: $defaultshadow;
    }

    &__points {
        color: $d7teal;
    }

    &__no-content {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        width: 140px;
        margin: 0 0 36px;
        text-align: center;
        color: $d7teal;
        &-icon {
            width: 84px;
            height: 84px;
            position: relative;
            overflow: visible;
            border-radius: 50%;
            margin: 0;
            background: var(--drip7-primary-color-20);
            img {
                width: 106px;
                position: absolute;
                bottom: 1px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        &-text {
            margin: 28px 0 0;
            span {
                line-height: 25px;
                color: $d7teal;
                letter-spacing: 0.37px;
            }
        }
    }

    & div.no-result {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 50vh;
        & h2 {
            text-align: center;
            color: $d7teal;
            line-height: 25px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .question-archive {
        height: unset;
        margin-top: 140px;
        padding: 24px 16px;
        margin-bottom: 0px;
        min-height: 69vh;

        &__body {
            width: 100%;
            margin: 12px 0 0;
        }

        &__item {
            height: unset;
            padding: 13px 0 8px;
        }

        &__question {
            min-height: unset;
        }
        &__answer {
            min-height: unset;
        }

        &__information {
            align-items: center;
        }

        &__category {
            margin: 0;
        }

        &__points {
            margin: 0;
        }
    }
}
