.management {
    &__table {
        width: calc(100% - 16px);
        border-collapse: collapse;
        margin: 8px 8px 0;
        &-row {
            height: 64px;
            border-bottom: solid 1px $lightgrey;
            &--head {
                height: 56px;
                border: none;
            }
        }
        &-head {
            &--name {
                position: relative;
                min-width: 300px;
                .management__table-heading {
                    margin: 0 0 0 32px;
                }
            }
            &--questions {
                min-width: 140px;
            }
            &--categories {
                min-width: 410px;
            }
            &--languages {
                min-width: 245px;
            }
        }
        &-heading,
        &-text {
            margin: 0;
            color: $d7navy;
            line-height: 20px;
            font: {
                family: $roboto;
                size: 13px;
                weight: 700;
            }
        }

        &-heading {
            display: flex;
            align-items: center;
            line-height: 20px;
            opacity: 0.8;
            cursor:pointer;
            &::after {
                content: url("../../images/shared/sort.svg");
                margin: 0 0 0 8px;
                opacity: 1;
                height: 15px;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        &-data {
            height: 64px;
            &--name {
                position: relative;
                .management__table-text {
                    margin: 0 0 0 32px;
                }
            }
        }
    }
}
.pointer {
    cursor:pointer
}
