@import "../../scss/helpers/mixin";

.progress-home {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 3%;
    padding: 0 43px 40px;
    min-height: 58vh;
    box-sizing: border-box;
    & > div {
        width: 47%;
        & + div {
            width: 47%;
        }
    }
    &__heading {
        margin: 32px 0 40px;
        text-align: center;
    }

    & > * {
        width: 416px;
    }

    &__global {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: flex-start;
        &-chart {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            width: 100%;
            & > * {
                flex-grow: 1;
                height: 100%;
                background: none;
                box-shadow: none;
                title {
                    background: purple;
                }
            }
        }
    }

    &__current-topics {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        height: fit-content;
    }

    &__nav {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        padding: 44px 36px;
        margin: 16px 0 0;
        box-sizing: border-box;
    }
    &__btn {
        width: 216px;
        height: 56px;
        letter-spacing: 0.37px;
        color: #fff;
        background: $d7teal;
        border: none;
        border-radius: 6px;
        font: {
            family: $roboto;
            size: 18px;
            weight: 500;
        }
        &:hover {
            cursor: pointer;
        }
    }
}

.current-topics {
    display: flex;
    gap: 1rem;
    row-gap: 1rem;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
    &.main {
        & .current-topics__topic {
            width: 47% !important;
        }
        .no-result {
            text-align: center;
            margin: 0 auto;
            h2 {
                color: $d7teal;
            }
        }
    }
    &__topic {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        height: 220px;
        width: 200px;
        padding: 1rem 0;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 10px;
        box-sizing: border-box;
        box-shadow: $defaultshadow;
        z-index: 5;
        cursor: pointer;
        &.lock {
            filter: grayscale(1);
        }
        &:hover {
            transition: .4s box-shadow;
            box-shadow: $darkshadow;
        }
    }

    &__name {
        width: 100%;
        margin: 8px 0;
        text-align: center;
        line-height: 20px;
        color: $d7navy;
        z-index: 2;
        letter-spacing: -0.24px;
    }

    &__perc {
        color: $darkgrey
    }

    &__continue {
        line-height: 10px;
        color: $d7teal;
        font: {
            family: $roboto;
            size: 17px;
            weight: 500;
        }
    }
}

@media only screen and (max-width: 767px) {
    .progress-home {
        display: flex;
        justify-content: center;
        max-width: 100%;
        position: relative;
        overflow: inherit;
        padding: 2rem 1rem;
        & > div {
            width: 100%;
            & + div {
                width: 100%;
            }
        }
        &__nav {
            display: none;
        }
        & > * {
            max-width: 100%;
            flex-grow: 1;
        }

        .admin-card {
            min-width: 175px;
            & > *,
            .speedometer {
                display: flex;
                justify-content: center;
            }
        }
    }

    .current-topics {
        max-width: 100%;
        box-shadow: 0 0 5px 10px #fff;
        gap: 6%;
        row-gap: 12px;
        &__topic {
            width: -moz-fit-content;
            width: fit-content;
            max-width: 48%;
        }
    }
}
